import { Select } from '@mantine/core'
import React from 'react'
import { useSelector } from 'react-redux'
import PoppiButton from '../../../../widget/buttons/poppiButton'
import PoppiCard from '../../../../widget/card/poppiCard'
import poppiNotification from '../../../../widget/dialog/poppiNotification'
import PoppiEmpty from '../../../../widget/empty/poppiEmpty'
import PoppiIcon from '../../../../widget/icons/poppiIcons'
import { LoaderDispatch } from '../../../../widget/loaders/loaderDipatch'
import { getAllDogs } from '../../dashboardStore'

export const CustomBookingDogSelection = (props) => {
    const dahsboardData = useSelector(state => state.dashboard)

    const { bookingData, setBookingData, activeStep, setActiveStep } = props

    return <PoppiCard
        title='Select a dog'
        actions={[
            <PoppiButton
                variant='default'
                fullWidth={true}
                onClick={() => {
                    if (bookingData.DogId) {
                        setActiveStep(activeStep + 1)
                    } else {
                        poppiNotification({
                            message: 'Missing Data',
                            description:
                                'You need to select a dog',
                        })
                    }
                }}
            >Next</PoppiButton>
        ]}>
        <LoaderDispatch
            dispatch={getAllDogs()}
        >
            {dahsboardData?.dogs.length === 0 ?
                <PoppiEmpty
                    description={<p>You don't have any dog registered.<br />
                        Go to your profile to add one</p>}>
                    <PoppiButton
                        icon={<PoppiIcon icon='profile' />}
                        to={'/profile'}
                        variant='default'>
                        Profile
                    </PoppiButton>
                </PoppiEmpty>
                :
                <Select
                m={10}
                    searchable
                    value={bookingData.DogId}
                    onChange={(selectedValue) => {
                        setBookingData(
                            {
                                ...bookingData,
                                DogId: selectedValue,
                                dogData: dahsboardData?.dogs.find((dog) => (dog.id === selectedValue))
                            })

                    }}
                    data={[...dahsboardData?.dogs].sort((a, b) => (a.name > b.name) ? 1 : -1).map((dog) => {
                        return {
                            value: dog.id,
                            label: `${dog.name} (${dog.breed})`
                        }

                    })
                    }
                />
            }
        </LoaderDispatch>
    </PoppiCard>


}

export default CustomBookingDogSelection