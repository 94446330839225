import React from "react"
import ApiRequest from "../../../api/api"
import PoppiButton from "../../../widget/buttons/poppiButton"
import PoppiCard from "../../../widget/card/poppiCard"
import PoppiPaymentForm from "../../../widget/checkout/poppiPaymentForm"
import poppiNotification from "../../../widget/dialog/poppiNotification"
import { currency } from "../../../widget/text/formatText"
import PoppiIcon from "../../../widget/icons/poppiIcons"
export const PaymentSection = (props) => {

    const { bookingData, activeStep, setActiveStep } = props


    return <React.Fragment>
        <PoppiCard
            title='Payment'
            subtitle={`Total price: ${currency(bookingData.total.price)} (${bookingData.total.time} min)`}
            actions={[
                <PoppiButton
                    icon={<PoppiIcon icon='arrowBack' />}
                    fullWidth={true}
                    onClick={() => {
                        setActiveStep(activeStep - 1)
                    }}
                >Back</PoppiButton>
            ]}
        >
            <PoppiPaymentForm
                bookingData={bookingData}
                setActiveStep={setActiveStep}
                onSuccess={(paymentData) => {
                    // ApiRequest(
                    //     `/calendar/${bookingData.CalendarId}/booking/new`,
                    //     'POST',
                    //     { ...bookingData, payment: paymentData, payments: [{ type: 'Card Online', amount: bookingData.total.price }] }
                    // ).then(
                    //     (response) => {
                    setActiveStep(activeStep + 1)
                    //     },
                    //     (error) => {
                    //         alert('error')
                    //     }
                    // )

                }}
                onError={(error) => {
                    poppiNotification(
                        {
                            message: 'Payment Error',
                            description: error,
                            type: 'error'
                        }
                    )
                }} />
        </PoppiCard>
    </React.Fragment>
}

export default PaymentSection