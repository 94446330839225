import { Stack, Switch, Text, TextInput } from "@mantine/core"
import { TimeInput } from "@mantine/dates"
import moment from "moment"
import React, { useState } from "react"
import { useDispatch } from 'react-redux'
import PoppiButton from "../../../widget/buttons/poppiButton"
import PoppiDialog from "../../../widget/dialog/poppiDialog"
import { setCalendarDate } from "./calendarStore"

export const DialogOpenDay = (props) => {
    const { dayData } = props
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()

    const [timeData, setTimeData] = useState(
        {
            open: dayData?.open ? dayData?.open : false,
            timings: [dayData?.timings.length > 0 ? dayData.timings[0] : '09:00', dayData?.timings?.length > 0 ? dayData.timings[1] : '21:00'],
            reverse: dayData?.reverse ? dayData?.reverse : false,
            notes: dayData?.notes ? dayData?.notes : '',
            id: props.selectedDate.format('DDMMYYYY')
        }
    )

    return <PoppiDialog
        footer={[
            <PoppiButton
                variant='default'
                loading={isLoading}
                onClick={() => {
                    // Save the data
                    setIsLoading(true)
                    dispatch(
                        setCalendarDate(
                            props.selectedDate.format('DDMMYYYY'),
                            { ...timeData }
                        )
                    ).then(
                        (response) => {
                            setIsLoading(false)
                            props.setSelectedDate(moment(props.selectedDate).add(1, 'second'))
                            props.handleClose()
                        }
                    )
                }}>
                Save</PoppiButton>]}
        open={props.open}
        handleClose={props.handleClose}
        title={'Open Day'}>
        <Stack gap='sm'>
            <Text fw={500}>{props.selectedDate.format('ddd DD/MM/YYYY')}</Text>

            <Switch
                label='Status'
                size="md"
                checked={!!timeData.open}
                onLabel='Open'
                offLabel='Closed'
                onChange={(e) => {
                    console.log(e.target.checked)
                    setTimeData({ ...timeData, open: e.target.checked })
                }} >Open</Switch>
            <TimeInput
                label='Open Time'
                disabled={!timeData.open}
                value={timeData.timings[0]}
                onChange={(e) => {
                    setTimeData({ ...timeData, timings: [e.target.value, timeData.timings[1]] })
                }
                }
                style={{ width: '100%' }} />
            <TimeInput
                label='Closing Time'
                disabled={!timeData.open}
                value={timeData.timings[1]}
                onChange={(e) => {
                    setTimeData({ ...timeData, timings: [timeData.timings[0], e.target.value] })
                }}
                style={{ width: '100%' }
                } />
            <Switch
                size="md"
                label='Reverse Booking'
                disabled={!timeData.open}
                checked={!!timeData.reverse}
                onLabel='Active'
                offLabel='Not active'
                onChange={(e) => {
                    setTimeData({ ...timeData, reverse: e.target.checked })
                }} />
            <TextInput
                label='Notes'
                value={timeData.notes}
                onChange={(e) => {
                    setTimeData({ ...timeData, notes: e.target.value })
                }} />
        </Stack>
    </PoppiDialog>
}
export default DialogOpenDay