import React from 'react'
import { Helmet } from 'react-helmet'
import { BASE_URL } from '../config'

export const MetaTags = (props) => {

    return <Helmet>
        <title>{props.title}</title>
        <meta name="description" content={props.description} />

        {/* Open Graph Tags */}
        {props.noindex &&
            <meta name="robots" content="noindex" />
        }
        {props.noindex &&
            <meta name="googlebot" content="noindex" />

        }
        {props.script &&
            <script src={props.script} />
        }


        <meta property="og:url" content={props.url} />
        <meta property="og:title" content={props.title} />
        <meta property="og:description" content={props.description} />
        <meta property="og:image" content={props.image} />
    </Helmet>
}

MetaTags.defaultProps = {
    title: 'Bespoke Barks',
    description: 'Grooming experience tailored for your dog',
    image: `${BASE_URL}/BespokeBarks512.png`,
    url: `${BASE_URL}`,
    noindex: false,
    script: null
}