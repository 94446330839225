// import "antd/dist/reset.css";
import React from 'react';
import { ClearBrowserCacheBoundary } from 'react-clear-browser-cache';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import appStore from './appStore';
import BaseContainer from './base/base';
import { MetaTags } from "./base/metatags";
import { TRACKING_ID } from "./config";
import ErrorBoundary, { LoaderApp } from './errorBoundary';
import reportWebVitals from './reportWebVitals';
import '@mantine/dates/styles.css';
// import "./index.css";
import '@mantine/core/styles.css';
import { MantineProvider, rem } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import '@mantine/notifications/styles.css';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import { DatesProvider } from '@mantine/dates';
import { ModalsProvider } from '@mantine/modals';

dayjs.extend(customParseFormat);

ReactGA.initialize(TRACKING_ID)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <MantineProvider forceColorScheme='light'
        theme={{
          fontSizes: {
            xs: rem(10),
            sm: rem(11),
            md: rem(14),
            lg: rem(16),
            xl: rem(20),
          },
        }}>
        <Notifications position='top-center' />
        <ClearBrowserCacheBoundary auto fallback={<LoaderApp />} duration={60000}>
          <Provider store={appStore}>
            <BrowserRouter>
              <ModalsProvider>
                <DatesProvider
                  settings={{ firstDayOfWeek: 1, locale: 'en' }}>

                  <MetaTags
                  />
                  <BaseContainer />
                </DatesProvider>
              </ModalsProvider>
            </BrowserRouter>
          </Provider>
        </ClearBrowserCacheBoundary>
      </MantineProvider>
    </ErrorBoundary>

  </React.StrictMode>
  // document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
