import { ActionIcon } from "@mantine/core"
import React from "react"
import { useNavigate } from "react-router"
import PoppiIcon from "../icons/poppiIcons"

export const PoppiIconButton = (props) => {
    const navigate = useNavigate()
    if (props.onClick || props.to) {
        return <ActionIcon
            variant={props.variant}
            color={props.color}
            loading={props.loading}
            disabled={props.disabled}
            onClick={
                props.to ?
                    () => { navigate(props.to) }
                    :
                    props.onClick
            }
            type={props.type}
            size={props.size}
            shape={props.shape}
            style={props.fullWidth ? { ...props.style, width: '100%' } : props.style}
        >
            {props.icon}
        </ActionIcon >
    }
    return <ActionIcon 
        color={props.color}
        variant={props.variant}
        loading={props.loading}
        disabled={props.disabled}
        type={props.type}
        size={props.size}
        shape={props.shape}
        style={props.fullWidth ? { ...props.style, width: '100%' } : props.style}
    >
        {/* {
            props.icon ?
                <Space direction="horizontal" size='small' align='start'>
                    {props.icon}
                    {props.children}
                </Space>
                :
                props.children
        } */}
        {props.icon}
    </ActionIcon >

}

PoppiIconButton.defaultProps = {
    variant: 'subtle',
    fullWidth: false,
    disabled: false,
    icon: null,
    type: 'default',
    size: 'middle',
    onClick: () => { },
    to: null,
    loading: false,
    style: {},
    shape: 'round',
    htmlType: null,
    extraProps: {}
}

export default PoppiIconButton