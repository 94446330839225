import { Avatar, Group } from '@mantine/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BespokeBarksLogo from '../static/images/bespokeBarksPng.png';
import PoppiButton from '../widget/buttons/poppiButton';
import PoppiIcon from '../widget/icons/poppiIcons';

const HeaderHomepage = (props) => {
    const navigate = useNavigate()
    const authData = useSelector(state => state.auth)
    return <Group justify='space-between' p={10}>
        <PoppiButton variant='subtle'
            icon={<Avatar src={BespokeBarksLogo} />}
            color="rgba(0, 0, 0, 1)"
            onClick={() => { navigate('/') }}
        >
            BespokeBarks
        </PoppiButton>
        <PoppiButton
            variant='outline'
            icon={<PoppiIcon icon='menu' />}
            onClick={props.toggleNavbar}
        >
            Menu
        </PoppiButton>
    </Group>

}

export default HeaderHomepage