import { Form } from "antd"
import moment from "moment"
import React, { useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { listOptionsBehaviours, listOptionsDiseases, listOptionsHealth } from "../../../utils/descriptions"
import PoppiButton from "../../../widget/buttons/poppiButton"
import PoppiDialog from "../../../widget/dialog/poppiDialog"
import poppiNotification from "../../../widget/dialog/poppiNotification"
import PoppiFormField from "../../../widget/form/poppiFormField"
import LoaderDispatch from "../../../widget/loaders/loaderDipatch"
import { getPrices, updateDogDetails } from "../dashboardStore"

export const DialogEditDogData = (props) => {

    const [isLoading, setIsLoading] = useState(false)
    const dashboardData = useSelector(state => state.dashboard)
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const { dogData } = props

    function handleEditDogData(data) {
        setIsLoading(true)
        dispatch(updateDogDetails(dogData.id, data))
            .then((response) => {
                setIsLoading(false)
                props.handleClose()
            },
                (error) => {
                    setIsLoading(false)
                    poppiNotification({ type: 'error' })

                }

            )
    }
    if (!props.open || !dogData) {
        return null
    }
    return <PoppiDialog
        footer={[
            <PoppiButton
                variant='default'
                loading={isLoading}
                onClick={() => {
                    // Save the data
                    form.submit()
                }}>
                Save</PoppiButton>]}
        open={props.open}
        handleClose={props.handleClose}
        title={'Edit Dog Data'}>
        <LoaderDispatch
            dispatch={getPrices()}>
            <Form form={form}
                onFinish={(data) => { handleEditDogData(data) }}
                initialValues={{
                    ...dogData,
                    dateOfBirth: moment(dogData.dateOfBirth, 'DD/MM/YYYY')
                }}>
                <h3>Payment Data</h3>
                <PoppiFormField
                    name='approved'
                    label='Approved'
                    type='switch'
                    rules={[
                        {
                            required: true,
                            message: 'Required Field'
                        },
                    ]}
                />
                <PoppiFormField
                    name='extraCharge'
                    label='Extra Charge'
                    rules={[
                        {
                            required: true,
                            message: 'Required Field'
                        },
                    ]}
                />
                <h3>Dog Data</h3>

                <PoppiFormField
                    name='name'
                    label='Name'
                    rules={[
                        {
                            required: true,
                            message: 'Required Field'
                        },
                    ]}
                />
                <PoppiFormField
                    name='dateOfBirth'
                    label='Date of Birth'
                    type='date'
                    rules={[
                        {
                            required: true,
                            message: 'Required Field'
                        },
                    ]}
                />
                <PoppiFormField
                    name='PriceId'
                    label='Breed'
                    type='select'
                    options={
                        dashboardData.prices.map((item) => {
                            return { value: item.id, label: item.breed }
                        })
                    }
                    rules={[
                        {
                            required: true,
                            message: 'Required Field'
                        },
                    ]}
                />
                <PoppiFormField
                    name='gender'
                    label='Gender'
                    type='radio'
                    options={
                        [{ value: 'Male', label: 'Male' },
                        { value: 'Female', label: 'Female' }]
                    }
                    rules={[
                        {
                            required: true,
                            message: 'Required Field'
                        },
                    ]}
                />
                <PoppiFormField
                    name={['data', 'size']}
                    label='Size'
                    type='radio'
                    options={
                        [
                            { value: 'Toy', label: 'Toy' },
                            { value: 'S', label: 'Small' },
                            { value: 'M', label: 'Medium' },
                            { value: 'L', label: 'Large' },
                            { value: 'XL', label: 'Extra Large' },
                        ]
                    }
                    rules={[
                        {
                            required: true,
                            message: 'Required Field'
                        },
                    ]}
                />
                <PoppiFormField
                    name={['data', 'hairType']}
                    label='Hair Type'
                    type='select'
                    options={
                        [
                            { value: 'Soft', label: 'Soft' },
                            { value: 'Whool', label: 'Whool' },
                            { value: 'Harsh', label: 'Harsh' },
                        ]
                    }
                />
                <PoppiFormField
                    name={['data', 'diseases']}
                    label='Diseases'
                    type='select'
                    mode='multiple'
                    options={
                        listOptionsDiseases
                    }
                />
                <PoppiFormField
                    name={['data', 'behaviour']}
                    label='Behaviour'
                    type='select'
                    mode='multiple'
                    options={
                        listOptionsBehaviours
                    }
                />
                <PoppiFormField
                    name={['data', 'health']}
                    label='Health'
                    type='select'
                    mode='multiple'
                    options={
                        listOptionsHealth
                    }
                />
                <PoppiFormField
                    name={'notes'}
                    label='Notes'
                />

                <h3>Veterinarian</h3>
                <PoppiFormField
                    name={['data', 'vetName']}
                    label='Name'
                    rules={[
                        {
                            required: true,
                            message: 'Required Field'
                        },
                    ]}
                />
                <PoppiFormField
                    name={['data', 'vetAddress', 'line1']}
                    label='Address Line 1'
                    rules={[
                        {
                            required: true,
                            message: 'Required Field'
                        },
                    ]}
                />
                <PoppiFormField
                    name={['data', 'vetAddress', 'line2']}
                    label='Address Line 2'
                    rules={[
                        {
                            required: true,
                            message: 'Required Field'
                        },
                    ]}
                />
                <PoppiFormField
                    name={['data', 'vetAddress', 'city']}
                    label='City'
                    rules={[
                        {
                            required: true,
                            message: 'Required Field'
                        },
                    ]}
                />
                <PoppiFormField
                    name={['data', 'vetAddress', 'country']}
                    type='country'
                    label='Country'
                    rules={[
                        {
                            required: true,
                            message: 'Required Field'
                        },
                    ]}
                />
                <PoppiFormField
                    name={['data', 'vetAddress', 'postcode']}
                    label='Postcode'
                    rules={[
                        {
                            required: true,
                            message: 'Required Field'
                        },
                    ]}
                />
            </Form>
        </LoaderDispatch>

    </PoppiDialog>
}

export default DialogEditDogData