import { Group, Table, Text, Title } from "@mantine/core";
import { Descriptions } from "antd";
import React from "react";
import { v4 as uuid } from 'uuid';
export const PoppiDescription = (props) => {
    return <>
        <Group justify='space-between' pt={'1em'}>
            <Title order={5}>
                {props.title}
            </Title>
            {props.extra}
        </Group>
        <Table withRowBorders={props.bordered} striped>
            <Table.Tbody>
                {props.items?.map((item) => {
                    return <Table.Tr key={uuid()} style={{ background: item.background }}>
                        {item.label && item.description &&
                            <>
                                <Table.Td w={props.width} align="right">{item.label}:  </Table.Td>
                                <Table.Td >{item.description}</Table.Td>
                            </>
                        }
                        {item.label && !item.description &&
                            <Table.Td >{item.description}</Table.Td>
                        }
                        {!item.label && item.description &&
                            <Table.Td >{item.description}</Table.Td>
                        }
                    </Table.Tr>
                })}
            </Table.Tbody>
        </Table>
    </>
    return <>
        <Group justify='space-between' pt={'1em'}>
            <Title order={5}>
                {props.title}
            </Title>
            {props.extra}
        </Group>
        {props.items?.map((item) => {
            return <Text key={uuid()}>
                <Text span style={{ width: '20vw' }} >{item.label}:  </Text>
                <Text span >{item.description}</Text>
            </Text>
        })}
    </>
    return <Descriptions
        layout={props.layout}
        title={props.title}
        size={props.size}
        column={props.column}
        bordered={props.bordered}
        extra={props.extra}
    >
        {props.items?.map((item) => {
            return <Descriptions.Item
                key={uuid()}
                label={item.label}>
                {item.description}
            </Descriptions.Item>
        })}
    </Descriptions>
}

PoppiDescription.defaultProps = {
    title: null,
    items: [],
    size: 'small',
    bordered: false,
    layout: 'horizontal',
    column: 1,
    extra: [],
    width: null
}

export default PoppiDescription