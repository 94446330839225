export const descriptionConsent = `I confirm that my pet is fit, healty & fully vaccinated.
I agree that the groomer will not be held liable or responsible for irritation abrasion, patchiness or hail loss due to any pre-existing skin condition, or as a result of the process of groomin, de-matting, thinning, stripping, or shaving, or any mishap caused by my non-disclosure of my pet medical condition or behavior.
If my pet bites or attempts to bite any person or pet, a muzzle may be used, or at the discretion of the groomer, tratment may stop and i will pay for all work done to that point and will pay the full cost of all damages and expense my pet has caused.
If I fail to collect my pet at the agreed time i will pay extra cost due.
If my pet harbours any parasites I will clear them at my expense.
If my pet's healt causes concern, I will obtain veterinary treatment at my expense.
I confirm that all facts I supplied above are true.
I agree to pay any charges due for appointment cancelled without adeguate notice.
I understand the groomer may not be able to achieve my desired look or may have to trim the pet very short due to matting.
I agree to pay any extra dematting charges ad a result of matting.
I accept and consent entirely to all of the terms and conditions shown.`

export const listOptionsBehaviours = [
    {
        value: 'Shy',
        label: 'Shy'
    },
    {
        value: 'Good',
        label: 'Good'
    },
    {
        value: 'Noisy',
        label: 'Noisy'
    },
    {
        value: 'Fights',
        label: 'Fights'
    },
    {
        value: 'Soils/Wets',
        label: 'Soils/Wets'
    },
    {
        value: 'Escapes',
        label: 'Escapes'
    },
    {
        value: 'Highly Strung',
        label: 'Highly Strung'
    },
    {
        value: 'Bite',
        label: 'Bite'
    },
    {
        value: 'Muzzle',
        label: 'Muzzle'
    },
    {
        value: 'Climbs',
        label: 'Climbs'
    },
    {
        value: 'Chews',
        label: 'Chews'
    },
]

export const listOptionsDiseases = [
    {
        value: 'Fleas',
        label: 'Fleas'
    },
    {
        value: 'Mite',
        label: 'Mite'
    },
    {
        value: 'Worms',
        label: 'Worms'
    },
    {
        value: 'Ticks',
        label: 'Ticks'
    },
    {
        value: 'Lice',
        label: 'Lice'
    },
    {
        value: 'Mange',
        label: 'Mange'
    },
    {
        value: 'Cough',
        label: 'Cough'
    },
    {
        value: 'Lung Worm',
        label: 'Lung Worm'
    },
]

export const listOptionsHealth = [
    {
        value: 'Heart',
        label: 'Heart'
    },
    {
        value: 'Sight',
        label: 'Sight'
    },
    {
        value: 'Ears',
        label: 'Ears'
    },
    {
        value: 'Balance',
        label: 'Balance'
    },
    {
        value: 'A Glands',
        label: 'A Glands'
    },
    {
        value: 'Ringworm',
        label: 'Ringworm'
    },
    {
        value: 'Hip. Dysp.',
        label: 'Hip. Dysp.'
    },
    {
        value: 'Eczema',
        label: 'Eczema'
    },
    {
        value: 'Allergies',
        label: 'Allergies'
    },
    {
        value: 'Arthritis',
        label: 'Arthritis'
    },
    {
        value: 'Diabetes',
        label: 'Diabetes'
    },
    {
        value: 'Incontinence',
        label: 'Incontinence'
    },
    {
        value: 'Warts',
        label: 'Warts'
    },
]