import React from 'react'
import { Route, Routes } from "react-router"
import PoppiPageHeader from "../../widget/card/poppiPageHeader"
import CustomBookingPage from './bookings/customBooking'
import DashboardBookingDetails from './bookings/dashboardBookingDetails'
import { DashboardCalendar } from "./calendar/dashboardCalendar"
import DashboardHomepage from "./dashboardHomepage"
import DashboardDogDetails from "./dogs/dashboardDogDetails"
import DashboardDogs from "./dogs/dashboardDogs"
import DashboardPrices from "./prices/dashboardPrices"
import DashboardUsers from "./users/dashboardsUsers"
import DashboardUserDetails from "./users/dashboardUserDetails"
import DashboardStatistics from './statistics/dashboardStatistics'
import DashboardPayments from './payments/dashboardPayments'
import OpenCloseDay from './calendar/openCloseDay'
import { element } from 'prop-types'

export const DashboardPage = (props) => {

    return <Routes>
        <Route path={'/calendar'} element={
            <DashboardContainer title='Calendar'>
                <DashboardCalendar />
            </DashboardContainer>
        }>

        </Route>
        <Route path={'/openclose/:selectedDate'} element={
            <DashboardContainer title='Open Close Day' to='/dashboard/calendar'>
                <OpenCloseDay />
            </DashboardContainer>
        } />
        <Route path={'/prices'} element={
            <DashboardContainer title='Prices'>
                <DashboardPrices />
            </DashboardContainer>} />
        <Route path={'/dogs'} element={
            <DashboardContainer title='Dogs'>
                <DashboardDogs />
            </DashboardContainer>} />
        <Route path={'/dogs/:dogId'} element={
            <DashboardContainer title='Dog Details' to={'/dashboard/dogs'}>
                <DashboardDogDetails />
            </DashboardContainer>} />
        <Route path={'/users'} element={
            <DashboardContainer title='Users'>
                <DashboardUsers />
            </DashboardContainer>} />
        <Route path={'/statistics'} element={
            <DashboardContainer title='Statistics'>
                <DashboardStatistics />
            </DashboardContainer>} />
        <Route path={'/payments'} element={
            <DashboardContainer title='Payments'>
                <DashboardPayments />
            </DashboardContainer>} />
        <Route path={'/users/:userId'} element={
            <DashboardContainer title='User Details' to={'/dashboard/users'}>
                <DashboardUserDetails />
            </DashboardContainer>} />
        <Route path={'/bookings/:bookingId'} element={
            <DashboardContainer title='Booking Details' to={'/dashboard/calendar'}>
                <DashboardBookingDetails />
            </DashboardContainer>} />
        <Route path={'/bookings/custom'} element={
            <DashboardContainer title='Custom Booking' to={'/dashboard'}>
                <CustomBookingPage />
            </DashboardContainer>} />
        <Route path='*' element={
            <DashboardContainer title='Dashboard' to='/' >
                <DashboardHomepage />
            </DashboardContainer>} />

    </Routes>

}


const DashboardContainer = (props) => {

    return <PoppiPageHeader
        title={props.title}
        to={props.to ? props.to : '/dashboard'}

    >
        {props.children}
    </PoppiPageHeader>

}

export default DashboardPage