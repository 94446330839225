import { Button, Container } from "@mantine/core"
import { useForm, yupResolver } from "@mantine/form"
import { Form } from "antd"
import dayjs from "dayjs"
import React, { useState } from "react"
import { useDispatch } from 'react-redux'
import * as yup from 'yup'
import PoppiDialog from "../../../widget/dialog/poppiDialog"
import poppiNotification from "../../../widget/dialog/poppiNotification"
import PoppiMatineFormField from "../../../widget/form/poppiMantineFormField"
import { editBookingData } from "../dashboardStore"

export const DialogEditBooking = (props) => {

    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const { bookingData } = props

    function handleChangeBooking(data) {
        setIsLoading(true)
        dispatch(editBookingData(bookingData.id, {
            ...bookingData,
            ...data
        }))
            .then((response) => {
                setIsLoading(false)
                props.handleClose()
            },
                (error) => {
                    setIsLoading(false)
                    poppiNotification({ type: 'error' })

                }

            )
    }
    if (!props.open) {
        return null
    }

    const schema = yup.object().shape({
        CalendarId: yup
            .string()
            .required('Date Required'),
        time: yup
            .string()
            .required('Time Required')
    });

    const formMantine = useForm({
        initialValues: {
            CalendarId: dayjs(bookingData.CalendarId, 'DDMMYYYY').toDate(),
            time: bookingData.time
        },
        validate: yupResolver(schema),
        transformValues: (values) => (
            {
                CalendarId: dayjs(values.CalendarId).format('DDMMYYYY'),
                time: values.time
            }
        )
    })

    return <PoppiDialog
        footer={[
            <Button
                form='formEditBooking'
                type='submit'
                loading={isLoading}
            >
                Save</Button>]}
        open={props.open}
        handleClose={props.handleClose}
        title={'Edit Booking'}>
        <Container size={420}>
            <form
                id='formEditBooking'
                onSubmit={formMantine.onSubmit(handleChangeBooking)}>
                <PoppiMatineFormField
                    label={'Date'}
                    placeholder={'Date'}
                    name={'CalendarId'}
                    type='date'
                    form={formMantine}

                />
                <PoppiMatineFormField
                    label={'Time'}
                    placeholder={'Time'}
                    name={'time'}
                    type='time'
                    form={formMantine}

                />
            </form>
        </Container>
        {/* <Form form={form}
            layout='vertical'
            onFinish={(data) => { handleChangeBooking(data) }}
            initialValues={{
                CalendarId: dayjs(bookingData.CalendarId, 'DDMMYYYY'),
                time: dayjs(bookingData.time, 'HH:mm')
            }}>
            <PoppiFormField
                name='CalendarId'
                label='Date Booking'
                type='date'
                rules={[
                    {
                        required: true,
                        message: 'Required Field'
                    },
                ]}
            />
            <PoppiFormField
                dateFormat='HH:mm'
                name='time'
                label='Time Booking'
                type='time'
                rules={[
                    {
                        required: true,
                        message: 'Required Field'
                    },
                ]}
            />
        </Form> */}
    </PoppiDialog>
}

export default DialogEditBooking