import React from "react"
import { useSelector } from "react-redux"
import NotAuthorizedPage from "../../base/notAuthorizedPage"

export const PrivateRoute = (props) => {
    const authData = useSelector(state => state.auth)

    if (authData.authenticated) {
        return props.children
    }
    return <NotAuthorizedPage />

}

export const PrivateContent = (props) => {
    const authData = useSelector(state => state.auth)

    if (authData.authenticated) {
        return props.children
    }
    return null

}

export function AdminRoute(props) {
    const authData = useSelector(state => state.auth)
    if (authData.authenticated === true && (authData.email === 'francesco.pocaforza@gmail.com' || authData.email === 'angeladipa@hotmail.it')) {
        return props.children
    }
    return <NotAuthorizedPage />

}

export const AdminContent = (props) => {
    const authData = useSelector(state => state.auth)

    if (authData.authenticated && authData.email === 'francesco.pocaforza@gmail.com') {
        return props.children
    }
    return null

}