import { Form } from "antd"
import React, { useState } from "react"
import { useDispatch } from 'react-redux'
import PoppiButton from "../../../widget/buttons/poppiButton"
import PoppiDialog from "../../../widget/dialog/poppiDialog"
import poppiNotification from "../../../widget/dialog/poppiNotification"
import PoppiFormField from "../../../widget/form/poppiFormField"
import { setBookingNotes } from "../dashboardStore"

export const DialogNotesBooking = (props) => {

    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const { bookingData } = props

    function handleNotesBooking(data) {
        setIsLoading(true)
        dispatch(setBookingNotes(bookingData.id, data))
            .then((response) => {
                setIsLoading(false)
                props.handleClose()
            },
                (error) => {
                    setIsLoading(false)
                    poppiNotification({ type: 'error' })

                }

            )
    }
    if (!props.open) {
        return null
    }
    return <PoppiDialog
        footer={[
            <PoppiButton
                variant='default'
                loading={isLoading}
                onClick={() => {
                    // Save the data
                    form.submit()
                }}>
                Save</PoppiButton>]}
        open={props.open}
        handleClose={props.handleClose}
        title={'Notes Booking'}>

        <Form form={form}
            layout='vertical'
            onFinish={(data) => { handleNotesBooking(data) }}
            initialValues={{
                notes: bookingData.notes
            }}>

            <PoppiFormField
                name='notes'
                label='Notes'
                type='textarea'
                rows={10}
            />

        </Form>

    </PoppiDialog>
}

export default DialogNotesBooking