import React from 'react'
import { Route, Routes } from "react-router-dom"
import LoginPage from "../auth/login"
import ResetPasswordPage from "../auth/resetpassword"
import SignUpPage from "../auth/signup"
import BookingPage from "../sections/booking/booking"
import ContactsPage from "../sections/contacts/contacts"
import DashboardPage from "../sections/dashboard/dashboard"
import EventsPage from "../sections/events/events"
import GroomingPage from "../sections/grooming/grooming"
import ProfilePage from "../sections/profile/profile"
import SpaPage from "../sections/spa/spa"
import TailoringPage from "../sections/tailoring/tailoring"
import TermsAndConditionsPage from "../sections/termsAndConditions/termsAndConditions"
import { AdminRoute, PrivateRoute } from "../widget/routes/customRoutes"
import Homepage from "./homepage"
import PageNotFound from "./pageNotFound"

const RoutesGlobal = (props) => {

    return <Routes>
        <Route path={'/'} element={<Homepage />} />
        <Route path={'/login'} element={<LoginPage />} />
        <Route path={'/signup'} element={<SignUpPage />} />
        <Route path={'/resetpassword'} element={<ResetPasswordPage />} />

        <Route path={'/termsAndConditions'} element={<TermsAndConditionsPage />} />
        <Route path={'/contacts'} element={<ContactsPage />} />

        <Route path={'/grooming'} element={<GroomingPage />} />
        <Route path={'/spa'} element={<SpaPage />} />
        <Route path={'/tailoring'} element={<TailoringPage />} />
        <Route path={'/events'} element={<EventsPage />} />
 
        <Route path={'/profile'} element={<PrivateRoute>
            <ProfilePage />
        </PrivateRoute>} />

        <Route path={'/booking'} element={<PrivateRoute>
            <BookingPage />
        </PrivateRoute>} />

        <Route path={'/dashboard/*'} element={<AdminRoute>
            <DashboardPage />
        </AdminRoute>} />

        <Route path='*' element={<PageNotFound />} />

    </Routes>
}

export default RoutesGlobal