import { Center, Loader, Stack, Text } from "@mantine/core"
import React from 'react'

const PoppiLoader = (props) => {
    return <Center pt='md'>
        <Stack align='center'>
            <Loader />
            <Text>{props.loadingText}</Text>
        </Stack>

    </Center>
}

PoppiLoader.defaultProps = {
    size: 'large',
    loadingText: 'Loading...'
}

export default PoppiLoader