import { ActionIcon, Button, UnstyledButton } from "@mantine/core"
import React from "react"
import { useNavigate } from "react-router"

export const PoppiLinkButton = (props) => {
    const navigate = useNavigate()
    if (props.to) {
        return <UnstyledButton c='blue'
            component="a"
            href={props.href}
            onClick={() => { navigate(props.to) }}>
            {props.children}
        </UnstyledButton>
    }
    return <UnstyledButton c='blue'
        component="a"
        href={props.href}
        onClick={props.onClick}>
        {props.children}
    </UnstyledButton>

}

PoppiLinkButton.defaultProps = {
    href: '#',
    to: ''

}

export default PoppiLinkButton