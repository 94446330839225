import { AutoComplete, Col, Input, Row, Space, Table } from "antd"
import React, { useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import PoppiButton from "../../../widget/buttons/poppiButton"
import customConfirm from "../../../widget/dialog/poppiConfirm"
import PoppiIcon from "../../../widget/icons/poppiIcons"
import { LoaderDispatch } from "../../../widget/loaders/loaderDipatch"
import { currency } from "../../../widget/text/formatText"
import { deletePrice, getPrices } from "../dashboardStore"
import PriceFormDialog from "./dashboardPriceFormDialog"

export const DashboardPrices = (props) => {
    const dashboardData = useSelector(state => state.dashboard)
    const [priceFormDialog, setPriceFormDialog] = useState({
        open: false,
        priceData: null
    })
    const dispatch = useDispatch()
    const [priceFilter, setPriceFilter] = useState()

    const columns = [
        {
            title: 'Breed',
            dataIndex: 'breed',
            key: 'breed',
            render: (data) => {
                return <small>{data}</small>
            }
        },
        {
            title: 'Services',
            dataIndex: 'services',
            key: 'services',
            render: (data) => {
                return <p>
                    {data.map((service) => {
                        if (service.enabled) {
                            return <small>{service.label}: {currency(service.price)} ({service.time} min)<br /></small>
                        }
                        return <small>{service.label}: N/A<br /></small>
                    })}
                </p>
            },
        },
        {
            title: 'Spa',
            dataIndex: 'spa',
            key: 'spa',
            render: (data) => {
                return <p>
                    {data.map((spa) => {
                        if (spa.enabled) {
                            return <small>{spa.label}: {currency(spa.price)} ({spa.time} min)<br /></small>
                        }
                        return <small>{spa.label}: N/A<br /></small>
                    })}
                </p>
            }
        },
        {
            title: '',
            key: 'actions',
            render: (_, data) => (
                <Space direction="vertical" size="small">
                    <PoppiButton
                        icon={<PoppiIcon icon='edit' />}
                        size='small'
                        type="text"
                        onClick={() => {
                            setPriceFormDialog({
                                priceData: data,
                                open: true
                            })
                        }}></PoppiButton>
                    {/* Delete Price Button */}
                    <PoppiButton
                        icon={<PoppiIcon icon='delete' />}
                        size="small"
                        type="text"
                        onClick={() => {
                            customConfirm({
                                title: 'Delete Price',
                                content: `Are you sure you want to delete this price?`,
                                onConfirm: () => {
                                    dispatch(deletePrice(data))
                                }
                            })
                        }}></PoppiButton>
                </Space>
            ),
        },
    ]
    return <React.Fragment>
        <LoaderDispatch
            dispatch={getPrices()}
        >
            {/* Dialog Price Edit */}
            {priceFormDialog.open &&
                <PriceFormDialog
                    open={priceFormDialog.open}
                    priceData={priceFormDialog.priceData}
                    handleClose={() => {
                        setPriceFormDialog(
                            { priceData: null, open: false }
                        )
                    }}
                />
            }
            <Table
                title={() => {
                    return <React.Fragment>
                        <Row style={{ width: '100%' }}>
                            <Col span={20}>
                                <AutoComplete
                                    style={{ width: '100%' }}
                                    onSearch={(searchText => {
                                        setPriceFilter(searchText)
                                    })}
                                >
                                    <Input.Search placeholder="Search..." />
                                </AutoComplete>
                            </Col>
                            <Col span={4}>
                                {/* Button New Price */}
                                <PoppiButton
                                    icon={<PoppiIcon icon='add' />}
                                    variant='default'
                                    onClick={() => {
                                        setPriceFormDialog({
                                            priceData: null,
                                            open: true
                                        })
                                    }}>New Price</PoppiButton>
                            </Col>
                        </Row>
                    </React.Fragment>
                }}
                pagination={false}
                columns={columns}
                dataSource={dashboardData.prices.filter((item) => {
                    if (priceFilter) {
                        return item.breed.toUpperCase().includes(priceFilter.toUpperCase())
                    }
                    return true
                }).sort((a, b) => {
                    if (a.breed.toUpperCase() > b.breed.toUpperCase()) {
                        return 1
                    } else {
                        return -1
                    }
                })}
            />

        </LoaderDispatch>
    </React.Fragment>
}



export default DashboardPrices 