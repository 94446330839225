import { Avatar } from '@mantine/core'
import React from 'react'
import { useSelector } from 'react-redux'
import PoppiButton from '../../../widget/buttons/poppiButton'
import PoppiCard from '../../../widget/card/poppiCard'
import poppiNotification from '../../../widget/dialog/poppiNotification'
import PoppiEmpty from '../../../widget/empty/poppiEmpty'
import PoppiSelectableList from '../../../widget/form/poppiSelectableList'
import PoppiIcon from '../../../widget/icons/poppiIcons'
import ImageLoader from '../../../widget/images/imageLoader'
import { LoaderDispatch } from '../../../widget/loaders/loaderDipatch'
import { getUserDogs } from '../../profile/profileStore'

export const DogSelection = (props) => {
    const authData = useSelector(state => state.auth)
    const profileData = useSelector(state => state.profile)
    const { bookingData, setBookingData, activeStep, setActiveStep } = props

    return <PoppiCard
        title='Select a dog'
        actions={[
            <PoppiButton
            icon={<PoppiIcon icon='arrowForward' />}
                variant='default'
                fullWidth={true}
                onClick={() => {
                    if (bookingData.DogId) {
                        if (bookingData.dogData.approved) {
                            setActiveStep(activeStep + 1)

                        } else {
                            poppiNotification({
                                message: 'Dog not approved',
                                description:
                                    'The dog need the approval from the groomer before proceeding with the booking',
                            })
                        }
                    } else {
                        poppiNotification({
                            message: 'Missing Data',
                            description:
                                'You need to select a dog',
                        })
                    }
                }}
            >Next</PoppiButton>
        ]}>
        <LoaderDispatch
            dispatch={getUserDogs(authData.id)}
        >
            {profileData?.dogs.length === 0 ?
                <PoppiEmpty
                    description={<p>You don't have any dog registered.<br />
                        Go to your profile to add one</p>}>
                    <PoppiButton
                        icon={<PoppiIcon icon='profile' />}
                        to={'/profile'}
                        variant='default'>
                        Profile
                    </PoppiButton>
                </PoppiEmpty>
                :
                <PoppiSelectableList
                    onChange={(selectedValue) => {
                        setBookingData(
                            {
                                ...bookingData,
                                DogId: selectedValue.id,
                                dogData: selectedValue
                            })

                    }}
                    items={profileData?.dogs.map((dog) => {
                        return {
                            selected: bookingData.DogId === dog.id,
                            value: dog,
                            label: dog.name,
                            description: dog.breed,
                            icon: <Avatar ><ImageLoader imageId={dog.photoId} /></Avatar>
                        }
                    })
                    }
                />
            }
        </LoaderDispatch>
    </PoppiCard>


}

export default DogSelection