import { Divider, SimpleGrid, Stack, Text } from '@mantine/core';
import React from 'react';
import PoppiButton from '../widget/buttons/poppiButton';
import PoppiIcon from '../widget/icons/poppiIcons';

const HomepageFooter = (props) => {

    return <>
        <Divider m={10} />
        <SimpleGrid cols={{ base: 1, xs: 1, sm: 3, md: 3 }}>
            <Stack gap={0}>
                <Text fw={500}>Bespoke Barks</Text>
                <Text>56 Containerville, 1 Emma Street, E3 9FP, London</Text>
            </Stack>
            <Stack align='flex-start' gap={0}>
                <Text fw={500}>Services</Text>
                <PoppiButton
                    variant='transparent'
                    icon={<PoppiIcon icon='grooming' />}
                    to='/grooming' >
                    Grooming
                </PoppiButton>
                <PoppiButton
                    variant='transparent'
                    icon={<PoppiIcon icon='spa' />}
                    to='/spa' >
                    Spa
                </PoppiButton>
                <PoppiButton
                    variant='transparent'
                    icon={<PoppiIcon icon='pets' />}
                    to='/tailoring' >
                    Tailoring
                </PoppiButton>
                <PoppiButton
                    variant='transparent'
                    icon={<PoppiIcon icon='event' />}
                    to='/events' >
                    Events
                </PoppiButton>

            </Stack>
            <Stack align='flex-start' gap={0}>

                <Text fw={500}>Contacts</Text>
                <PoppiButton
                    variant='transparent'
                    icon={<PoppiIcon icon='location' />}
                    to='/contacts' >
                    Contacts
                </PoppiButton>
                <PoppiButton
                    variant='transparent'
                    icon={<PoppiIcon icon='instagram' />}
                    href='https://www.instagram.com/dipalo.angela/' >
                    Instagram
                </PoppiButton>
                <PoppiButton
                    variant='transparent'
                    icon={<PoppiIcon icon='termsAndConditions' />}
                    to='/termsAndConditions'>
                    Terms And Conditions
                </PoppiButton>
            </Stack>
        </SimpleGrid>
        <Divider m={10} />
        <Text align='center'>© 2021 Copyright: PoppIT Development</Text>

    </>

}

export default HomepageFooter