import { Image, SimpleGrid } from "@mantine/core"
import React from "react"
import Tailoring01 from '../../static/images/Tailoring01.jpeg'
import Tailoring02 from '../../static/images/Tailoring02.jpeg'
import Tailoring03 from '../../static/images/Tailoring03.jpeg'
import Tailoring04 from '../../static/images/Tailoring04.jpeg'
import Tailoring05 from '../../static/images/Tailoring05.jpeg'
import Tailoring06 from '../../static/images/TailoringNew01.jpg'
import Tailoring07 from '../../static/images/TailoringNew02.jpg'
import PoppiButton from "../../widget/buttons/poppiButton"
import PoppiCard from "../../widget/card/poppiCard"
import PoppiPageHeader from "../../widget/card/poppiPageHeader"
import PoppiIcon from "../../widget/icons/poppiIcons"

const TailoringPage = (props) => {
    return <PoppiPageHeader
        title='Tailoring'
    >

        <PoppiCard
            bottomTitle={true}
            title='Bespoke Tailoring'
            subtitle={
                <p>
                    Bespoke tailoring bandanas, bows and clothes
                    <br />
                    Get in touch for more informations.
                </p>
            }
            actions={
                [
                    <PoppiButton
                        variant='default'
                        icon={<PoppiIcon icon='location' />}
                        fullWidth={true}
                        to='/contacts'
                    >Contacts</PoppiButton>
                ]
            }
        >
            <SimpleGrid cols={3} m='sm'>
                <Image src={Tailoring01} radius='md' />
                <Image src={Tailoring02} radius='md' />
                <Image src={Tailoring03} radius='md' />
            </SimpleGrid>
            <SimpleGrid cols={4} m='sm'>
                <Image src={Tailoring04} radius='md' />
                <Image src={Tailoring05} radius='md' />
                <Image src={Tailoring06} radius='md' />
                <Image src={Tailoring07} radius='md' />

            </SimpleGrid>

        </PoppiCard>


    </PoppiPageHeader>
}

export default TailoringPage