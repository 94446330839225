
import { AppShell, Drawer, Space } from '@mantine/core';
import { useDisclosure, useHeadroom } from '@mantine/hooks';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { trackPage } from '../utils/analytics';
import HomepageFooter from './footer';
import HeaderHomepage from './header';
import NavbarHomepage from './navbar';
import RoutesGlobal from './routes';
import { useSelector } from 'react-redux';
import { UpdateData } from './updateData';


const BaseContainer = (props) => {
    const location = useLocation()
    const [opened, { toggle }] = useDisclosure();
    const pinned = useHeadroom({ fixedAt: -120 });
    const authData = useSelector(state => state.auth)

    useEffect(() => {
        trackPage(location.pathname)
    }, [location])
    return <AppShell
        header={{ height: 60, }}
        footer={{ offset: false, collapsed: !pinned, offset: false }}
        padding="md"


    >
        <AppShell.Header>
            <HeaderHomepage toggleNavbar={toggle} />

        </AppShell.Header>
        <Drawer opened={opened} onClose={toggle} title="Menu" size='100%'>
            <NavbarHomepage toggleNavbar={toggle} />
        </Drawer>
        <AppShell.Main
        >
            {(authData.authenticated && authData.verified === false)
                ?
                <UpdateData />
                :
                <RoutesGlobal />

            }

            <Space h='xl'></Space>
            <HomepageFooter />
        </AppShell.Main>
        <AppShell.Footer

        >

        </AppShell.Footer>
    </AppShell>
}

export function ScrollToTop() {
    const pathname = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null
}

export default BaseContainer