import React, { useState } from "react"
import DetoxSpa01 from '../../static/images/DetoxSpa01.jpg'
import Spa01 from '../../static/images/Spa01.jpeg'

import TeethCleaning01 from '../../static/images/TeethCleaning01.jpg'

import { Col, Row } from "antd"
import PoppiButton from "../../widget/buttons/poppiButton"
import PoppiCard from "../../widget/card/poppiCard"
import PoppiPageHeader from "../../widget/card/poppiPageHeader"
import PoppiCarousel from "../../widget/carousel/poppiCarousel"
import PoppiIcon from "../../widget/icons/poppiIcons"
import DialogPriceServices from "../grooming/dialogPrice"
import { SimpleGrid } from "@mantine/core"

const SpaPage = (props) => {
    const [openDialogPrice, setOpenDialogPrice] = useState({ open: false, serviceValue: null })

    return <React.Fragment>
        {openDialogPrice.open &&
            <DialogPriceServices
                open={openDialogPrice.open}
                serviceValue={openDialogPrice.serviceValue}
                handleClose={() => {
                    setOpenDialogPrice({ open: false, serviceValue: null })
                }}
            >

            </DialogPriceServices>
        }<PoppiPageHeader
            title='SPA services'
        >
            <SimpleGrid cols={{ base: 1, sm: 1, md: 2, lg: 3 }}>
                <PoppiCard
                    bottomTitle={true}
                    title='Detox SPA'
                    subtitle={
                        <p>
                            Activated Charchoal. It's great absorption power eliminated toxins, impurities, traces od grease and dirt.
                            <br />Deeply Cleanses.
                            Leaves the coat clean and shiny
                        </p>
                    }
                    actions={
                        [
                            <PoppiButton
                                icon={<PoppiIcon icon='prices' />}
                                fullWidth={true}
                                onClick={() => {
                                    setOpenDialogPrice({ open: true })
                                }}>Prices</PoppiButton>,
                            <PoppiButton
                                icon={<PoppiIcon icon='booking' />}
                                variant='default' fullWidth={true} to='/booking'>Book</PoppiButton>
                        ]
                    }
                />

                <PoppiCard
                    bottomTitle={true}
                    title='Sensitive SPA'
                    subtitle={
                        <p>
                            Artero Spa Mask formulated with Aloe Vera and Rosehip, which together achieve a unique moisturizing action.
                            <br />Produce a reparing, anti-inflammatory and healing result
                        </p>
                    }
                    actions={
                        [
                            <PoppiButton
                                icon={<PoppiIcon icon='prices' />}
                                fullWidth={true}
                                onClick={() => {
                                    setOpenDialogPrice({ open: true })
                                }}>Prices</PoppiButton>,
                            <PoppiButton
                                icon={<PoppiIcon icon='booking' />}
                                variant='default' fullWidth={true} to='/booking'>Book</PoppiButton>
                        ]
                    }
                />
                <PoppiCard
                    bottomTitle={true}
                    title='Ultrasound Teeth Cleaning'
                    subtitle={
                        <React.Fragment>
                            <ul>
                                <li>Deep cleaning action, destroying bacteria</li>
                                <li>Removes bad breath</li>
                                <li>Cleanses Gum Pockets</li>
                                <li>Anti-inflammatory effect</li>
                                <li>Best prevention and protection against tartar build up</li>
                            </ul>
                        </React.Fragment>
                    }
                    actions={
                        [
                            <PoppiButton
                                icon={<PoppiIcon icon='prices' />}
                                fullWidth={true}
                                onClick={() => {
                                    setOpenDialogPrice({ open: true })
                                }}>Prices</PoppiButton>,
                            <PoppiButton
                                icon={<PoppiIcon icon='booking' />}
                                variant='default' fullWidth={true} to='/booking'>Book</PoppiButton>
                        ]
                    }
                />
            </SimpleGrid>
        </PoppiPageHeader>
    </React.Fragment>
}

export default SpaPage