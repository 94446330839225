import moment from "moment"
import React, { useState } from "react"
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from "react-router"
import PoppiDatePicker from "../../../widget/form/poppiDatePicker"
import { LoaderDispatch } from "../../../widget/loaders/loaderDipatch"
import { getAllBookingData } from "../dashboardStore"
import PoppiButton from "../../../widget/buttons/poppiButton"
const { RangePicker } = PoppiDatePicker;
export const DashboardStatistics = (props) => {
    const dashboardData = useSelector(state => state.dashboard)
    const params = useParams()

    return <LoaderDispatch
        dispatch={getAllBookingData(params.bookingId)}
    >
        {/* {
            JSON.stringify(dashboardData.bookings)
        } */}
        <StatisticsSelector bookings={dashboardData.bookings} />
    </LoaderDispatch>
}

const StatisticsSelector = (props) => {
    const { bookings } = props
    const [dates, setDates] = useState([moment().startOf('month'), moment()])

    return <>
        <RangePicker
            onChange={(value) => { setDates(value) }}
            format={'DD/MM/YYYY'}
            defaultValue={dates}
        />
        {(moment.isMoment(dates[0]) && moment.isMoment(dates[1])) ?
            <>OOK</>
            :
            <>Not a date</>}
        <StatisticsCalculator
            dates={dates}
            bookings={bookings} />
    </>

}

const StatisticsCalculator = (props) => {
    const { bookings, dates } = props
    const navigate = useNavigate()

    var bookingPayments = []
    var dataBookings = []
    var data = []
    var bookingDogs = []
    if (bookings?.length > 0) {
        bookings.forEach(element => {
            if (moment(element.CalendarId, 'DDMMYYYY').isSameOrAfter(dates[0]) && moment(element.CalendarId, 'DDMMYYYY').isSameOrBefore(dates[1])) {
                console.log('HERE')
                bookingDogs.push(element)
                if (dataBookings[element.CalendarId]) {
                    dataBookings[element.CalendarId].bookings += 1
                    if (element?.payment?.payment?.totalMoney?.amount) {
                        dataBookings[element.CalendarId].payments
                    }
                }
            }
        });
    }

    console.log(bookingDogs)
    bookingDogs.reduce(function (res, value) {
        if (!res[value.CalendarId]) {
            console.log('here')
            res[value.CalendarId] = { date: value.CalendarId, value: 0 };
            data.push(res[value.CalendarId])
        }
        res[value.CalendarId].value += 1;
        return res;
    }, {});
    console.log(data)
    const config = {
        data,
        height: 400,
        xField: 'date',
        yField: 'value',
        point: {
            size: 5,
            shape: 'diamond',
        },
    };
    // return <Line {...config} />;
    var sum = 0
    bookingDogs.map((booking) => {
        booking?.payments?.map((item) => {
            sum += item.amount
            return null
        })
        return null
    })
    return <>
        Total {sum}
        {bookingDogs.map((booking) => {
            console.log(booking)
            return <>
                <p>{booking.id}
                    Dog: <PoppiButton
                        fullWidth={true}
                        size='large'
                        onClick={() => { navigate(`/dashboard/dogs/${booking.DogId}`) }}>
                        Dogs
                    </PoppiButton>
                    {booking?.services?.map((item) => {
                        return <p>{item.value} {item.price}</p>
                    })}
                    {booking?.spa?.map((item) => {
                        return <p>{item.value} {item.price}</p>
                    })}
                    {booking?.extras?.map((item) => {
                        return <p>{item.value} {item.price}</p>
                    })}
                    {booking?.payments?.map((item) => {
                        return <p>{item.type} {item.amount}</p>
                    })}
                    Tip: {booking.tip}
                </p></>
        })}</>

}


export default DashboardStatistics 