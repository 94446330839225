import { Form } from "antd"
import React, { useState } from "react"
import { useDispatch } from 'react-redux'
import { useNavigate } from "react-router"
import PoppiButton from "../../../widget/buttons/poppiButton"
import PoppiDialog from "../../../widget/dialog/poppiDialog"
import poppiNotification from "../../../widget/dialog/poppiNotification"
import PoppiFormField from "../../../widget/form/poppiFormField"
import { updateDogPhoto } from "../dashboardStore"

export const DialogChangeDogPhoto = (props) => {

    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const { dogData } = props

    function handleEditDogData(data) {
        console.log(data)
        setIsLoading(true)
        dispatch(updateDogPhoto(dogData.id, { id: dogData.photoId, ...data }))
            .then((response) => {
                setIsLoading(false)
                props.handleClose()
                navigate(0)
            },
                (error) => {
                    setIsLoading(false)
                    poppiNotification({ type: 'error' })
                }

            )
    }
    if (!props.open) {
        return null
    }
    return <PoppiDialog
        footer={[
            <PoppiButton
                variant='default'
                loading={isLoading}
                onClick={() => {
                    // Save the data
                    form.submit()
                }}>
                Save</PoppiButton>]}
        open={props.open}
        handleClose={props.handleClose}
        title={'Change Dog Photo'}>
        <Form form={form}
            onFinish={(data) => { handleEditDogData(data) }}
        >
            <PoppiFormField
                type='upload'
                name={'data'}
                label='Dog Photo'
                form={form}
                rules={[
                    {
                        required: true,
                        message: 'Required Field'
                    },
                ]}
            />
        </Form>

    </PoppiDialog >
}

export default DialogChangeDogPhoto
