import { Avatar, Indicator, Tabs } from "@mantine/core"
import React, { useState } from "react"
import { useSelector } from 'react-redux'
import { useNavigate } from "react-router"
import PoppiButton from "../../../widget/buttons/poppiButton"
import PoppiIcon from "../../../widget/icons/poppiIcons"
import ImageLoader from "../../../widget/images/imageLoader"
import PoppiList from "../../../widget/list/PoppiList"
import PoppiListItem from "../../../widget/list/PoppiListItem"
import { LoaderDispatch } from "../../../widget/loaders/loaderDipatch"
import { getAllDogs } from "../dashboardStore"

export const DashboardDogs = (props) => {
    const dashboardData = useSelector(state => state.dashboard)

    return <LoaderDispatch
        dispatch={getAllDogs()}
    >
        <ListDogs
            listDogs={dashboardData.dogs}
        />
    </LoaderDispatch>
}

const ListDogs = (props) => {
    const navigate = useNavigate()
    var listDogs = props.listDogs
    const [filterApproved, setFilterApproved] = useState('Approved')

    return <React.Fragment>
        <PoppiList
            header={<Tabs
                pb='xs'
                // defaultValue={'Approved'}
                value={filterApproved}
                onChange={setFilterApproved}>
                <Tabs.List grow>
                    <Tabs.Tab value='Approved' leftSection={<PoppiIcon icon='approved' size='1.5em' />}>Approved</Tabs.Tab>
                    <Tabs.Tab value='NotApproved' leftSection={<PoppiIcon icon='notApproved' size='1.5em' />}>Not Approved</Tabs.Tab>
                    <Tabs.Tab value='All'>All</Tabs.Tab>
                </Tabs.List>
            </Tabs>}
            filterBy='name'
            // header={<Input
            //     placeholder="Search..."
            //     style={{ width: '100%' }}
            //     value={dogFilter}
            //     onChange={(event => {
            //         setDogFilter(event.currentTarget.value)
            //     })}
            //     rightSection={
            //         <CloseButton
            //             aria-label="Clear input"
            //             onClick={() => setDogFilter('')}
            //         />}
            // />}
            dataSource={listDogs.filter((dogData) => {
                switch (filterApproved) {
                    case 'Approved':
                        return !!dogData.approved
                    case 'NotApproved':
                        return !dogData.approved
                    default:
                        return true
                }
            })}
            renderItem={dogData => {
                return <DogDataDetails dogData={dogData} key={dogData.id} />
            }}
            onClickItem={dogData => {
                navigate(`/dashboard/dogs/${dogData.id}`)
            }}
        />

    </React.Fragment>
}
export const DogDataDetails = (props) => {
    const { dogData } = props
    const navigate = useNavigate()
    return <PoppiListItem
        avatar={
            dogData.approved ?
                <Avatar ><ImageLoader imageId={dogData.photoId} size='s' /></Avatar>
                :
                <Indicator label={'New'}>
                    <Avatar><ImageLoader imageId={dogData.photoId} size='s' /></Avatar>
                </Indicator>
        }
        title={dogData.name}
        description={dogData.breed}
        extra={<PoppiButton
            iconOnly={true}
            icon={<PoppiIcon icon='details' />}
            onClick={() => { navigate(`/dashboard/dogs/${dogData.id}`) }} >Details</PoppiButton>}
    />
}

export default DashboardDogs 