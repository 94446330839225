import { browserLocalPersistence, sendEmailVerification, setPersistence, signInWithEmailAndPassword } from '@firebase/auth'
import { Form, Space } from 'antd'
import React, { useState } from 'react'
import { BASE_URL } from '../config'
import PoppiButton from '../widget/buttons/poppiButton'
import PoppiPageHeader from '../widget/card/poppiPageHeader'
import poppiNotification from '../widget/dialog/poppiNotification'
import PoppiResult from '../widget/empty/poppiResult'
import PoppiFormField from '../widget/form/poppiFormField'
import PoppiIcon from '../widget/icons/poppiIcons'
import { auth, createUser } from './auth'

const SignUpPage = (props) => {
    // General State
    const [user, setUser] = useState(null)

    return <PoppiPageHeader
        title='Create an Account'>
        {user ?
            <SuccessSignUp
                user={user} />
            :
            <FormSignUp
                setUser={setUser} />
        }

    </PoppiPageHeader>

}

const FormSignUp = (props) => {
    const [isProcessing, setIsProcessing] = useState(false)

    async function handleSignUp(accountData) {
        setIsProcessing(true)
        // Create the user
        const user = await createUser(accountData)
        if (user.status === 'error') {
            switch (user.data?.code) {
                case 'auth/email-already-exists':
                    poppiNotification(
                        {
                            message: 'Email already used',
                            description: 'The email is already used by another account',
                        }
                    )
                    break;
                default:
                    poppiNotification(
                        {
                            message: 'Error',
                            description: 'An error occured during the registration',
                            type: 'error'
                        }
                    )
                    break;
            }
            setIsProcessing(false)
            return false
        }

        sendEmailVerification(user.data, {
            url: BASE_URL,
        })
        setIsProcessing(false)
        // Login the user
        setPersistence(auth, browserLocalPersistence)
            .then(
                signInWithEmailAndPassword(auth, accountData.email, accountData.password)
                    .catch((error) => { console.log(error) })
            )
        props.setUser(user.data)

    }

    const [form] = Form.useForm()
    return <center>
        <Space direction='vertical'>
            <Form form={form}
                layout='vertical'

                onFinish={handleSignUp} >
                <h4>Compile this form to create an account at BespokeBarks.co.uk.
                    <br />After you can access to you profile and add the data of your dogs.</h4>
                <h3>Access Data</h3>
                <PoppiFormField
                    name='email'
                    label='Email'
                    rules={
                        [
                            {
                                required: true,
                                message: 'Required Field'
                            },
                        ]}
                />

                <PoppiFormField
                    name='password'
                    label='Password'
                    type='password'
                    rules={
                        [
                            {
                                required: true,
                                message: 'Required Field'
                            },
                        ]}
                />

                <PoppiFormField
                    name='confirmpassword'
                    label='Confirm Password'
                    type='password'
                    rules={
                        [
                            {
                                required: true,
                                message: 'Required Field'
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                },
                            }),
                        ]}
                />

                <h3>Personal Data</h3>

                <PoppiFormField
                    name='name'
                    label='Name'
                    rules={
                        [
                            {
                                required: true,
                                message: 'Required Field'
                            },
                        ]}
                />

                <PoppiFormField
                    name='surname'
                    label='Surname'
                    rules={
                        [
                            {
                                required: true,
                                message: 'Required Field'
                            },
                        ]}
                />
                <PoppiFormField
                    name='phone'
                    label='Phone Number'
                    rules={
                        [
                            {
                                required: true,
                                message: 'Required Field'
                            },
                        ]}
                />

                <h3>Address</h3>

                <PoppiFormField
                    name={['address', 'line1']}
                    label='Line 1'
                    rules={
                        [
                            {
                                required: true,
                                message: 'Required Field'
                            },
                        ]}
                />

                <PoppiFormField
                    name={['address', 'line2']}
                    label='Line 2'
                />

                <PoppiFormField
                    name={['address', 'city']}
                    label='Town or City'
                    rules={
                        [
                            {
                                required: true,
                                message: 'Required Field'
                            },
                        ]}
                />

                <PoppiFormField
                    name={['address', 'country']}
                    label='Country'
                    type='country'
                    rules={
                        [
                            {
                                required: true,
                                message: 'Required Field'
                            },
                        ]}
                />
                <PoppiFormField
                    name={['address', 'postcode']}
                    label='Postcode'
                    rules={
                        [
                            {
                                required: true,
                                message: 'Required Field'
                            },
                        ]}
                />

                <PoppiFormField
                    type='checkbox'
                    name={'consent'}
                    label='I accept the privacy policy'
                    rules={[
                        {
                            required: true,
                            message: 'Should accept the privacy policy'
                        }

                    ]}
                />
                <Form.Item>
                    <PoppiButton
                        loading={isProcessing}
                        icon={<PoppiIcon icon='login' />}
                        type='submit'
                    >
                        Create Account
                    </PoppiButton>
                </Form.Item>
            </Form>
            <PoppiButton
                loading={isProcessing}
                icon={<PoppiIcon icon='home' />}
                to='/'
            >
                Homepage
            </PoppiButton>
        </Space >
    </center>
}

const SuccessSignUp = (props) => {

    return < PoppiResult
        status='success'
        title='Account Created'
        subtitle={<p>Your account has been created.
            <br /> You can access to your profile to add dogs.
        </p>}
        extra={[
            <PoppiButton to={'/profile'} variant='default' icon={<PoppiIcon icon='profile' />}>Profile</PoppiButton>,
            <PoppiButton to={'/'} icon={<PoppiIcon icon='home' />}>Homepage</PoppiButton>

        ]}
    />
}

export default SignUpPage
