import { Form, Select, Space } from "antd"
import React, { useState } from "react"
import { useSelector } from 'react-redux'
import PoppiButton from "../../widget/buttons/poppiButton"
import PoppiDescription from "../../widget/description/poppiDescription"
import PoppiDialog from "../../widget/dialog/poppiDialog"
import PoppiIcon from "../../widget/icons/poppiIcons"
import { LoaderDispatch } from "../../widget/loaders/loaderDipatch"
import { currency } from "../../widget/text/formatText"
import { getUserPrices } from "../profile/profileStore"

const { Option } = Select
export const DialogPriceServices = (props) => {

    const [idSelection, setIdSelection] = useState(null)
    const pricesData = useSelector(state => state.profile.prices)

    if (!props.open) {
        return null
    }

    return <PoppiDialog
        fullScreen={false}
        maxWidth='50vw'
        open={props.open}
        handleClose={props.handleClose}
        footer={
            [
                <PoppiButton
                    icon={<PoppiIcon icon='booking' />}
                    variant='default' to='/booking'>Book an Appointment</PoppiButton>
            ]
        }
        title={'Price details'}>
        <LoaderDispatch
            dispatch={getUserPrices()}
        >
            <Space size='small' direction="vertical" style={{ width: '100%' }}>
                <p>
                    The prices are based on a recurring grooming every 4 / 6 weeks
                </p>
                <Form layout="vertical">
                    <Form.Item
                        label='Select the breed'
                    >
                        <Select
                            showSearch
                            showArrow
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            onChange={(idSelected) => {
                                setIdSelection(idSelected)
                            }}
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        >
                            {
                                pricesData?.map((priceData) => {
                                    return <Option key={priceData.id} value={priceData.id}>{priceData.breed}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                </Form>



            </Space>

            <PriceDetails
                idSelected={idSelection}
            />

        </LoaderDispatch>
    </PoppiDialog>
}

const PriceDetails = (props) => {
    const { idSelected } = props
    const priceListData = useSelector(state => state.profile.prices)

    var priceDetails = priceListData.find((priceData) => (priceData.id === idSelected))

    if (!priceDetails) {
        return null
    }
    return <React.Fragment>
        <h3>Services</h3>
        <PoppiDescription
            items={
                priceDetails.services.map((serviceData) => {
                    return {
                        label: serviceData.label,
                        description: serviceData.enabled ?
                            `From ${currency(serviceData.price)} (${serviceData.time} min)`
                            :
                            'Not Available'

                    }
                })
            }
        />
        <h3>Spa</h3>
        <PoppiDescription
            items={
                priceDetails.spa.map((serviceData) => {
                    return {
                        label: serviceData.label,
                        description: serviceData.enabled ?
                            `From ${currency(serviceData.price)} (${serviceData.time} min)`
                            :
                            'Not Available'

                    }
                })
            }
        />
    </React.Fragment>


}

export default DialogPriceServices