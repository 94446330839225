import { Avatar, useMantineTheme } from "@mantine/core"
import dayjs from "dayjs"
import React from "react"
import { useNavigate } from "react-router"
import PoppiButton from "../../../widget/buttons/poppiButton"
import PoppiDescription from "../../../widget/description/poppiDescription"
import PoppiEmpty from "../../../widget/empty/poppiEmpty"
import PoppiIcon from "../../../widget/icons/poppiIcons"
import ImageLoader from "../../../widget/images/imageLoader"
import PoppiList from "../../../widget/list/PoppiList"
import PoppiListItem from "../../../widget/list/PoppiListItem"

const BookingsList = (props) => {
    const { bookings } = props
    const theme = useMantineTheme()
    const navigate = useNavigate()
    if (bookings.length === 0) {
        return <PoppiEmpty
            description='No Bookings' />
    }
    return <PoppiList
        dataSource={[...bookings].sort((a, b) => (
            dayjs(`${a.CalendarId} ${a.time}`, 'DDMMYYY HH:mm').isBefore(dayjs(`${b.CalendarId} ${b.time}`, 'DDMMYYY HH:mm'))
                ? 1 * (props.sortDesc ? -1 : 1)
                : -1 * (props.sortDesc ? -1 : 1)
        ))}
        renderItem={booking => {
            return <PoppiListItem
                key={booking.Dog.id}
                bordered={false}
                avatar={
                    <Avatar size='s' >
                        <ImageLoader imageId={booking.Dog?.photoId} />
                    </Avatar>
                }
                title={`${booking.Dog?.name} (${booking.Dog?.breed})`}
                description={`${dayjs(booking.time, 'HH:mm').format('HH:mm')} - ${dayjs(booking.time, 'HH:mm').add(booking.total.time, 'minutes').format('HH:mm')} (${dayjs(booking.CalendarId, 'DDMMYYYY').format('ddd DD/MM/YYYY')})`}

            >

                <PoppiDescription
                    width='30vw'
                    extra={[<PoppiButton
                        iconOnly={true}
                        key={`Deatils${booking.id}`}
                        icon={<PoppiIcon icon='details' />}
                        onClick={() => { navigate(`/dashboard/bookings/${booking.id}`) }} >Details</PoppiButton>]}
                    title={'Services'}
                    bordered={true}
                    items={[
                        ...booking.services.length > 0 ? booking.services.map((service) => {
                            return {
                                background: theme.colors.green[1],
                                label: service.label,
                                description: `${service.time} min`
                            }
                        }) : [],
                        ...booking.spa.length > 0 ? booking.spa.map((spa) => {
                            return {
                                background: theme.colors.blue[1],
                                label: spa.label,
                                description: `${spa.time} min`
                            }
                        }) : [],
                        ...booking?.extras?.length > 0 ? booking.extras.map((extra) => {
                            return {
                                background: theme.colors.red[1],
                                label: extra.label,
                                description: `${extra.time} min`
                            }
                        }) : [],
                    ]}
                />
                {booking.notes &&
                    <PoppiDescription
                        title='Notes'
                        bordered={true}
                        items={[
                            {
                                description: <span style={{ whiteSpace: 'pre-line' }}>
                                    {booking.notes}
                                </span>

                            }

                        ]}
                    />
                }
                <small>Booked on {dayjs(booking.createdAt).format('ddd DD/MM/YYYY HH:mm')}</small>
            </PoppiListItem>
        }}
    />


}

BookingsList.defaultProps = {
    sortDesc: false
}
export default BookingsList 