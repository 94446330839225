import { createSlice } from '@reduxjs/toolkit'
import ApiRequest from '../../api/api'

export const dashboardStore = createSlice({
    name: 'dashboard',
    initialState: {
        users: [],
        dogs: [],
        prices: [],
        dog: {},
        user: {},
        booking: {},
        bookings: []
    },
    reducers: {
        getAllDataSuccess: (state, action) => {
            state['users'] = action.payload
            var listDogs = []
            action.payload.forEach(element => {
                if (element.dogsList) {
                    element.dogsList.forEach(dog => {
                        listDogs.push({ ...dog, owner: `${element.surname} ${element.name}` })
                    });
                }
            });
            state['dogs'] = listDogs
            return state
        },
        getAllUsersSuccess: (state, action) => {
            state['users'] = action.payload
            return state
        },
        getAllDogsSuccess: (state, action) => {
            state['dogs'] = action.payload
            return state
        },
        getDogDetailsSuccess: (state, action) => {
            state['dog'] = action.payload
            return state
        },
        getUserDetailsSuccess: (state, action) => {
            state['user'] = action.payload
            return state
        },
        getPricesSuccess: (state, action) => {
            state['prices'] = action.payload
            return state
        },
        getBookingSuccess: (state, action) => {
            state['booking'] = action.payload
        },
        getBookingsSuccess: (state, action) => {
            state['bookings'] = action.payload
        }
    },
});


// Actions
const { getAllDataSuccess, getBookingSuccess, getAllUsersSuccess, getAllDogsSuccess, getDogDetailsSuccess, getUserDetailsSuccess, getPricesSuccess, getBookingsSuccess } = dashboardStore.actions

export const getPrices = (date) => async dispatch => new Promise((resolve, reject) => {
    ApiRequest(`/prices/get`, 'GET').then(
        (response) => {
            dispatch(getPricesSuccess(response));
            resolve(response)
        },
        (error) => {
            console.log(error)
            reject(error)
        }
    )
})

export const getAllUsers = () => async dispatch => new Promise((resolve, reject) => {
    ApiRequest(`/admin/users`, 'GET').then(
        (response) => {
            dispatch(getAllUsersSuccess(response));
            resolve(response)
        },
        (error) => {
            console.log(error)
            reject(error)
        }
    )
})

export const getAllDogs = () => async dispatch => new Promise((resolve, reject) => {
    ApiRequest(`/admin/dogs`, 'GET').then(
        (response) => {
            dispatch(getAllDogsSuccess(response));
            resolve(response)
        },
        (error) => {
            console.log(error)
            reject(error)
        }
    )
})

export const getDogDetails = (dogId) => async dispatch => new Promise((resolve, reject) => {
    ApiRequest(`/admin/dogs/${dogId}`, 'GET').then(
        (response) => {
            dispatch(getDogDetailsSuccess(response));
            resolve(response)
        },
        (error) => {
            console.log(error)
            reject(error)
        }
    )
})

export const updateDogDetails = (dogId, dogData) => async dispatch => new Promise((resolve, reject) => {
    ApiRequest(`/admin/dogs/${dogId}/update`, 'POST', dogData).then(
        (response) => {
            dispatch(getDogDetailsSuccess(response));
            resolve(response)
        },
        (error) => {
            console.log(error)
            reject(error)
        }
    )
})

export const updateDogPhoto = (dogId, photoData) => async dispatch => new Promise((resolve, reject) => {
    ApiRequest(`/admin/dogs/${dogId}/updatePhoto`, 'POST', photoData).then(
        (response) => {
            dispatch(getDogDetailsSuccess(response));
            resolve(response)
        },
        (error) => {
            console.log(error)
            reject(error)
        }
    )
})

export const getUserDetails = (userId) => async dispatch => new Promise((resolve, reject) => {
    ApiRequest(`/admin/users/${userId}`, 'GET').then(
        (response) => {
            dispatch(getUserDetailsSuccess(response));
            resolve(response)
        },
        (error) => {
            console.log(error)
            reject(error)
        }
    )
})

export const setPrice = (priceData) => async dispatch => new Promise((resolve, reject) => {
    ApiRequest(`/prices/${priceData.id}/set`, 'POST', priceData).then(
        (response) => {
            dispatch(getPricesSuccess(response));
            resolve(response)
        },
        (error) => {
            console.log(error)
            reject(error)
        }
    )
})

export const deletePrice = (priceData) => async dispatch => new Promise((resolve, reject) => {
    ApiRequest(`/prices/${priceData.id}/delete`, 'DELETE', priceData).then(
        (response) => {
            dispatch(getPricesSuccess(response));
            resolve(response)
        },
        (error) => {
            console.log(error)
            reject(error)
        }
    )
})

export const getAllData = () => async dispatch => new Promise((resolve, reject) => {
    ApiRequest(`/admin/getAllData`, 'GET').then(
        (response) => {
            dispatch(getAllDataSuccess(response));
            resolve(response)
        },
        (error) => {
            console.log(error)
            reject(error)
        }
    )
})

export const changeRegisteredDogStatus = (dogData) => async dispatch => new Promise((resolve, reject) => {
    ApiRequest(`/admin/changeRegisteredDogStatus/`, 'POST', dogData).then(
        (response) => {
            dispatch(getAllDataSuccess(response));
            resolve(response)
        },
        (error) => {
            console.log(error)
            reject(error)
        }
    )
})

export const deleteDog = (userId, dogId) => async dispatch => new Promise((resolve, reject) => {
    ApiRequest(`/admin/user/${userId}/dogs/${dogId}/delete`, 'DELETE').then(
        (response) => {
            dispatch(getAllDataSuccess(response));
            resolve(response)
        },
        (error) => {
            console.log(error)
            reject(error)
        }
    )
})

export const getAllBookingData = (bookingId) => async dispatch => new Promise((resolve, reject) => {
    ApiRequest(`/admin/bookings/getAll`, 'GET').then(
        (response) => {
            dispatch(getBookingsSuccess(response));
            resolve(response)
        },
        (error) => {
            console.log(error)
            reject(error)
        }
    )

})

export const getBookingData = (bookingId) => async dispatch => new Promise((resolve, reject) => {
    ApiRequest(`/admin/bookings/${bookingId}/get`, 'GET').then(
        (response) => {
            dispatch(getBookingSuccess(response));
            resolve(response)
        },
        (error) => {
            console.log(error)
            reject(error)
        }
    )

})


export const editBookingData = (bookingId, bookingData) => async dispatch => new Promise((resolve, reject) => {
    ApiRequest(`/admin/bookings/${bookingId}/edit`, 'POST', bookingData).then(
        (response) => {
            dispatch(getBookingSuccess(response));
            resolve(response)
        },
        (error) => {
            console.log(error)
            reject(error)
        }
    )

})

export const setBookingNotes = (bookingId, notesData) => async dispatch => new Promise((resolve, reject) => {
    ApiRequest(`/admin/bookings/${bookingId}/updateNotes`, 'POST', notesData).then(
        (response) => {
            dispatch(getBookingSuccess(response));
            resolve(response)
        },
        (error) => {
            console.log(error)
            reject(error)
        }
    )

})

export default dashboardStore.reducer

