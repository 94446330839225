import React, { useState } from "react"
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from "react-router"
import { formatAddress } from "../../../utils/address"
import PoppiLinkButton from "../../../widget/buttons/PoppiLinkButton"
import PoppiOptionsMenu from "../../../widget/buttons/poppiOptionsMenu"
import PoppiCard from "../../../widget/card/poppiCard"
import PoppiDescription from "../../../widget/description/poppiDescription"
import PoppiEmpty from "../../../widget/empty/poppiEmpty"
import PoppiIcon from "../../../widget/icons/poppiIcons"
import ImageLoader from "../../../widget/images/imageLoader"
import { LoaderDispatch } from "../../../widget/loaders/loaderDipatch"
import BookingsList from "../calendar/bookingsList"
import { getDogDetails } from "../dashboardStore"
import { DialogChangeDogPhoto } from "./dialogChangeDogPhoto"
import DialogEditDogData from "./dialogEditDogData"
import dayjs from "dayjs"

export const DashboardDogDetails = (props) => {
    const dashboardData = useSelector(state => state.dashboard)
    const params = useParams()

    return <LoaderDispatch
        dispatch={getDogDetails(params.dogId)}
    >
        {!dashboardData?.dog ?
            <PoppiEmpty
                description='Dog not Found'>
            </PoppiEmpty>
            :
            <DogDataDetails
                dogData={dashboardData.dog}
            />
        }
    </LoaderDispatch>
}

const DogDataDetails = (props) => {
    const { dogData } = props
    const [activeTab, setActiveTab] = useState('data')
    const [dialogEditDogData, setDialogEditDogData] = useState({ open: false, dogData: dogData })
    const [dialogChangeDogPhoto, setDialogChangeDogPhoto] = useState({ open: false, dogData: dogData })

    const tabList = [
        {
            key: 'data',
            label: 'Data',
            icon: <PoppiIcon icon='details' />,
            content: <DogData dogData={dogData} />
        },
        {
            key: 'bookings',
            label: 'Bookings',
            icon: <PoppiIcon icon='booking' />,
            content: <DogBookings dogData={dogData} />

        }
    ]
    return <>
        {dialogEditDogData.open && <DialogEditDogData
            open={dialogEditDogData.open}
            handleClose={() => { setDialogEditDogData({ open: false, dogData: dogData }) }}
            dogData={dialogEditDogData.dogData} />}
        {dialogChangeDogPhoto.open && <DialogChangeDogPhoto
            open={dialogChangeDogPhoto.open}
            handleClose={() => { setDialogChangeDogPhoto({ open: false, dogData: dogData }) }}
            dogData={dialogEditDogData.dogData} />}

        <PoppiCard
            cover={<ImageLoader imageId={dogData.photoId} size='m' />}

            extra={
                <PoppiOptionsMenu
                    items={[
                        {
                            key: 'editPhoto',
                            label: 'Edit Photo',
                            icon: <PoppiIcon icon='photo' />,
                            onClick: () => { setDialogChangeDogPhoto({ open: true, dogData: dogData }) }
                        }
                    ]}
                    onEdit={() => { setDialogEditDogData({ open: true, dogData: dogData }) }}
                />
            }
            icon={dogData.approved ?
                <PoppiIcon icon='approved' color='green' />
                :
                <PoppiIcon icon='notApproved' color='red' />}
            title={dogData.name}
            subtitle={dogData.breed}
            tabList={tabList}
            activeTab={activeTab}
            setActiveTab={(key) => { setActiveTab(key) }}
        >
        </PoppiCard>
    </>
}

const DogData = (props) => {
    const navigate = useNavigate()
    const { dogData } = props

    return <>
        <PoppiDescription
            items={[
                {
                    label: 'Status',
                    description: dogData.approved ? 'Approved' : 'Not Approved'
                },
                {
                    label: 'Owner',
                    description: <PoppiLinkButton
                        onClick={() => { navigate(`/dashboard/users/${dogData.User.id}`) }}> {dogData.User.surname}  {dogData.User.name}</PoppiLinkButton>
                },
                {
                    label: 'Breed',
                    description: dogData.breed
                },
                {
                    label: 'Date of Birth',
                    description: dogData.dateOfBirth
                },
                {
                    label: 'Gender: ',
                    description: dogData.gender
                },
                {
                    label: 'Size',
                    description: dogData.data.size
                },
                {
                    label: 'Hair Type',
                    description: dogData.data.hairType
                },
                {
                    label: 'Diseases',
                    description: dogData.data.diseases?.length > 0 ? dogData.data.diseases.join(', ') : 'None specified'
                },
                {
                    label: 'Behaviour',
                    description: dogData.data.behaviour?.length > 0 ? dogData.data.behaviour.join(', ') : 'None specified'
                },
                {
                    label: 'Health',
                    description: dogData.data.health?.length > 0 ? dogData.data.health.join(', ') : 'None specified'
                },
                {
                    label: 'Notes',
                    description: dogData.data.notes ? dogData.data.notes : 'None specified'
                },
                {
                    label: 'Veterinarian',
                    description: dogData.data.vetName
                },
                {
                    label: 'Veterinarian Address',
                    description: formatAddress(dogData.data.vetAddress)
                },
                {
                    label: 'Registered On',
                    description: dayjs(dogData.createdAt).format('DD/MM/YYYY HH:mm')
                },
            ]}
        />
    </>
}

const DogBookings = (props) => {
    const { dogData } = props
    return <BookingsList
        sortDesc={true}
        bookings={[...dogData?.Bookings]
            .map((item) => {
                return {
                    ...item,
                    Dog: dogData
                }
            })
        } />
}
export default DashboardDogDetails 