import dayjs from "dayjs"
import React, { useState } from "react"
import { useSelector } from 'react-redux'
import PoppiSteps from "../../../widget/steps/poppiSteps"
import { currency } from "../../../widget/text/formatText"
import ConfirmationSection from "./bookingSections/customBookingConfirmationSection"
import { CustomBookingDateSelection } from "./bookingSections/customBookingDateSelection"
import { CustomBookingDogSelection } from "./bookingSections/customBookingDogSelection"
import { CustomBookingServiceSelection } from "./bookingSections/customBookingServiceSelection"
import { CustomBookingSpaSelection } from "./bookingSections/customBookingSpaSelection"
import { CustomBookingSummarySection } from "./bookingSections/customBookingSummarySection"

const CustomBookingPage = (props) => {
    const authData = useSelector(state => state.auth)
    const [activeStep, setActiveStep] = useState(0)
    const [bookingData, setBookingData] = useState({
        UserId: authData.id,
        DogId: null,
        dogData: null,
        services: [],
        spa: [],
        extras: [],
        tip: 0,
        CalendarId: dayjs().format('DDMMYYYY'),
        time: null,
        total: { price: 0, time: 0 }
    })

    function calculateTotalPrice(bookingData) {
        var total = { price: 0, time: 0 }
        if (bookingData?.services) {
            bookingData.services.map((service) => {
                if (service.value === 'NailClipping') {
                    total.price += service.price
                } else {
                    total.price += service.price + bookingData.dogData?.extraCharge
                }
                total.time += service.time
                return null
            })

        }
        if (bookingData?.spa) {
            bookingData.spa.map((spa) => {
                total.price += spa.price
                total.time += spa.time
                return null
            })
        }
        if (bookingData?.extras) {
            bookingData.extras.map((extra) => {
                total.price += extra.price
                total.time += extra.time
                return null
            })
        }
        total.price += bookingData.tip
        return total
    }
    // return <PoppiResult
    //     status={'info'}
    //     title='Online Booking not available'
    //     subtitle='The online booking is not available at the moment. We are gonna launch it soon.'
    //     extra={[
    //         <PoppiButton to='/' icon={<PoppiIcon icon='home' />} variant='default'>Homepage</PoppiButton>
    //     ]}
    // />
    return <>
        <h3>
            {`Total: ${currency(bookingData.total.price)}, Time: ${bookingData.total.time} min`}
        </h3>
        <PoppiSteps
            activeStep={activeStep}
            steps={[
                {
                    title: 'Dog',
                    content: <CustomBookingDogSelection
                        bookingData={bookingData}
                        setBookingData={setBookingData}
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                    />
                },
                {
                    title: 'Services',
                    content: <CustomBookingServiceSelection
                        bookingData={bookingData}
                        setBookingData={setBookingData}
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                        calculateTotalPrice={calculateTotalPrice}
                    />
                },
                {
                    title: 'Spa',
                    content: <CustomBookingSpaSelection
                        bookingData={bookingData}
                        setBookingData={setBookingData}
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                        calculateTotalPrice={calculateTotalPrice}
                    />
                },
                {
                    title: 'Date',
                    content: <CustomBookingDateSelection
                        bookingData={bookingData}
                        setBookingData={setBookingData}
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                    />
                },
                {
                    title: 'Summary',
                    content: <CustomBookingSummarySection
                        calculateTotalPrice={calculateTotalPrice}
                        bookingData={bookingData}
                        setBookingData={setBookingData}
                        activeStep={activeStep}
                        setActiveStep={setActiveStep} />
                },
                {
                    title: 'Confirmation',
                    content: <ConfirmationSection
                        calculateTotalPrice={calculateTotalPrice}
                        bookingData={bookingData}
                        setBookingData={setBookingData}
                        activeStep={activeStep}
                        setActiveStep={setActiveStep} />
                }
            ]}>
        </PoppiSteps>

    </>
}

export default CustomBookingPage