import { Avatar, List } from "antd"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import { formatAddress } from "../../utils/address"
import PoppiButton from "../../widget/buttons/poppiButton"
import PoppiCard from "../../widget/card/poppiCard"
import PoppiPageHeader from "../../widget/card/poppiPageHeader"
import PoppiDescription from "../../widget/description/poppiDescription"
import PoppiEmpty from "../../widget/empty/poppiEmpty"
import PoppiIcon from "../../widget/icons/poppiIcons"
import ImageLoader from "../../widget/images/imageLoader"
import { LoaderDispatch } from "../../widget/loaders/loaderDipatch"
import DialogPetForm from "./dialogPetForm"
import ProfileBookings from "./profileBookings"
import { getUserDogs } from "./profileStore"
import { Box, Tabs } from "@mantine/core"
import PoppiList from "../../widget/list/PoppiList"
import PoppiListItem from "../../widget/list/PoppiListItem"

const ProfilePage = (props) => {
    const authData = useSelector(state => state.auth)
    const [openDialogNewPet, setOpenDialogNewPet] = useState(false)

    return <PoppiPageHeader
        title='Your Profile'
        align='left'
    >
        <Tabs defaultValue={'bookings'}>
            <Tabs.List grow>
                <Tabs.Tab value="bookings"
                    leftSection={<PoppiIcon icon={'booking'} />}>
                    Bookings
                </Tabs.Tab>
                <Tabs.Tab value="dogs"
                    leftSection={<PoppiIcon icon={'pets'} />}>
                    Dogs
                </Tabs.Tab>
                <Tabs.Tab value="account"
                    leftSection={<PoppiIcon icon={'profile'} />}>
                    Account
                </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value='bookings'>
                <ProfileBookings userId={authData.id} />
            </Tabs.Panel>

            <Tabs.Panel value='dogs'>
                <Box pt='sm' pb='sm'>
                    <PoppiButton
                        fullWidth={true}
                        variant='default'
                        icon={<PoppiIcon icon='add' />}
                        onClick={() => {
                            setOpenDialogNewPet(true)
                        }}>New dog
                    </PoppiButton>
                </Box>
                <DialogPetForm
                    open={openDialogNewPet}
                    handleClose={() => { setOpenDialogNewPet(false) }} />
                <UserDogsList setOpenDialogNewPet={setOpenDialogNewPet} />
            </Tabs.Panel>

            <Tabs.Panel value='account'>
                <PoppiCard
                    title='Your Personal Informations'
                >
                    <PoppiDescription

                        items={
                            [
                                {
                                    label: 'Name',
                                    description: authData.name
                                },
                                {
                                    label: 'Surname',
                                    description: authData.surname
                                },
                                {
                                    label: 'Email',
                                    description: authData.email
                                },
                                {
                                    label: 'Phone Number',
                                    description: authData.phone
                                },
                                {
                                    label: 'Address',
                                    description: formatAddress(authData.address)
                                },
                            ]
                        }
                    />
                </PoppiCard>
            </Tabs.Panel>
        </Tabs>
    </PoppiPageHeader >
}
const UserDogsList = (props) => {
    const authData = useSelector(state => state.auth)
    const profileData = useSelector(state => state.profile)



    return <LoaderDispatch
        dispatch={getUserDogs(authData.id)}
        loadingText='Loding the list of your dogs'
    >

        {!profileData?.dogs.length > 0 ?
            <React.Fragment>

                <PoppiEmpty description={`You don't have any pet registered in you profile.`} >
                    <PoppiButton
                        variant='default'
                        icon={<PoppiIcon icon='add' />}
                        onClick={() => {
                            props.setOpenDialogNewPet(true)
                        }}>Add new dog
                    </PoppiButton>
                </PoppiEmpty>

            </React.Fragment>
            :
            <PoppiList
            title='List of your dogs'
                itemLayout="vertical"
                size='small'
                dataSource={
                    profileData.dogs
                }
                renderItem={dogData => {
                    return <DogDataDetails dogData={dogData} key={dogData.id} />
                }}
            />
        }



    </LoaderDispatch >
}
export const DogDataDetails = (props) => {
    const { dogData } = props
    return <PoppiListItem
        avatar={
            <Avatar icon={<ImageLoader imageId={dogData.photoId} size='s' />} />

        }
        title={dogData.name}
        description={dogData.breed}
    >
        <PoppiDescription
            items={[
                {
                    label: 'Date of Birth',
                    description: dogData.dateOfBirth
                },
                {
                    label: 'Gender: ',
                    description: dogData.gender
                },
                {
                    label: 'Size',
                    description: dogData.data.size
                },
                {
                    label: 'Hair Type',
                    description: dogData.data.hairType
                },
                {
                    label: 'Diseases',
                    description: dogData.data.diseases?.length > 0 ? dogData.data.diseases.join(', ') : 'None specified'
                },
                {
                    label: 'Behaviour',
                    description: dogData.data.behaviour?.length > 0 ? dogData.data.behaviour.join(', ') : 'None specified'
                },
                {
                    label: 'Health',
                    description: dogData.data.health?.length > 0 ? dogData.data.health.join(', ') : 'None specified'
                },
                {
                    label: 'Notes',
                    description: dogData.data.notes ? dogData.data.notes : 'None specified'
                },
            ]}
        />
    </PoppiListItem>
    return <List.Item

    >
        <List.Item.Meta
            avatar={
                <Avatar icon={<ImageLoader imageId={dogData.photoId} size='s' />} />

            }
            title={dogData.name}
            description={dogData.breed}
        />
        <PoppiDescription
            items={[
                {
                    label: 'Date of Birth',
                    description: dogData.dateOfBirth
                },
                {
                    label: 'Gender: ',
                    description: dogData.gender
                },
                {
                    label: 'Size',
                    description: dogData.data.size
                },
                {
                    label: 'Hair Type',
                    description: dogData.data.hairType
                },
                {
                    label: 'Diseases',
                    description: dogData.data.diseases?.length > 0 ? dogData.data.diseases.join(', ') : 'None specified'
                },
                {
                    label: 'Behaviour',
                    description: dogData.data.behaviour?.length > 0 ? dogData.data.behaviour.join(', ') : 'None specified'
                },
                {
                    label: 'Health',
                    description: dogData.data.health?.length > 0 ? dogData.data.health.join(', ') : 'None specified'
                },
                {
                    label: 'Notes',
                    description: dogData.data.notes ? dogData.data.notes : 'None specified'
                },
            ]}
        />
    </List.Item>


}

export default ProfilePage