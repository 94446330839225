import { Form } from "antd"
import React, { useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { descriptionConsent, listOptionsBehaviours, listOptionsDiseases, listOptionsHealth } from "../../utils/descriptions"
import PoppiButton from "../../widget/buttons/poppiButton"
import PoppiDialog from "../../widget/dialog/poppiDialog"
import poppiNotification from "../../widget/dialog/poppiNotification"
import PoppiFormField from "../../widget/form/poppiFormField"
import { LoaderDispatch } from "../../widget/loaders/loaderDipatch"
import { addProfileDog, getUserPrices } from "./profileStore"

export const DialogPetForm = (props) => {

    const [isLoading, setIsLoading] = useState(false)
    const authData = useSelector(store => store.auth)
    const profileData = useSelector(state => state.profile)
    const dispatch = useDispatch()
    // New Form
    const [form] = Form.useForm()
    // Old Form

    function handleNewDog(data) {
        setIsLoading(true)
        dispatch(addProfileDog(authData.id, data))
            .then((response) => {
                setIsLoading(false)
                props.handleClose()
            },
                (error) => {
                    setIsLoading(false)
                    poppiNotification({ type: 'error' })

                }

            )
    }

    if (!props.open) {
        return null
    }
    return <PoppiDialog
        footer={
            [<PoppiButton
                loading={isLoading}
                variant='default'
                onClick={() => { form.submit() }}>
                Save</PoppiButton>]
        }
        fullScreen={true}
        open={props.open}
        handleClose={props.handleClose}
        title={props.editPet ? 'Edit you pet' : 'New Dog'}>
        <LoaderDispatch
            dispatch={getUserPrices()}
        >
            <Form
                form={form}
                layout='vertical'
                onFinish={(data) => { handleNewDog(data) }}>
                <h3>Dog Data</h3>
                <PoppiFormField
                    name='name'
                    label='Name'
                    rules={[
                        {
                            required: true,
                            message: 'Required Field'
                        },
                    ]}
                />
                <PoppiFormField
                    name='dateOfBirth'
                    label='Date of Birth'
                    type='date'
                    rules={[
                        {
                            required: true,
                            message: 'Required Field'
                        },
                    ]}
                />
                <PoppiFormField
                    name='PriceId'
                    label='Breed'
                    type='select'
                    options={
                        profileData.prices.map((item) => {
                            return { value: item.id, label: item.breed }
                        })
                    }
                    rules={[
                        {
                            required: true,
                            message: 'Required Field'
                        },
                    ]}
                />
                <PoppiFormField
                    name='gender'
                    label='Gender'
                    type='radio'
                    options={
                        [{ value: 'Male', label: 'Male' },
                        { value: 'Female', label: 'Female' }]
                    }
                    rules={[
                        {
                            required: true,
                            message: 'Required Field'
                        },
                    ]}
                />
                <PoppiFormField
                    name={['data', 'size']}
                    label='Size'
                    type='radio'
                    options={
                        [
                            { value: 'Toy', label: 'Toy' },
                            { value: 'S', label: 'Small' },
                            { value: 'M', label: 'Medium' },
                            { value: 'L', label: 'Large' },
                            { value: 'XL', label: 'Extra Large' },
                        ]
                    }
                    rules={[
                        {
                            required: true,
                            message: 'Required Field'
                        },
                    ]}
                />
                <PoppiFormField
                    name={['data', 'hairType']}
                    label='Hair Type'
                    type='select'
                    options={
                        [
                            { value: 'Soft', label: 'Soft' },
                            { value: 'Whool', label: 'Whool' },
                            { value: 'Harsh', label: 'Harsh' },
                        ]
                    }
                />
                <PoppiFormField
                    name={['data', 'diseases']}
                    label='Diseases'
                    type='select'
                    mode='multiple'
                    options={
                        listOptionsDiseases
                    }
                />
                <PoppiFormField
                    name={['data', 'behaviour']}
                    label='Behaviour'
                    type='select'
                    mode='multiple'
                    options={
                        listOptionsBehaviours
                    }
                />
                <PoppiFormField
                    name={['data', 'health']}
                    label='Health'
                    type='select'
                    mode='multiple'
                    options={
                        listOptionsHealth
                    }
                />
                <PoppiFormField
                    name={'notes'}
                    label='Notes'
                />
                <PoppiFormField
                    type='upload'
                    name={'dogPhoto'}
                    label='Present Picture of your dog (size and coats condition visible)'
                    form={form}
                    rules={[
                        {
                            required: true,
                            message: 'Required Field'
                        },
                    ]}
                />

                <h3>Veterinarian</h3>
                <PoppiFormField
                    name={['data', 'vetName']}
                    label='Name'
                    rules={[
                        {
                            required: true,
                            message: 'Required Field'
                        },
                    ]}
                />
                <PoppiFormField
                    name={['data', 'vetAddress', 'line1']}
                    label='Address Line 1'
                    rules={[
                        {
                            required: true,
                            message: 'Required Field'
                        },
                    ]}
                />
                <PoppiFormField
                    name={['data', 'vetAddress', 'line2']}
                    label='Address Line 2'
                    rules={[
                        {
                            required: true,
                            message: 'Required Field'
                        },
                    ]}
                />
                <PoppiFormField
                    name={['data', 'vetAddress', 'city']}
                    label='City'
                    rules={[
                        {
                            required: true,
                            message: 'Required Field'
                        },
                    ]}
                />
                <PoppiFormField
                    name={['data', 'vetAddress', 'country']}
                    label='Country'
                    type='country'
                    rules={[
                        {
                            required: true,
                            message: 'Required Field'
                        },
                    ]}
                />
                <PoppiFormField
                    name={['data', 'vetAddress', 'postcode']}
                    label='Postcode'
                    rules={[
                        {
                            required: true,
                            message: 'Required Field'
                        },
                    ]}
                />
                <h3>Terms and Conditions</h3>
                <p>
                    <small>{descriptionConsent}</small>

                </p>
                <PoppiFormField
                    type='checkbox'
                    name={'consent'}
                    label='I accept the terms and conditions'
                    rules={[
                        {
                            required: true,
                            message: 'Should accept terms and conditions'
                        }

                    ]}
                />
            </Form>

        </LoaderDispatch>
    </PoppiDialog >
}


export default DialogPetForm