import { SimpleGrid } from "@mantine/core"
import React, { useState } from "react"
import PoppiButton from "../../widget/buttons/poppiButton"
import PoppiCard from "../../widget/card/poppiCard"
import PoppiPageHeader from "../../widget/card/poppiPageHeader"
import PoppiIcon from "../../widget/icons/poppiIcons"
import DialogPriceServices from "./dialogPrice"
const GroomingPage = (props) => {

    const [openDialogPrice, setOpenDialogPrice] = useState({ open: false, serviceValue: null })

    return <React.Fragment>
        {openDialogPrice.open &&
            <DialogPriceServices
                open={openDialogPrice.open}
                serviceValue={openDialogPrice.serviceValue}
                handleClose={() => {
                    setOpenDialogPrice({ open: false, serviceValue: null })
                }}
            >

            </DialogPriceServices>
        }
        <PoppiPageHeader
            title='Grooming'
        >
            <SimpleGrid cols={{ base: 1, sm: 1, md: 2, lg: 3 }}>

                <PoppiCard
                    title='Wash and Brush / Puppy Introduction'
                    actions={
                        [
                            <PoppiButton
                                icon={<PoppiIcon icon='prices' />}
                                fullWidth={true}
                                onClick={() => {
                                    setOpenDialogPrice({ open: true })
                                }}>Prices</PoppiButton>,
                            <PoppiButton
                                fullWidth={true}
                                icon={<PoppiIcon icon='booking' />}
                                variant='default' to='/booking'>Book</PoppiButton>
                        ]
                    }
                    subtitle={
                        <ul>
                            <li>Wash, brush and blow dry</li>
                            <li>General Health Check</li>
                            <li>Nails clipping</li>
                            <li>Ears cleaning</li>
                            <li>Maintenance advices</li>
                            <small>*De matting not included</small>
                        </ul>
                    }
                >

                </PoppiCard>


                <PoppiCard
                    title='Hand Stripping'
                    subtitle={
                        <ul>
                            <li>Full hand strip</li>
                            <li>Wash, brush and blow dry</li>
                            <li>General health check</li>
                            <li>Pads</li>
                            <li>Nails clipping</li>
                            <li>Ears cleaning</li>
                            <li>Maintenance advices</li>
                            <small>*De matting not included</small>
                        </ul>
                    }
                    actions={
                        [
                            <PoppiButton
                                icon={<PoppiIcon icon='prices' />}
                                fullWidth={true}
                                onClick={() => {
                                    setOpenDialogPrice({ open: true })
                                }}>Prices</PoppiButton>,
                            <PoppiButton
                                icon={<PoppiIcon icon='booking' />}
                                variant='default' fullWidth={true} to='/booking'>Book</PoppiButton>
                        ]
                    }
                />

                <PoppiCard
                    title='De-Shedding'
                    subtitle={
                        <ul>
                            <li>Wash, brush and blow dry</li>
                            <li>General health check</li>
                            <li>Good de-shedding</li>
                            <li>Pads</li>
                            <li>Nails clipping</li>
                            <li>Bum</li>
                            <li>Sanitary area</li>
                            <li>Ears cleaning</li>
                            <li>Cut Style</li>
                            <li>Maintenance advices</li>
                            <small>*De matting not included</small>
                        </ul>
                    }
                    actions={
                        [
                            <PoppiButton
                                icon={<PoppiIcon icon='prices' />}
                                fullWidth={true}
                                onClick={() => {
                                    setOpenDialogPrice({ open: true })
                                }}>Prices</PoppiButton>,
                            <PoppiButton
                                icon={<PoppiIcon icon='booking' />}
                                variant='default' fullWidth={true} to='/booking'>Book</PoppiButton>
                        ]
                    }
                />

                <PoppiCard
                    title='Full Groom'
                    subtitle={
                        <ul>
                            <li>Wash, brush and blow dry</li>
                            <li>General health check</li>
                            <li>Pads</li>
                            <li>Nails clipping</li>
                            <li>Bum</li>
                            <li>Sanitary area</li>
                            <li>Ears cleaning</li>
                            <li>Cut Style</li>
                            <li>Maintenance advices</li>
                            <small>*De matting not included</small>
                        </ul>
                    }
                    actions={
                        [
                            <PoppiButton
                                icon={<PoppiIcon icon='prices' />}
                                fullWidth={true}
                                onClick={() => {
                                    setOpenDialogPrice({ open: true })
                                }}>Prices</PoppiButton>,
                            <PoppiButton
                                icon={<PoppiIcon icon='booking' />}
                                variant='default' fullWidth={true} to='/booking'>Book</PoppiButton>
                        ]
                    }
                />

                <PoppiCard
                    bottomTitle={true}
                    title='SPA Pawdicure'
                    subtitle={
                        <ul>
                            <li>Paws washing</li>
                            <li>Pads massage</li>
                            <li>Pads balm</li>
                            <li>Nails clipping</li>
                            <li>Feet hair trimming</li>
                            <li>Maintenance advices</li>
                        </ul>
                    }
                    actions={
                        [
                            <PoppiButton
                                icon={<PoppiIcon icon='prices' />}
                                fullWidth={true}
                                onClick={() => {
                                    setOpenDialogPrice({ open: true })
                                }}>Prices</PoppiButton>,
                            <PoppiButton
                                icon={<PoppiIcon icon='booking' />}
                                variant='default' fullWidth={true} to='/booking'>Book</PoppiButton>
                        ]
                    }
                />
            </SimpleGrid>
        </PoppiPageHeader>
    </React.Fragment>
}

export default GroomingPage