import { notifications } from "@mantine/notifications"

export function poppiNotification(newProps) {
    const props = {
        message: 'Error',
        description: 'An error occured in the system',
        type: 'warning',
        duration: 5000,
        ...newProps
    }
    notifications.show(
        {
            color: props.type === 'warning' ? 'orange' :
                props.type === 'error' ? 'red' : 'blue',

            withBorder: true,
            title: props.message,
            message: props.description,
            placement: props.placement,
            autoclose: props.duration
        }
    )
}

export default poppiNotification
