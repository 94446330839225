import React from "react"
import { useNavigate } from "react-router"
import PoppiButton from "../../../../widget/buttons/poppiButton"
import PoppiCard from "../../../../widget/card/poppiCard"
import PoppiResult from "../../../../widget/empty/poppiResult"
import PoppiIcon from "../../../../widget/icons/poppiIcons"
import { SummaryDetails } from "./customBookingSummarySection"
import dayjs from "dayjs"

export const CustomBookingConfirmationSection = (props) => {
    const { bookingData } = props
    const navigate = useNavigate()

    return <PoppiCard
        title='Appointment confirmed'
        actions={[
            <PoppiButton
                icon={<PoppiIcon icon='dashboard' />}
                fullWidth={true}
                onClick={() => {
                    navigate('/dashboard')
                }}
            >Dashboard</PoppiButton>,
            <PoppiButton
                icon={<PoppiIcon icon='calendar' />}
                variant='default'
                fullWidth={true}
                onClick={() => {
                    navigate('/dashboard/calendar')

                }}
            >Calendar</PoppiButton>
        ]}
    >
        <PoppiResult
            status='success'
            title='Appointment booked!'
            subtitle={`${dayjs(bookingData.CalendarId, 'DDMMYYYY').format('dddd DD/MM/YYYY')} at ${bookingData.time}`}
            extra={
                <SummaryDetails bookingData={bookingData} />
            } />

    </PoppiCard >
}

export default CustomBookingConfirmationSection