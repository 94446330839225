import Dexie from 'dexie';

export class MySubClassedDexie extends Dexie {

    constructor() {
        super('BespokeBarksIndexedDB');
        this.version(1).stores({
            Images: 'id, data' // Primary key and indexed props
        });
    }
}

export const PoppiIndexedDB = new MySubClassedDexie();