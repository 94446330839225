import React from "react"
import PoppiCard from "../../widget/card/poppiCard"
import PoppiPageHeader from "../../widget/card/poppiPageHeader"
import PoppiIcon from "../../widget/icons/poppiIcons"



const ContactsPage = (props) => {

    return <PoppiPageHeader
        title='Contacts'
    >

        <PoppiCard
            title='How to find us'
        >
            <PoppiCard
                bordered={false}
                icon={<PoppiIcon icon='phone' />}
                title='Whatsapp (Text Only)'
                subtitle={<a href='https://wa.me/447528368810' >+44 7528 368 810</a>}
            />

            <PoppiCard
                bordered={false}
                icon={<PoppiIcon icon='location' />}
                title='Location'
                subtitle={<a href='https://goo.gl/maps/ZXyauvJiwncgxkYUA' >56 Containerville, 1 Emma Street, E2 9FP, London</a>}
            >
                <div className="google-map-code">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2826.4182569615223!2d-0.062281588231105786!3d51.532447833428385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761de369a8a93f%3A0x4464d64597cc93ad!2sBespoke%20Barks!5e0!3m2!1sen!2suk!4v1647957493318!5m2!1sen!2suk"
                        title="WhereAreWe"
                        width="100%" height="450" frameborder="0" style={{ border: 0 }} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                </div>
            </PoppiCard>

        </PoppiCard>

    </PoppiPageHeader >
}

export default ContactsPage