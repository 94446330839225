import React from 'react'
import { Result } from "antd"
import PoppiButton from "../widget/buttons/poppiButton"
import PoppiPageHeader from "../widget/card/poppiPageHeader"
import PoppiIcon from "../widget/icons/poppiIcons"
import PoppiEmpty from '../widget/empty/poppiEmpty'

const PageNotFound = (props) => {

    return <PoppiPageHeader
        to='/'
        title='Page not found'>
        <PoppiEmpty
            image={<PoppiIcon icon='notFound' />}
            title={'Page not found'}

        >
            <PoppiButton fullWidth={true} to='/' icon={<PoppiIcon icon='home' />}>Homepage</PoppiButton>
        </PoppiEmpty>
    </PoppiPageHeader>


}

export default PageNotFound