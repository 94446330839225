import { Grid, SimpleGrid, Text, UnstyledButton, getThemeColor, useMantineTheme } from "@mantine/core"
import { Space } from "antd"
import moment from "moment"
import React from 'react'
import { useSelector } from "react-redux"
import { useNavigate } from "react-router"
import PoppiButton from "../../widget/buttons/poppiButton"
import PoppiCard from "../../widget/card/poppiCard"
import PoppiEmpty from "../../widget/empty/poppiEmpty"
import PoppiIcon from "../../widget/icons/poppiIcons"
import LoaderDispatch from "../../widget/loaders/loaderDipatch"
import BookingsList from "./calendar/bookingsList"
import { getBookingsDate } from "./calendar/calendarStore"
import { DashboardNewDogsList } from "./dogs/dashboardNewDogsList"

const DashboardHomepage = (props) => {
    const navigate = useNavigate()
    const calendarData = useSelector(store => store.calendar)
    const theme = useMantineTheme()

    const ButtonDashboard = (props) => {
        return <UnstyledButton
            onClick={() => { navigate(props.to) }}
            style={{
                width:'100%',
                alignItems: 'center',
                justifyItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                padding: '10px',
                borderRadius: '5px',
                color: getThemeColor('white', theme),
                background: getThemeColor('blue', theme)
            }}
        >
            {props.icon}
            <Text size="xs" mt={7}>
                {props.label}
            </Text>
        </UnstyledButton>
    }
    return <Space direction="vertical" style={{ display: 'flex' }}>
        {/* <SimpleGrid cols={{ base: 3, sm: 3, md: 5 }} > */}
        <Grid>
            <Grid.Col span={'auto'}>
                <ButtonDashboard
                    label='Dogs'
                    icon={<PoppiIcon icon='pets' />}
                    to='/dashboard/dogs' />
            </Grid.Col>
            <Grid.Col span={'auto'}>
                <ButtonDashboard
                    label='Users'
                    icon={<PoppiIcon icon='users' />}
                    to='/dashboard/users' />
            </Grid.Col>
            <Grid.Col span={'auto'}>
                <ButtonDashboard
                    label='Calendar'
                    icon={<PoppiIcon icon='calendar' />}
                    to='/dashboard/calendar' />
            </Grid.Col>
            <Grid.Col span={'auto'}>
                <ButtonDashboard
                    label='Prices'
                    icon={<PoppiIcon icon='prices' />}
                    to='/dashboard/prices' />
            </Grid.Col>
            <Grid.Col span={'auto'}>
                <ButtonDashboard
                    label='Custom Booking'
                    icon={<PoppiIcon icon='booking' />}
                    to='/dashboard/bookings/custom' />
            </Grid.Col>
        </Grid>
        {/* </SimpleGrid> */}
        <DashboardNewDogsList />
        <PoppiCard
            title={'Appointment of today'}
            subtitle={moment().format('ddd DD/MM/YYYY')}
            extra={
                <PoppiButton
                    onClick={() => { navigate('/dashboard/calendar') }}
                    icon={<PoppiIcon icon='calendar'>Calendar</PoppiIcon>}>Calendar</PoppiButton>
            }
        >
            {/* List of appoitments for the day */}
            <LoaderDispatch
                dispatch={getBookingsDate(moment().format('DDMMYYYY'))}
                loadingText='Loading Bookings'
            >
                <BookingsList
                    bookings={calendarData?.bookings?.Bookings} />
            </LoaderDispatch>
        </PoppiCard>
        <ButtonDashboard
                    label='Payments'
                    icon={<PoppiIcon icon='payment' />}
                    to='/dashboard/payments' />
    </Space>
}


export default DashboardHomepage