import { DateInput, DatePicker, DatePickerInput, DatesProvider } from "@mantine/dates"
import dayjs from "dayjs"
import React, { useState } from "react"
import PoppiButton from "../../../widget/buttons/poppiButton"
import PoppiCard from "../../../widget/card/poppiCard"
import poppiNotification from "../../../widget/dialog/poppiNotification"
import PoppiEmpty from "../../../widget/empty/poppiEmpty"
import PoppiSelectableList from "../../../widget/form/poppiSelectableList"
import LoaderApiRequest from "../../../widget/loaders/loaderApiRequest"
import PoppiIcon from "../../../widget/icons/poppiIcons"
import { Center, Indicator } from "@mantine/core"

export const DateSelection = (props) => {
    const { bookingData, setBookingData, activeStep, setActiveStep } = props
    const [dateSelected, setDateSelected] = useState(
        bookingData.CalendarId
            ? dayjs(bookingData.CalendarId, 'DDMMYYYY')
            : dayjs(new Date()));

    return <PoppiCard
        title='Slot Selection'
        actions={[
            <PoppiButton
                icon={<PoppiIcon icon='arrowBack' />}
                fullWidth={true}
                onClick={() => {
                    setBookingData({
                        ...bookingData,
                        CalendarId: dayjs().format('DDMMYYYY'),
                        time: null,
                    })
                    setActiveStep(activeStep - 1)
                }}
            >Back</PoppiButton>,
            <PoppiButton
                icon={<PoppiIcon icon='arrowForward' />}
                variant='default'
                fullWidth={true}
                onClick={() => {
                    if (!(bookingData.CalendarId && bookingData.time)) {
                        console.log(bookingData)
                        poppiNotification({
                            message: 'Missing Data',
                            description:
                                'You need to select an available slot',
                        })
                    } else {
                        setActiveStep(activeStep + 1)
                    }
                }}
            >Next</PoppiButton>
        ]}
    >
        <LoaderApiRequest
            method='GET'
            path={`/calendar/opendays/${bookingData.typeService}`}
        >
            {(responseDatesOpen) => {
                console.log(responseDatesOpen)
                return <>
                    <DatePickerInput
                        defaultDate={dayjs(dateSelected).toDate()}
                        mt='sm'
                        label='Date'
                        minDate={new Date()}
                        onChange={(value) => {
                            setBookingData({
                                ...bookingData,
                                CalendarId: dayjs(value).format('DDMMYYYY'),
                                time: null
                            })
                            setDateSelected(dayjs(value))
                        }}
                        value={dayjs(dateSelected).toDate()}
                        renderDay={(date) => {
                            var found = responseDatesOpen.find((item) => (item.id === dayjs(date).format('DDMMYYYY')))
                            return <Indicator size={6} color="green" offset={-5} disabled={!found}>
                                <div>{date.getDate()}</div>
                            </Indicator>
                        }}
                        excludeDate={(date => !(responseDatesOpen.find((item) => (item.id === dayjs(date).format('DDMMYYYY')))))}
                    />


                    <LoaderApiRequest
                        method='POST'
                        refresh={dateSelected}
                        values={bookingData}
                        path={`/calendar/${dateSelected.format('DDMMYYYY')}/availability`}
                    >
                        {(response) => {
                            if (response) {
                                return <PoppiSelectableList
                                    title='Select an available slot'
                                    onChange={(value) => {
                                        if (bookingData.time === value) {
                                            setBookingData({ ...bookingData, time: null })
                                        } else {
                                            setBookingData({ ...bookingData, time: value })
                                        }
                                    }
                                    }
                                    items={[
                                        {
                                            selected: bookingData.time === response,
                                            value: response,
                                            label: response
                                        }
                                    ]}></PoppiSelectableList>
                            }
                            return <PoppiEmpty
                                description='No appointment available this day' />

                        }}

                    </LoaderApiRequest>
                </>
            }}
        </LoaderApiRequest>

    </PoppiCard >
}

export default DateSelection