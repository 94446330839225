import { Group, Title } from "@mantine/core"
import { Badge, Form } from "antd"
import React, { useState } from "react"
import { useDispatch } from 'react-redux'
import PoppiButton from "../../../widget/buttons/poppiButton"
import PoppiDescription from "../../../widget/description/poppiDescription"
import PoppiDialog from "../../../widget/dialog/poppiDialog"
import poppiNotification from "../../../widget/dialog/poppiNotification"
import PoppiFormField from "../../../widget/form/poppiFormField"
import { editBookingData } from "../dashboardStore"

export const DialogEditServices = (props) => {

    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const { bookingData } = props

    function handleChangeServices(data) {
        setIsLoading(true)
        var newBooking = { ...bookingData }
        if (newBooking.services?.length > 0) {
            newBooking.services = bookingData.services.map((service, index) => {
                return { ...service, time: data.bookingData.services[index].time }
            })
        }
        if (newBooking.spa?.length > 0) {
            newBooking.spa = bookingData.spa.map((spa, index) => {
                return { ...spa, time: data.bookingData.spa[index].time }
            })
        }
        if (newBooking.extras?.length > 0) {
            newBooking.extras = bookingData.extras.map((extra, index) => {
                return { ...extra, time: data.bookingData.extras[index].time }
            })
        }

        // Calculate New Timing
        var totalTime = 0
        if (newBooking.services?.length > 0) {
            newBooking.services.map((service, index) => {
                totalTime += service.time
                return null
            })
        }
        if (newBooking.spa?.length > 0) {
            newBooking.spa.map((spa, index) => {
                totalTime += spa.time
                return null
            })
        }
        if (newBooking.extras?.length > 0) {
            newBooking.extras.map((extra, index) => {
                totalTime += extra.time
                return null
            })
        }
        dispatch(editBookingData(bookingData.id, { ...newBooking, total: { ...newBooking.total, time: totalTime } }))
            .then((response) => {
                setIsLoading(false)
                props.handleClose()
            },
                (error) => {
                    setIsLoading(false)
                    poppiNotification({ type: 'error' })

                }

            )
    }
    if (!props.open) {
        return null
    }
    console.log('Refresh')
    return <PoppiDialog
        footer={[
            <PoppiButton
                variant='default'
                loading={isLoading}
                onClick={() => {
                    // Save the data
                    form.submit()
                }}>
                Save</PoppiButton>]}
        open={props.open}
        handleClose={props.handleClose}
        title={'Edit Services'}>
        <Form form={form}
            layout='vertical'
            onFinish={(data) => { handleChangeServices(data) }}
            initialValues={{
                bookingData: bookingData
            }}>
            <Title order={5}>
                Services
            </Title>
            {
                bookingData.services.length > 0 && bookingData?.services.map((service, index) => {
                    return <Group>
                        <Badge status="success" text={service.label} />
                        <PoppiFormField
                            append='min'
                            name={['bookingData', 'services', index, 'time']}
                            label='Duration'
                            type='number'
                            rules={[
                                {
                                    required: true,
                                    message: 'Required Field'
                                },
                            ]}
                        />
                    </Group>
                })
            }
            {
                bookingData.spa.length > 0 && bookingData.spa.map((spa, index) => {
                    return <Group>

                        <Badge status="processing" text={spa.label} />
                        <PoppiFormField
                            append='min'
                            name={['bookingData', 'spa', index, 'time']}
                            label='Duration'
                            type='number'
                            rules={[
                                {
                                    required: true,
                                    message: 'Required Field'
                                },
                            ]}
                        />
                    </Group>
                })
            }
            {
                bookingData?.extras?.length > 0 && bookingData.extras.map((extra, index) => {
                    return <Group>
                        <Badge status="warning" text={extra.label} />
                        <PoppiFormField
                            append='min'
                            name={['bookingData', 'extras', index, 'time']}
                            label='Duration'
                            type='number'
                            rules={[
                                {
                                    required: true,
                                    message: 'Required Field'
                                },
                            ]}
                        />
                    </Group>
                })
            }


            {/* <PoppiDescription
                title='Services'
                bordered={true}
                items={[
                    ...bookingData.services.length > 0 ? bookingData.services.map((service, index) => {
                        return {
                            label: <Badge status="success" text={service.label} />,
                            description:
                                <PoppiFormField
                                    append='min'
                                    name={['bookingData', 'services', index, 'time']}
                                    label='Duration'
                                    // type='number'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Required Field'
                                        },
                                    ]}
                                />
                        }
                    }) : [],
                    ...bookingData.spa.length > 0 ? bookingData.spa.map((spa, index) => {
                        return {
                            label: <Badge status="processing" text={spa.label} />,
                            description: <PoppiFormField
                                append='min'
                                name={['bookingData', 'spa', index, 'time']}
                                label='Duration'
                                // type='number'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required Field'
                                    },
                                ]}
                            />
                        }
                    }) : [],
                    ...bookingData?.extras?.length > 0 ? bookingData.extras.map((extra, index) => {
                        return {
                            label: <Badge status="warning" text={extra.label} />,
                            description: <PoppiFormField
                                append='min'
                                name={['bookingData', 'extras', index, 'time']}
                                label='Duration'
                                // type='number'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required Field'
                                    },
                                ]}
                            />
                        }
                    }) : [],
                ]}
            /> */}
        </Form >
    </PoppiDialog >
}

export default DialogEditServices