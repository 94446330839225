import { modals } from '@mantine/modals';
export const PoppiConfirm = (
    props,
    defaultProps = {
        title: 'Confirm',
        content: '',
        onConfirm: () => { },
        onCancel: () => { },
        okType: 'primary'
    }
) => {
    modals.openConfirmModal({
        title: props.title ? props.title : defaultProps.title,
        children: props.content ? props.content : defaultProps.content,
        labels: { confirm: 'Confirm', cancel: 'Cancel' },
        confirmProps: { color: 'red' },
        onCancel: props.onCancel ? props.onCancel : defaultProps.onCancel,
        onConfirm: props.onConfirm ? props.onConfirm : defaultProps.onConfirm,
    })
};

export default PoppiConfirm
