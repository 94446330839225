import { Carousel, Image } from "antd";
import { v4 as uuid } from 'uuid';
import React from 'react'

export const PoppiCarousel = (props) => {
    if (props.images?.length === 1) {
        return <div style={{ background: '#A9D2F0' }}>
            <center>
                <Image src={props.images[0]}
                // style={{ maxWidth: props.width, maxHeight: props.height }}
                />
            </center>
        </div>
    }
    // if (props.height || props.width) {
    //     return <div style={{ background: '#A9D2F0' }}>
    //         <Carousel
    //             dotPosition="left"
    //             autoplay={props.autoplay}
    //             effect={props.effect}>
    //             {props.images.map((image, index) => {
    //                 return <center key={uuid()}>
    //                     <Image src={image}
    //                     // style={{ maxWidth: props.width, maxHeight: props.height }}
    //                     />
    //                 </center>

    //             })}

    //         </Carousel>
    //     </div>
    // }
    return <Carousel
        dotPosition="left"
        autoplay={props.autoplay}
        effect={props.effect}>
        {props.images.map((image, index) => {
            return <center key={uuid()}>
                <Image src={image}
                // style={{ maxWidth: props.width, maxHeight: props.height }}
                />
            </center>

        })}

    </Carousel>
}

PoppiCarousel.defaultProps = {
    effect: 'fade',
    width: null,
    height: null,
    images: [],
    autoplay: true,
}

export default PoppiCarousel