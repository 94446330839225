import ReactGA from 'react-ga';

export function trackEvent(category, action, value = '') {
    ReactGA.event({
        category: category,
        action: action,
        label: value
    });
}

export function trackPage(url) {
    ReactGA.pageview(url);
}