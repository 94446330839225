import { Checkbox, Space, Stack, Text, Title, useMantineTheme } from "@mantine/core"
import React, { useState } from "react"
import PoppiButton from "../../../widget/buttons/poppiButton"
import PoppiCard from "../../../widget/card/poppiCard"
import poppiNotification from "../../../widget/dialog/poppiNotification"
import PoppiSelectableList from "../../../widget/form/poppiSelectableList"
import PoppiIcon from "../../../widget/icons/poppiIcons"

export const TypeOfServiceSelection = (props) => {
    const { bookingData, setBookingData, activeStep, setActiveStep, calculateTotalPrice } = props
    const [osteopathyTC, setOsteopathyTC] = useState({ checked: false, error: false })
    const theme = useMantineTheme()
    return <PoppiCard
        actions={[
            <PoppiButton
                icon={<PoppiIcon icon='arrowBack' />}
                fullWidth={true}
                onClick={() => {
                    setBookingData(
                        {
                            ...bookingData,
                            services: [],
                            total: calculateTotalPrice({
                                ...bookingData,
                                type: null
                            })
                        }
                    )
                    setActiveStep(activeStep - 1)
                }}
            >Back</PoppiButton>,
            <PoppiButton
                icon={<PoppiIcon icon='arrowForward' />}
                variant='default'
                fullWidth={true}
                onClick={() => {
                    if (bookingData?.typeService) {
                        if (bookingData?.typeService === 'osteopathy' && !osteopathyTC.checked) {
                            setOsteopathyTC({ checked: false, error: true })
                            poppiNotification({
                                message: 'Missing Data',
                                description:
                                    'You need to accept Terms and conditions for the Osteopathy service',
                            })
                        } else {
                            setActiveStep(activeStep + 1)
                        }
                    } else {
                        poppiNotification({
                            message: 'Missing Data',
                            description:
                                'You need to select a type of service',
                        })
                    }
                }}
            >Next</PoppiButton>
        ]} >
        <Stack>
            <PoppiSelectableList
                title='Type of Service'
                onChange={(valueSelected) => {
                    // Max one service
                    if (bookingData?.typeService === valueSelected) {
                        setBookingData({
                            ...bookingData,
                            typeService: null,
                        })
                    } else {
                        setBookingData({
                            ...bookingData,
                            typeService: valueSelected
                        })
                    }

                }}
                items={
                    [
                        {
                            selected: bookingData.typeService === 'grooming',
                            value: 'grooming',
                            label: 'Grooming',
                            description: 'Grooming services for the dog',
                            icon: <PoppiIcon icon='grooming' />
                        },
                        {
                            selected: bookingData.typeService === 'osteopathy',
                            value: 'osteopathy',
                            label: 'Osteopathy',
                            description: 'Osteopathy services for the dog',
                            icon: <PoppiIcon icon='osteopathy' />
                        }
                    ]
                }
            />
            {bookingData.typeService === 'osteopathy' &&

                <Stack gap={0} p='xs'>

                    <Title size='2em'>Osteopathy Terms and conditions</Title>
                    <Title size='1em'>
                        Treatment plans
                    </Title>
                    <Text>
                        An animal manual practitioner will typically treat an animal 2 to 3 times before reassessing their approach. The therapist will consider how the animal is adapting to the treatment and if there are any side effects following a session. If the animal's condition worsens, the practitioner might refer them back to the vet.
                    </Text>
                    <Title size='1em'>
                        Insurance
                    </Title>
                    <Text>
                        It's the owner's responsibility to contact their insurance provider to confirm that manual therapy is covered by their policy. The owner is also responsible for settling any fees and reclaiming them from the insurance company.
                    </Text>
                    <Title size='1em'>
                        Treatment for non-clinical cases

                    </Title>
                    <Text>
                        In the UK, animal manual therapists can treat non-clinical cases without a veterinary referral, such as for preventative or competition care. However, if the animal is unwell or has an underlying condition, the therapist must inform the vet and get their approval before the first session.
                    </Text>
                    <Title size='1em'>
                        Aftercare

                    </Title>
                    <Text>
                        It is the owner's responsibility to follow the therapist's guidance regarding management and care for their animal after an osteopathic treatment. In case the animal's condition worsens, the owner must contact the veterinarian and alert the therapist.
                    </Text>
                    <Space h='xs'></Space>
                    <Checkbox
                        checked={osteopathyTC?.checked}
                        onChange={(e) => { setOsteopathyTC({ ...osteopathyTC, checked: e.target.checked }) }}
                        error={osteopathyTC.error && 'Accept before proceeding'}
                        label='I accept the osteopathy terms and conditions'></Checkbox>
                </Stack>}
        </Stack>

    </PoppiCard >
}

export default TypeOfServiceSelection