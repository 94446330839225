import moment from "moment"
import React, { useState } from "react"
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from "react-router"
import PoppiDatePicker from "../../../widget/form/poppiDatePicker"
import { LoaderDispatch } from "../../../widget/loaders/loaderDipatch"
import { getAllBookingData } from "../dashboardStore"
import PoppiButton from "../../../widget/buttons/poppiButton"
import ApiRequest from "../../../api/api"
import LoaderApiRequest from "../../../widget/loaders/loaderApiRequest"
import { Accordion, Box, Divider, Group, Stack, Text } from "@mantine/core"
import PoppiIcon from "../../../widget/icons/poppiIcons"
const { RangePicker } = PoppiDatePicker;
export const DashboardPayments = (props) => {
    const dashboardData = useSelector(state => state.dashboard)
    const params = useParams()

    return <LoaderApiRequest
        method='GET'
        path='/payments/get'
    >
        {(response) => {
            console.log(response)
            return <Accordion>
                {response.map((item) => {
                    return <Accordion.Item key={item.id} value={item.id}>
                        <Accordion.Control>
                            <Stack gap={0}>
                                <Text>Date: {moment(item?.createdAt).format('DD/MM/YYYY HH:mm:ss')}</Text>
                                <Text>Amount: {JSON.stringify(item?.amountMoney)}</Text>
                                <Text>Email: {item?.buyerEmailAddress}</Text>
                                <Text>UserId: {item?.note}</Text>
                                <Text c={item.status === 'COMPLETED' ? 'green' : 'red'}>Status: {item.status}</Text>
                            </Stack>
                        </Accordion.Control>
                        <Accordion.Panel>

                            {Object.keys(item).map((key) => {
                                if (key === 'createdAt') {
                                    return <Text>{key}: {moment(item[key]).format('DD/MM/YYYY HH:mm:ss')}</Text>

                                }
                                return <Text>{key}: {JSON.stringify(item[key])}</Text>
                            })}
                        </Accordion.Panel>
                    </Accordion.Item>
                })}
            </Accordion>
        }}
        {/* {
            JSON.stringify(dashboardData.bookings)
        } */}
    </LoaderApiRequest>
}


export default DashboardPayments 