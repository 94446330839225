import { Center, Stack, Text, Title } from "@mantine/core";
import { Empty } from "antd";
import React from "react";
import PoppiIcon from "../icons/poppiIcons";

export const PoppiEmpty = (props) => {
    return <Center>
        <Stack gap='sm' m={10} align="center">
            {props.image ? props.image :
                <PoppiIcon icon='empty' size='5em' color='grey' />}
            <Stack gap={0} align="center">
                {props.title && <Title>{props.title}</Title>}
                <Text>{props.description ? props.description : 'No Results'}</Text>
            </Stack>

            {props.children}
        </Stack>
    </Center>
    if (props.image) {
        return <Empty
            image={props.image}
            description={props.description}>
            {props.children}
        </Empty>
    }
    return <Empty
        description={props.description}>
        {props.children}
    </Empty>
}

PoppiEmpty.defaultProps = {
    title: null,
    image: null,
    description: null
}

export default PoppiEmpty