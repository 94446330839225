import { browserLocalPersistence, setPersistence, signInWithEmailAndPassword } from '@firebase/auth'
import { Container, Group, Paper, Space, Text, Title } from '@mantine/core'
import { useForm, yupResolver } from '@mantine/form'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import PoppiLinkButton from '../widget/buttons/PoppiLinkButton'
import PoppiButton from '../widget/buttons/poppiButton'
import PoppiPageHeader from '../widget/card/poppiPageHeader'
import poppiNotification from '../widget/dialog/poppiNotification'
import PoppiResult from '../widget/empty/poppiResult'
import PoppiMatineFormField from '../widget/form/poppiMantineFormField'
import PoppiIcon from '../widget/icons/poppiIcons'
import { auth } from './auth'

const LoginPage = (props) => {
    const authData = useSelector(state => state.auth)
    const location = useLocation()

    if (authData?.authenticated) {
        return <PoppiPageHeader
            extra={null}
            to={location.state?.urlRedirect ? location.state.urlRedirect : '/'}
            title={'Sign In to Bespoke Barks'}
        >

            <PoppiResult
                icon={null}
                title={'Already logged in'}
                extra={[
                    <PoppiButton
                        icon={<PoppiIcon icon='home' />}
                        to='/'
                        fullWidth={true}
                    >
                        Homepage
                    </PoppiButton>
                ]} />

        </PoppiPageHeader>
    }

    return <React.Fragment>

        <PoppiPageHeader
            extra={null}
            to='/'
            title={'Login to your account'}
        >
            <LoginForm />

        </PoppiPageHeader >
    </React.Fragment >

}

export const LoginForm = (props) => {
    const [isProcessing, setIsProcessing] = useState(false)
    const navigate = useNavigate()

    const schema = yup.object().shape({
        email: yup
            .string()
            .required('Invalid email')
            .email('Invalid email'),
        password: yup
            .string()
            .required('Required')
    });

    const formMantine = useForm({
        initialValues: { email: '', password: '' },
        validate: yupResolver(schema)
    })

    const handleLoginEmailAndPassword = data => {
        console.log(data)
        setIsProcessing(true)
        const { email, password } = data
        setPersistence(auth, browserLocalPersistence)
            .then(
                signInWithEmailAndPassword(auth, email, password)
                    .catch((error) => { console.log(error) })
                    .then((response) => {
                        setIsProcessing(false)
                        if (response) {
                            // Login the User
                            navigate('/profile')

                        } else {
                            // Show Error Message
                            poppiNotification(
                                {
                                    message: 'Wrong Credentials',
                                    description: 'Email or password not correct',
                                }
                            )
                        }

                    })
            )

    };
    return <>
        <Container size={420}>
            <Title ta='center'>Login</Title>
            <Text c='dimmed' ta={'center'}>Do not have an account yet?{' '}
                <PoppiLinkButton
                    to={'/signup'}
                >Create Account</PoppiLinkButton>
            </Text>
            <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                <form
                    onSubmit={formMantine.onSubmit(handleLoginEmailAndPassword)}>
                    <PoppiMatineFormField
                        label={'Email'}
                        placeholder={'Email'}
                        name={'email'}
                        form={formMantine}
                    />
                    <PoppiMatineFormField
                        label={'Password'}
                        placeholder={'Password'}
                        name={'password'}
                        type='password'
                        form={formMantine}
                    />
                    <Group justify='flex-end'>
                        <PoppiLinkButton
                            to={'/resetpassword'}
                        >Forgot Password?</PoppiLinkButton>
                    </Group>
                    <Space h='xs' />
                    <PoppiButton
                        fullWidth
                        loading={isProcessing}
                        icon={<PoppiIcon icon='login' />}
                        type="submit"
                    >
                        Login
                    </PoppiButton>
                </form>
            </Paper>
        </Container>
    </>
}

export default LoginPage
