import { createSlice } from '@reduxjs/toolkit';
import ApiRequest from '../../../api/api';
export const calendarStore = createSlice({
    name: 'calendar',
    initialState: {
        bookings: [],
        booking: null
    },
    reducers: {
        getBookingsSuccess: (state, action) => {
            state['bookings'] = action.payload
        },
        getMonthDataSuccess: (state, action) => {
            for (var dateData of action.payload) {
                state[dateData.id] = dateData
            }
        },
        setDateSuccess: (state, action) => {
            state[action.payload.id] = action.payload
        },
       
    },
});


// Actions
const { getBookingsSuccess, setCalendarDateSuccess, setDateSuccess, getMonthDataSuccess } = calendarStore.actions

export const getBookingsDate = (date) => async dispatch => new Promise((resolve, reject) => {
    ApiRequest(`/calendar/${date}/get`, 'GET').then(
        (response) => {
            dispatch(getBookingsSuccess(response));
            resolve(response)
        },
        (error) => {
            console.log(error)
            reject(error)
        }
    )

})



export const getMonthData = (month, year) => async dispatch => new Promise((resolve, reject) => {
    ApiRequest(`/calendar/month/${month}/${year}`, 'GET').then(
        (response) => {
            dispatch(getMonthDataSuccess(response));
            resolve(response)
        },
        (error) => {
            console.log(error)
            reject(error)
        }
    )

})

export const getCalendarDate = (date) => async dispatch => new Promise((resolve, reject) => {
    ApiRequest(`/calendar/${date}/get`, 'GET').then(
        (response) => {
            console.log('Done Calendar')
            dispatch(setDateSuccess(response));
            resolve(response)
        },
        (error) => {
            console.log(error)
            reject(error)
        }
    )

})

export const setCalendarDate = (date, dateData) => async dispatch => new Promise((resolve, reject) => {
    ApiRequest(`/calendar/${date}/set`, 'POST', dateData).then(
        (response) => {
            dispatch(setDateSuccess(response));
            resolve(response)
        },
        (error) => {
            console.log('Error')
            reject(error)
        }
    )
})

export const setCalendarBooking = (date, bookingData) => async dispatch => new Promise((resolve, reject) => {
    ApiRequest(`/calendar/${date}/setBooking`, 'POST', bookingData).then(
        (response) => {
            dispatch(setCalendarDateSuccess({ date: date, dateData: response }));
            resolve(response)
        },
        (error) => {
            console.log('Error')
            reject(error)
        }
    )
})

export default calendarStore.reducer

