import { Col, Form, Image, Row, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import Compressor from 'compressorjs';
import React from 'react';
import PoppiButton from '../buttons/poppiButton';
import poppiNotification from '../dialog/poppiNotification';
import PoppiIcon from '../icons/poppiIcons';

export const PoppiImageUpload = (props) => {

    const photoData = Form.useWatch(props.name, props.form)
    return <Form.Item
        name={props.name}
        label={props.label}
        rules={props.rules}
    >
        {photoData ?
            <Row justify='space-between' align='middle'>
                <Col>
                    <Image src={photoData} height='100px' />

                </Col>
                <Col>
                    <PoppiButton
                        icon={<PoppiIcon icon='delete' />}
                        onClick={() => { props.form.setFieldValue(props.name, null) }}>Delete</PoppiButton></Col>
            </Row>
            :
            <ImgCrop>
                <Upload.Dragger
                    listType="picture-card"
                    name={`files_${props.name}`}
                    maxCount={1}
                    beforeUpload={(image) => {
                        if (image) {
                            if (image?.type.includes('image')) {
                                new Compressor(image, {
                                    maxWidth: 500,
                                    maxHeight: 500,
                                    convertSize: 100000,
                                    quality: 0.2, // 0.6 can also be used, but its not recommended to go below.
                                    success: (compressedResult) => {
                                        // compressedResult has the compressed file.
                                        // Use the compressed file to upload the images to your server.        
                                        if (compressedResult) {
                                            const reader = new FileReader()
                                            reader.readAsDataURL(compressedResult)
                                            reader.onload = () => {
                                                props.form.setFieldValue(props.name, reader.result)
                                            }
                                        }
                                    },
                                });
                            } else {
                                poppiNotification({
                                    message: 'Warning', description: 'You can upload only images'
                                })

                            }
                        }
                    }}>
                    <PoppiIcon icon='upload' /> Upload or drag
                    {/* <p className="ant-upload-drag-icon">
                    <PoppiIcon icon='upload' />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">Support for a single or bulk upload.</p> */}
                </Upload.Dragger>
            </ImgCrop>
        }
    </Form.Item >
}

export default PoppiImageUpload