import { Group, Stack } from '@mantine/core'
import React from 'react'
import { useNavigate } from "react-router-dom"
import { PoppiIconButton } from '../buttons/PoppiIconButton'
import PoppiIcon from '../icons/poppiIcons'

export const PoppiPageHeader = (props) => {
    const navigate = useNavigate()

    return <>
        <Group justify='space-between' pl={10} pr={10}>
            <Group >
                <PoppiIconButton onClick={() => { navigate(props.to) }}
                    icon={<PoppiIcon icon='arrowBack' />}
                />
                <Stack>
                    {props.title}
                    {props.subtitle}

                </Stack>

            </Group>
            {props.extra}
        </Group>
        {props.children}
    </>
}

PoppiPageHeader.defaultProps = {
    align: 'right',
    title: '',
    subtitle: null,
    to: '/',
    extra: null
}

export default PoppiPageHeader
