import dayjs from "dayjs"
import React from "react"
import { useNavigate } from "react-router"
import PoppiButton from "../../../widget/buttons/poppiButton"
import PoppiCard from "../../../widget/card/poppiCard"
import PoppiResult from "../../../widget/empty/poppiResult"
import PoppiIcon from "../../../widget/icons/poppiIcons"
import { SummaryDetails } from "./summarySection"

export const ConfirmationSection = (props) => {
    const { bookingData } = props
    const navigate = useNavigate()

    return <PoppiCard
        title='Appointment confirmed'
        actions={[
            <PoppiButton
                icon={<PoppiIcon icon='home' />}
                fullWidth={true}
                onClick={() => {
                    navigate('/')
                }}
            >Homepage</PoppiButton>,
            <PoppiButton
                icon={<PoppiIcon icon='profile' />}
                variant='default'
                fullWidth={true}
                onClick={() => {
                    navigate('/profile')

                }}
            >Profile</PoppiButton>
        ]}
    >
        <PoppiResult
            status='success'
            title='Appointment booked!'
            subtitle={`${dayjs(bookingData.CalendarId, 'DDMMYYYY').format('dddd DD/MM/YYYY')} at ${bookingData.time}`}
            extra={
                <SummaryDetails bookingData={bookingData} />
            } />

    </PoppiCard >
}

export default ConfirmationSection