import { createSlice } from '@reduxjs/toolkit'
import { TOKEN_LOCAL_STORAGE } from '../config'
import jwt_decode from "jwt-decode";
import { signOut } from '@firebase/auth';
import { auth } from './auth';
import { ApiRequest } from '../api/api'

function parseToken(token) {
    var decoded = {}
    if (token) {
        try {
            decoded = jwt_decode(token)
            decoded.authenticated = true
        } catch (error) {
            console.log(error)
        }
    }
    return {
        authenticated: !!decoded?.authenticated,
        email: decoded?.email ? decoded.email : null,
        exp: decoded?.exp ? decoded.exp : null,
        id: decoded?.user_id ? decoded.user_id : null,
        name: '',
        surname: '',
        phone: '',
        address: {},
        token: token
    }
}

export const authStore = createSlice({
    name: 'auth',
    initialState: parseToken(localStorage.getItem(TOKEN_LOCAL_STORAGE)),
    reducers: {
        loginSuccess: (state, action) => {
            state = action.payload
            return state
        },
        logoutSuccess: (state) => {
            const decodedToken = parseToken(null)
            state = decodedToken
            return state
        },
    },
});


// Actions
const { loginSuccess, logoutSuccess } = authStore.actions

export const login = (token) => async dispatch => {
    try {
        localStorage.setItem(TOKEN_LOCAL_STORAGE, token);
        // Fetch User Data from API
        const userId = parseToken(token).id
        var userData = {}

        dispatch(loginSuccess({ ...parseToken(token) }));
        try {
            userData = await ApiRequest(`/auth/user/${userId}/get`, 'GET')
        }
        catch (error) {
            console.log(error)
        }
        dispatch(loginSuccess({ ...parseToken(token), ...userData }));

    } catch (e) {
        return console.error(e.message);
    }
}

export const logout = () => async dispatch => {
    try {
        localStorage.removeItem(TOKEN_LOCAL_STORAGE)
        await signOut(auth)
        dispatch(logoutSuccess());
    } catch (e) {
        return console.error(e.message);
    }
}

export const resfreshUserData = () => async dispatch => {

    try {
        const token = localStorage.getItem(TOKEN_LOCAL_STORAGE);
        // Fetch User Data from API
        const userId = parseToken(token).user_id
        var userData = {}
        try {
            userData = await ApiRequest(`/auth/user/${userId}/get`, 'GET')
        }
        catch (error) {
            console.log(error)
        }
        dispatch(loginSuccess({ token: token, userData: userData }));

    } catch (e) {
        return console.error(e.message);
    }
}

export default authStore.reducer

