import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import authStore from './auth/authStore';
import calendarStore from "./sections/dashboard/calendar/calendarStore";
import dashboardStore from "./sections/dashboard/dashboardStore";
import profileStore from "./sections/profile/profileStore";
const reducer = combineReducers({
    // here we will be adding reducers
    auth: authStore,
    calendar: calendarStore,
    profile: profileStore,
    dashboard: dashboardStore
})

const store = configureStore(
    {
        reducer
    }
)

export default store