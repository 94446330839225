import { ActionIcon, Button } from "@mantine/core"
import React, { forwardRef } from "react"
import { useNavigate } from "react-router"

export const PoppiButton = forwardRef((props, ref) => {
    const navigate = useNavigate()
    if (props.onClick || props.to) {
        if (props.iconOnly) {
            return <ActionIcon
                ref={ref}
                variant={props.variant}
                onClick={
                    props.to ?
                        () => { navigate(props.to) }
                        :
                        props.onClick
                }   >
                {props.icon}
            </ActionIcon>
        }
        return <Button
            ref={ref}
            variant={props.variant}
            fullWidth={props.fullWidth}
            color={props.color}
            loading={props.loading}
            disabled={props.disabled}
            onClick={
                props.to ?
                    () => { navigate(props.to) }
                    :
                    props.onClick
            }
            type={props.type}
            size={props.size}
            // shape={props.shape}
            style={props.style}
            leftSection={props.icon ? props.icon : null}
        >
            {/* {props.icon ?
                <Space direction="horizontal" size='small' align='start'>
                    {props.icon}
                    {props.children}
                </Space>
                :
                props.children} */}
            {props.children}
        </Button >
    }
    return <Button
        ref={ref}
        color={props.color}
        fullWidth={props.fullWidth}
        variant={props.variant}
        loading={props.loading}
        disabled={props.disabled}
        type={props.type}
        size={props.size}
        shape={props.shape}
        style={props.style}
        leftSection={props.icon ? props.icon : null}
    >
        {/* {
            props.icon ?
                <Space direction="horizontal" size='small' align='start'>
                    {props.icon}
                    {props.children}
                </Space>
                :
                props.children
        } */}
        {props.children}
    </Button >

}
)

PoppiButton.defaultProps = {
    fullWidth: false,
    disabled: false,
    icon: null,
    type: 'default',
    size: 'md',
    onClick: () => { },
    to: null,
    loading: false,
    style: {},
    shape: 'round',
    htmlType: null,
    extraProps: {},
    iconOnly: false
}

export default PoppiButton