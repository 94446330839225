import { Avatar, Stack, useMantineTheme } from "@mantine/core"
import dayjs from "dayjs"
import { useSelector } from "react-redux"
import { v4 as uuid } from 'uuid'
import { calculatePayments } from "../../utils/payments"
import PoppiButton from "../../widget/buttons/poppiButton"
import PoppiDescription from "../../widget/description/poppiDescription"
import PoppiIcon from "../../widget/icons/poppiIcons"
import ImageLoader from "../../widget/images/imageLoader"
import PoppiList from "../../widget/list/PoppiList"
import PoppiListItem from "../../widget/list/PoppiListItem"
import LoaderDispatch from "../../widget/loaders/loaderDipatch"
import { currency } from "../../widget/text/formatText"
import { getUserBookings } from "./profileStore"

const ProfileBookings = (props) => {
    const profileStore = useSelector(store => store.profile)
    const { userId } = props

    return <LoaderDispatch
        dispatch={getUserBookings(userId)}
    >
        <PoppiList
            extra={<PoppiButton
                icon={<PoppiIcon icon='booking' />}
                to='/booking'
                variant='default'>
                New Appointment
            </PoppiButton>}
            title='List of you Bookings'
            dataSource={profileStore?.bookings
                 ? [...profileStore?.bookings].sort((a, b) => (
                dayjs(`${a.CalendarId} ${a.time}`, 'DDMMYYY HH:mm').isAfter(dayjs(`${b.CalendarId} ${b.time}`, 'DDMMYYY HH:mm'))
                    ? -1
                    : 1
            )) : profileStore.bookings
        }
            renderItem={(booking) => {
                return <ProfileBookingDetails
                    booking={booking}
                    key={booking.id}
                />
            }}
        >

        </PoppiList>

    </LoaderDispatch>

}

const ProfileBookingDetails = (props) => {
    const { booking } = props
    const theme = useMantineTheme()
    return <>
        <PoppiListItem
            bordered={false}
            avatar={
                <Avatar size='s' ><ImageLoader imageId={booking.Dog?.photoId} /></Avatar>

            }
            title={`${booking.Dog?.name} (${booking.Dog?.breed})`}
            description={`${dayjs(booking.time, 'HH:mm').format('HH:mm')} - ${dayjs(booking.time, 'HH:mm').add(booking.total.time, 'minutes').format('HH:mm')} (${dayjs(booking.CalendarId, 'DDMMYYYY').format('ddd DD/MM/YYYY')})`}
        >
            <PoppiDescription
                width='30vw'
                title='Services'
                bordered={true}
                items={[
                    ...booking.services.length > 0 ? booking.services.map((service) => {
                        return {
                            background: theme.colors.green[1],
                            label: service.label,
                            description: `${service.time} min`
                        }
                    }) : [],
                    ...booking.spa.length > 0 ? booking.spa.map((spa) => {
                        return {
                            background: theme.colors.blue[1],
                            label: spa.label,
                            description: `${spa.time} min`
                        }
                    }) : [],
                    ...booking?.extras?.length > 0 ? booking.extras.map((extra) => {
                        return {
                            background: theme.colors.red[1],
                            label: extra.label,
                            description: `${extra.time} min`
                        }
                    }) : [],
                ]}
            />
            <PoppiDescription
                bordered={true}
                title='Payment'
                items={
                    [
                        {
                            label: 'Total Price',
                            description: currency(booking.total.price)
                        },
                        {
                            label: 'Total Time',
                            description: `${booking.total.time} min`
                        },
                        {
                            label: 'Tip',
                            description: currency(booking.tip)
                        },
                        {
                            label: 'Payments',
                            description: <Stack direction="vertical">
                                {booking?.payments?.map((payment) => { return <div key={uuid()}>{currency(payment.amount)} {payment.type}</div> })}
                            </Stack>
                        },
                        {
                            label: 'Remaining Amount',
                            description: <Stack direction="vertical">
                                {currency(booking.total.price - calculatePayments(booking.payments))}
                            </Stack>
                        },
                    ]
                }
            />
        </PoppiListItem>
    </>
}

export default ProfileBookings