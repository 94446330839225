import { Space } from "antd"
import React from "react"
import { useSelector } from 'react-redux'
import { useParams } from "react-router"
import { formatAddress } from "../../../utils/address"
import PoppiCard from "../../../widget/card/poppiCard"
import PoppiDescription from "../../../widget/description/poppiDescription"
import PoppiEmpty from "../../../widget/empty/poppiEmpty"
import { LoaderDispatch } from "../../../widget/loaders/loaderDipatch"
import { getUserDetails } from "../dashboardStore"
import { DogDataDetails } from "../dogs/dashboardDogs"
import { Stack, UnstyledButton } from "@mantine/core"
import PoppiLinkButton from "../../../widget/buttons/PoppiLinkButton"
export const DashboardUserDetails = (props) => {
    const dashboardData = useSelector(state => state.dashboard)
    const params = useParams()

    return <LoaderDispatch
        dispatch={getUserDetails(params.userId)}
    >
        {!dashboardData?.user ?
            <PoppiEmpty
                description='User not Found'>
            </PoppiEmpty>
            :
            <UserDataDetails
                userData={dashboardData.user}
            />
        }
    </LoaderDispatch>
}

const UserDataDetails = (props) => {
    const { userData } = props


    return <Stack gap="md">
        <PoppiCard
            title={`${userData.surname} ${userData.name}`}>
            <PoppiDescription
                items={[
                    {
                        label: 'Address',
                        description: formatAddress(userData.address)
                    },
                    {
                        label: 'Phone',
                        description: <PoppiLinkButton href={`tel:${userData.phone}`} > {userData.phone} </PoppiLinkButton>
                    },
                    {
                        label: 'Email',
                        description: <PoppiLinkButton href={`mailto:${userData.email}`} > {userData.email} </PoppiLinkButton>
                    }
                ]}
            />
        </PoppiCard>
        <PoppiCard
            title={'Dogs'}>
            {userData.Dogs.map((dogData) => {
                return <DogDataDetails dogData={dogData} key={dogData.id} />
            })
            }
        </PoppiCard>

    </Stack>
}
export default DashboardUserDetails 