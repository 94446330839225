import { Checkbox, DatePicker, Form, Input, InputNumber, Radio, Select, Switch, TimePicker } from 'antd';
import { countries } from 'countries-list';
import React from 'react';
import PoppiDatePicker from './poppiDatePicker';
import PoppiImageUpload from './poppiImageUpload';
import PoppiTimePicker from './poppiTimePicker';
import { v4 as uuid } from 'uuid';

const { Option } = Select
const { TextArea } = Input

const PoppiFormField = (props) => {
    const { type } = props
    switch (type) {
        case 'upload':
            return <PoppiImageUpload {...props} />
        case 'checkbox':
            return <Form.Item
                valuePropName='checked'
                name={props.name}
                rules={props.rules}
            >
                <Checkbox >{props.label}</Checkbox>

            </Form.Item >
        case 'switch':
            return <Form.Item
                valuePropName='checked'
                name={props.name}
                rules={props.rules}
                style={props.style}
            >
                <Switch checkedChildren={props.label} unCheckedChildren={props.label} />
            </Form.Item >
        case 'radio':
            return <Form.Item
                name={props.name}
                label={props.label}
                rules={props.rules}
            >
                <Radio.Group>
                    {props.options.map((item) => {
                        return <Radio
                            key={uuid()}
                            value={item.value} > {item.label}</Radio>
                    })}
                </Radio.Group>
            </Form.Item >
        case 'select':
            return <Form.Item
                name={props.name}
                label={props.label}
                rules={props.rules}
            >
                <Select
                    showSearch
                    showArrow
                    optionFilterProp='children'
                    filterOption={(input, option) => {
                        return (option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0) || (option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                    }
                    }
                    mode={props.mode}
                >
                    {props.options.map((item) => {
                        return < Option
                            key={uuid()}
                            value={item.value} >{item.label}</Option>
                    })}
                </Select>
            </Form.Item >
        case 'country':
            return <Form.Item
                name={props.name}
                label={props.label}
                rules={props.rules}
            >
                <Select
                    showSearch
                    showArrow
                    optionFilterProp='children'
                    filterOption={(input, option) => {
                        return (option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0) || (option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                    }
                    }
                    mode={props.mode}
                >
                    {Object.keys(countries).sort((a, b) => (countries[a].name > countries[b].name ? 1 : -1)).map((code) => {
                        return < Option
                            key={uuid()}
                            value={code} >{countries[code].name}</Option>
                    })}
                </Select>
            </Form.Item >
        case 'date':
            return <Form.Item
                name={props.name}
                label={props.label}
                rules={props.rules}
            >
                <PoppiDatePicker
                    format={props.dateFormat} />
            </Form.Item>
        case 'time':
            return <Form.Item
                name={props.name}
                label={props.label}
                rules={props.rules}
            >
                <PoppiTimePicker
                    format={props.dateFormat} />
            </Form.Item>
        case 'password':
            return <Form.Item
                name={props.name}
                label={props.label}
                rules={props.rules}
            >
                <Input.Password />
            </Form.Item>
        case 'textarea':
            return <Form.Item
                name={props.name}
                label={props.label}
                rules={props.rules}
                style={props.style}
            >
                <TextArea
                    rows={props.rows}

                />
            </Form.Item>
        case 'number':
            return <Form.Item
                name={props.name}
                label={props.label}
                rules={props.rules}
                style={props.style}
            >
                <InputNumber
                    size={props.size}
                    addonBefore={props.prepend}
                    addonAfter={props.append}
                />
            </Form.Item>

        default:
            return <Form.Item
                name={props.name}
                label={props.label}
                rules={props.rules}
                style={props.style}
            >
                <Input
                    size={props.size}
                    addonBefore={props.prepend}
                    addonAfter={props.append}
                />
            </Form.Item>

    }
}


PoppiFormField.defaultProps = {
    // New Props
    form: null,
    dateFormat: 'DD/MM/YYYY',
    style: {},
    prepend: null,
    append: null,
    size: null,
    options: [],
    rows: 4
}


export default PoppiFormField