import { Avatar, Indicator } from "@mantine/core"
import dayjs from "dayjs"
import React from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router"
import PoppiButton from "../../../widget/buttons/poppiButton"
import PoppiCard from "../../../widget/card/poppiCard"
import PoppiIcon from "../../../widget/icons/poppiIcons"
import PoppiList from "../../../widget/list/PoppiList"
import PoppiListItem from "../../../widget/list/PoppiListItem"
import { LoaderDispatch } from "../../../widget/loaders/loaderDipatch"
import { getAllDogs } from "../dashboardStore"
import ImageLoader from "../../../widget/images/imageLoader"

export const DashboardNewDogsList = (props) => {
    const dashboardData = useSelector(state => state.dashboard)


    return <PoppiCard
        title={'New Dogs Not Approved'}
    >
        <LoaderDispatch
            dispatch={getAllDogs()}
        >
            <SortedListDogs
                dogsList={dashboardData?.dogs}
            />
        </LoaderDispatch>
    </PoppiCard>
}
const SortedListDogs = (props) => {
    const { dogsList } = props
    const navigate = useNavigate()

    var sortedDogList = [...dogsList]
    return <div
        id="scrollableDiv"
        style={{
            height: 200,
            overflow: 'auto',
            border: '1px solid rgba(140, 140, 140, 0.35)',
        }}
    ><PoppiList
            dataSource={sortedDogList.filter((dog) => (!dog.approved)).sort((a, b) => {
                if (dayjs(a.createdAt).isBefore(dayjs(b.createdAt))) {
                    return -1
                } else {
                    return 1
                }
            })}
            renderItem={dogData => {
                return <PoppiListItem
                    key={dogData.id}
                    avatar={
                        dogData.approved ?
                            <Avatar ><ImageLoader imageId={dogData.photoId} size='s' /></Avatar>
                            :
                            <Indicator label={'New'}>
                                <Avatar><ImageLoader imageId={dogData.photoId} size='s' /></Avatar>
                            </Indicator>

                        // dogData.approved ?
                        //     <Avatar>{dogData.name}</Avatar>

                        //     :
                        //     <Indicator label={'New'}>
                        //         <Avatar >{dogData.name}</Avatar>
                        //     </Indicator>
                    }
                    title={`${dogData.name} - ${dogData.breed}`}
                    description={`Registered on: ${dayjs(dogData.createdAt).format('DD/MM/YYYY HH:mm')}`}
                    extra={<PoppiButton
                        iconOnly={true}
                        icon={<PoppiIcon icon='details' />}
                        onClick={() => { navigate(`/dashboard/dogs/${dogData.id}`) }} ></PoppiButton>}
                />
            }}
        />
    </div>
}
