import { NumberInput, Stack } from "@mantine/core"
import moment from "moment"
import React, { useState } from "react"
import PoppiButton from "../../../widget/buttons/poppiButton"
import PoppiCard from "../../../widget/card/poppiCard"
import PoppiDescription from "../../../widget/description/poppiDescription"
import PoppiDialog from "../../../widget/dialog/poppiDialog"
import PoppiIcon from "../../../widget/icons/poppiIcons"
import { currency } from "../../../widget/text/formatText"

export const SummarySection = (props) => {

    const { bookingData, setBookingData, activeStep, calculateTotalPrice, setActiveStep } = props
    const [openDialogTip, setOpenDialogTip] = useState(false)

    return <PoppiCard
        title='Summary'
        subtitle={`Total price: ${currency(bookingData.total.price)} (${bookingData.total.time} min)`}
        actions={[
            <PoppiButton
            icon={<PoppiIcon icon='arrowBack' />}
            fullWidth={true}
                onClick={() => {
                    setActiveStep(activeStep - 1)
                }}
            >Back</PoppiButton>,
            <PoppiButton
            icon={<PoppiIcon icon='payment' />}
            variant='default'
                fullWidth={true}
                onClick={() => {
                    setActiveStep(activeStep + 1)
                }}
            >Payment</PoppiButton>
        ]}
    >
        <Stack>
            Review the detail fo your booking, if everything is ok proceed to payment or go back to make some changes
            <SummaryDetails {...props} />
            <PoppiButton
                icon={<PoppiIcon icon='tip' />}
                onClick={() => { setOpenDialogTip(true) }} variant='default' >Add a Tip £</PoppiButton>
            <DialogTip
                setBookingData={setBookingData}
                bookingData={bookingData}
                open={openDialogTip}
                setOpenDialogTip={setOpenDialogTip}
                calculateTotalPrice={calculateTotalPrice} />
        </Stack>
    </PoppiCard>
}

export const DialogTip = (props) => {
    const { setBookingData, bookingData, open, setOpenDialogTip, calculateTotalPrice } = props
    const [tip, setTip] = useState(bookingData.tip)
    if (!open) {
        return <></>
    }
    return <PoppiDialog
        open={props.open}
        handleClose={() => { setOpenDialogTip(false) }}
        footer={[
            <PoppiButton variant='default' onClick={() => {
                setBookingData(
                    {
                        ...bookingData,
                        tip: tip,
                        total: calculateTotalPrice({
                            ...bookingData,
                            tip: tip,
                        })
                    }
                )
                setOpenDialogTip(false)
            }}>Add Tip</PoppiButton>
        ]}
        title='Add a Tip'>
        Specify the amount of the tip and press Ok.
        <NumberInput
            prefix='£'
            decimalScale={2}
            fixedDecimalScale
            defaultValue={tip}
            onChange={
                (value) => {
                    console.log(value)
                    setTip(value)
                }}></NumberInput>
    </PoppiDialog>
}

export const SummaryDetails = (props) => {
    const { bookingData } = props

    return <>
        <PoppiDescription
            bordered={true}
            title='Dog'
            items={[
                {
                    label: 'Dog',
                    description: bookingData.dogData.name
                },
                {
                    label: 'Breed',
                    description: bookingData.dogData.breed
                },
            ]} />
        <PoppiDescription
            bordered={true}
            title='Services'
            items={bookingData.services.map((service) => {
                return {
                    label: service.label,
                    description: `${currency(service.price + (service.value === 'NailClipping' ? 0 : bookingData.dogData.extraCharge))} (${service.time} min)`
                }
            })} />
        {bookingData.spa.length > 0 &&
            <React.Fragment>
                <PoppiDescription
                    bordered={true}
                    title='Spa'
                    items={bookingData.spa.map((spa) => {
                        return {
                            label: spa.label,
                            description: `${currency(spa.price)} (${spa.time} min)`
                        }
                    })} />
            </React.Fragment>
        }
        {bookingData.extras.length > 0 &&
            <React.Fragment>
                <PoppiDescription
                    bordered={true}
                    title='Extra'
                    items={bookingData.extras.map((extra) => {
                        return {
                            label: extra.label,
                            description: `${currency(extra.price)} (${extra.time} min)`
                        }
                    })} />
            </React.Fragment>
        }
        <PoppiDescription
            bordered={true}
            title={'Appointment Date'}
            items={[
                {
                    label: 'Date',
                    description: moment(bookingData.CalendarId, 'DDMMYYYY').format('ddd DD/MM/YYYY')
                },
                {
                    label: 'Time',
                    description: bookingData.time
                },
                {
                    label: 'Tip',
                    description: currency(bookingData.tip)
                },
            ]} />
    </>
}

export default SummarySection