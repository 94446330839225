import { IconAlertTriangleOff, IconArrowNarrowLeft, IconArrowNarrowRight, IconBrandInstagram, IconCalendar, IconCircleCheck, IconCoins, IconConfetti, IconCreditCard, IconDashboard, IconDotsVertical, IconError404, IconExclamationCircle, IconExclamationMark, IconFileSad, IconFolderOpen, IconGavel, IconHelp, IconHome, IconLockCog, IconLogin, IconLogout, IconMapPin, IconMassage, IconMenu, IconMenu2, IconNeedle, IconNote, IconNotebook, IconPaw, IconPencil, IconPhone, IconPhoto, IconPlant2, IconPlus, IconReceipt, IconReportOff, IconRestore, IconScissors, IconSortAscending, IconSortAZ, IconSortZA, IconTransferVertical, IconTrash, IconUpload, IconUser, IconUsers } from '@tabler/icons-react';
import React from 'react';

export const PoppiIcon = (props) => {
    switch (props.icon) {
        case 'osteopathy':
            return <IconMassage {...props} />
        case 'sortAsc':
            return <IconSortAZ {...props} />
        case 'sortDesc':
            return <IconSortZA {...props} />
        case 'sadFace':
            return <IconFileSad {...props} />
        case 'notFound':
            return <IconError404 {...props} />
        case 'lockCog':
            return <IconLockCog {...props} />
        case 'arrowForward':
            return <IconArrowNarrowRight {...props} />
        case 'arrowBack':
            return <IconArrowNarrowLeft {...props} />
        case 'menu':
            return <IconMenu {...props} />
        case 'home':
            return <IconHome {...props} />
        case 'dashboard':
            return <IconDashboard {...props} />
        case 'pets':
            return <IconPaw {...props} />
        case 'spa':
            return <IconPlant2 {...props} />
        case 'grooming':
            return <IconScissors {...props} />
        case 'location':
            return <IconMapPin {...props} />
        case 'event':
            return <IconConfetti {...props} />
        case 'tailoring':
            return <IconNeedle {...props} />
        case 'booking':
            return <IconNotebook {...props} />
        case 'prices':
            return <IconReceipt />
        case 'profile':
            return <IconUser {...props} />
        case 'empty':
            return <IconReportOff {...props} />
        case 'priority':
            return <IconExclamationMark {...props} />
        case 'photo':
            return <IconPhoto {...props} />
        case 'reverse':
            return <IconTransferVertical {...props} />
        case 'notes':
            return <IconNote {...props} />
        case 'approved':
            return <IconCircleCheck {...props} />
        case 'notApproved':
            return <IconAlertTriangleOff {...props} />
        case 'passwordReset':
            return <IconRestore {...props} />
        case 'error':
            return <IconExclamationCircle {...props} />
        case 'details':
            return <IconFolderOpen {...props} />
        case 'edit':
            return <IconPencil {...props} />
        case 'help':
            return <IconHelp {...props} />
        case 'options':
            return <IconDotsVertical {...props} />

        case 'phone':
            return <IconPhone {...props} />

        case 'delete':
            return <IconTrash {...props} />
        case 'upload':
            return <IconUpload {...props} />

        case 'add':
            return <IconPlus {...props} />
        case 'termsAndConditions':
            return <IconGavel {...props} />
        case 'instagram':
            return <IconBrandInstagram {...props} />

        case 'login':
            return <IconLogin {...props} />
        case 'logout':
            return <IconLogout {...props} />

        case 'calendar':
            return <IconCalendar {...props} />

        case 'users':
            return <IconUsers {...props} />
        case 'payment':
            return <IconCreditCard {...props} />
        case 'tip':
            return <IconCoins {...props} />

        default:
            return '?'
    }
}

PoppiIcon.defaultProps = {
    // spin: false,
    // style: { fontSize: '1.5em' },
    // rotate: 0
}

export default PoppiIcon