import { Image, SimpleGrid } from "@mantine/core"
import React from "react"
import Event01 from '../../static/images/Event01.jpeg'
import Event02 from '../../static/images/Event02.jpeg'
import Event03 from '../../static/images/Event03.jpeg'
import Event05 from '../../static/images/Event05.jpeg'
import Event06 from '../../static/images/Event06.jpeg'
import Event07 from '../../static/images/Event07.jpeg'
import Event18 from '../../static/images/Event18.jpeg'
import PoppiButton from "../../widget/buttons/poppiButton"
import PoppiCard from "../../widget/card/poppiCard"
import PoppiPageHeader from "../../widget/card/poppiPageHeader"
import PoppiIcon from "../../widget/icons/poppiIcons"

const EventPage = (props) => {
    return <PoppiPageHeader
        title='Event'
    >

        <PoppiCard
            bottomTitle={true}
            title='Events Services'
            subtitle={
                <p>
                    We are willing to collaborate with any type of events with dogs like weedings, birthdays, local events, big events.
                    <br />Get in touch for more informations.

                </p>
            }
            actions={
                [
                    <PoppiButton
                        variant='default'
                        icon={<PoppiIcon icon='location' />}
                        fullWidth={true}
                        to='/contacts'
                    >Contacts</PoppiButton>
                ]
            }
        >
            <SimpleGrid cols={3} m='sm'>
                <Image src={Event01} radius='md' />
                <Image src={Event02} radius='md' />
                <Image src={Event03} radius='md' />
            </SimpleGrid>
            <SimpleGrid cols={4} m='sm'>
                <Image src={Event05} radius='md' />
                <Image src={Event06} radius='md' />
                <Image src={Event07} radius='md' />
                <Image src={Event18} radius='md' />

            </SimpleGrid>
        </PoppiCard>

    </PoppiPageHeader>
}

export default EventPage