import { createSlice } from '@reduxjs/toolkit'
import ApiRequest from '../../api/api'

export const profileStore = createSlice({
    name: 'profile',
    initialState: {
        dogs: [],
        prices: [],
        bookings: []
    },
    reducers: {
        setUserBookingsSuccess: (state, action) => {
            state.bookings = action.payload
        },
        setUserDogsSuccess: (state, action) => {
            state.dogs = action.payload
        },
        setUserPricesSuccess: (state, action) => {
            state.prices = action.payload
        },
    },
});


// Actions
const { setUserDogsSuccess, setUserPricesSuccess, setUserBookingsSuccess } = profileStore.actions

export const getUserPrices = () => async dispatch => new Promise((resolve, reject) => {
    ApiRequest(`/prices/get`, 'GET').then(
        (response) => {
            dispatch(setUserPricesSuccess(response));
            resolve(response)
        },
        (error) => {
            console.log(error)
            reject(error)
        }
    )
})

export const getUserBookings = (userId) => async dispatch => new Promise((resolve, reject) => {
    ApiRequest(`/profile/${userId}/bookings`, 'GET').then(
        (response) => {
            dispatch(setUserBookingsSuccess(response));
            resolve(response)
        },
        (error) => {
            console.log(error)
            reject(error)
        }
    )
})

export const getUserDogs = (userId) => async dispatch => new Promise((resolve, reject) => {
    ApiRequest(`/profile/${userId}/dogs`, 'GET').then(
        (response) => {
            dispatch(setUserDogsSuccess(response));
            resolve(response)
        },
        (error) => {
            console.log(error)
            reject(error)
        }
    )
})

export const addProfileDog = (userId, dogData) => async dispatch => new Promise((resolve, reject) => {
    ApiRequest(`/profile/${userId}/dogs/new`, 'POST', dogData).then(
        (response) => {
            dispatch(setUserDogsSuccess(response));
            resolve(response)
        },
        (error) => {
            console.log(error)
            reject(error)
        }
    )
})


export default profileStore.reducer

