import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import ApiRequest from "../../api/api"
import PoppiResult from "../empty/poppiResult"
import PoppiLoader from "./poppiLoader"

const LoaderApiRequest = (props) => {
    const [statusRequest, setStatusRequest] = useState({ status: 'loading', data: null })

    useEffect(() => {
        setStatusRequest({ status: 'loading', data: null })
        ApiRequest(props.path, props.method, props.values).then(
            (response) => {
                setStatusRequest({ status: 'done', data: response })
            },
            (error) => {
                setStatusRequest({ status: 'error', data: error })
            }
        )
    }, [props.path, props.method, props.values, props.refresh])

    switch (statusRequest.status) {
        case 'error':
            return <PoppiResult
                status='error'
                title={'An error occured in the server'} />
        case 'done':
            return props.children(statusRequest.data)
        default:
            return <PoppiLoader />

    }
}

LoaderApiRequest.defaultProps = {
    refresh: dayjs(),
    path: '/',
    method: 'GET',
    values: {},
    loadingText: 'Loading...',
    children: () => { return <React.Fragment></React.Fragment> }
}

export default LoaderApiRequest