import { Text, Title } from "@mantine/core"
import React from "react"
import PoppiPageHeader from "../../widget/card/poppiPageHeader"

const TermsAndConditionsPage = (props) => {

    return <PoppiPageHeader
        to='/'
        title='Grooming Terms And Conditions'
    >
            <Title mt='sm'>Health</Title>
            <Text>
                It is of the utmost importance that your dog is fit and healthy before we groom him/her. You must notify a groomer before we groom your dog of any existing health issues, this includes, ear/eye problems, warts, scabs or marks anywhere on the body, food allergies, shampoo allergies or any other concerns. (i.e. recent diarrhoea).
                We will carry out a general health check before and during the grooming session and will notify the owner of anything untoward that we find, it will then be the owners responsibility to seek veterinary advice. We are not medical professionals and our health checks do not replace the need to take your dog to the vets for checkups, advice and treatment.
                Your pet should be up to date with all vaccinations, recommended by your vet as an unvaccinated pet poses a risk to itself and others and we will not groom pets that haven't been vaccinated.
                Note: Puppies need to have all necessary vaccinations and we can only groom them two weeks after their final jabs.
                Grooming may expose a number of health or skin problems. We will notify you of any lumps, bumps or skin problems as soon as possible.
                We do not accept any pet under any form of sedation.
                We will not accept pregnat bitches as any level of stess mai lead to the absobtion od the puppies.
                If your dog has any medicated shampoos or any prescribed medication, please bring this along to your grooming appointment.
                Bespoke Barks will not be held responsible for any harm suffered by any pet caused by factors outside our control, including injury, illness (new illnesses and preexisting conditions) and death. Your statutory rights are not affected.
                Note: For older, vulnerable dogs, we may ask the owner to stay on the premises during the groom in case of any medical emergencies i.e. heart attacks or seizures.

            </Text>
            <Title mt='sm'>Behaviour</Title>

            <Text>
                We reserve the right to refuse to groom any misbehaved pet. Any such decision will be made at the discretion of an employee of Bespoke Barks
                If your pet behaves in a manner that makes it difficult for our groomers to perform their job i.e. your dog requires extra attention due to aggression, extreme show of anxiety or restlessness, we will do our best to groom him/her and allow extra time and occasionally have an additional groomer to help complete the groom, this will however incur an increase in the grooming fee for the extra staff and time required.
                Muzzling: We will generally avoid the use of muzzles on your dog however, for the safety of our groomers and/or to prevent your dog being able to bite may need to fit a muzzle on your dog.
                Note: You must inform us of any behavior issues, the safety of our groomers is a priority and although they are trained to deal with all kinds of pets and temperaments we do appreciate knowing if your pet has a particular body part they don't like being touched which may lead to aggressive behavior. If your dog is known to be aggressive to other dogs it is helpful to know as much information as possible so we know the best way to handle your pet.
            </Text>
            <Title mt='sm'>De-Matting</Title>
            <Text>
                Any de-matting is not included in the normal groom price. We charge an additional 35.00 £ per half an hour for de-matting. If we feel like your dog is not able to be de-matted we will suggest a short clip which is often the most pain-free and healthiest way to resolve matting. This will be with a very short blade and may expose any preexisting skin conditions or marks there may be bald patches where the matts have been so tight to the skin. The skin under the matts may split and this is only visible once shaved. In some rare cases of severe matting, hematoma may happen, if the groomer thinks there is a risk of this happening, advice will be given. All extra charges will be discussed prior to the groom, during the consultation.
                Note: Bespoke Barks will not be held responsible for the appearance, skin injuries, damage or any discomfort caused as a consequence of the dog being shaved.
            </Text>
            <Title mt='sm'>Cancellations, Lateness & Arrival</Title>
            <Text>
                We require 48 hours notice for the cancellation of an appointment. Missed appointments or appointments cancelled after the appointment time will be subjected to a cancellation fee of 100% of the groom price.
                Any uncollected pets will be charged a sitting fee of 12.00 £ per hour left uncollected. If this exceeds 3 hours an automatic full day charge of 60.00 £ will be applied.
                Please arrive 10 mins before your grooming appointment time, if you are running late please call the salon to notify us, lateness will result in a reduction of the allocated time for your dog's groom which means the groomers may not have the time to give the dog the full treatment that it was booked in for.
            </Text>
            <Title mt='sm'>Payment</Title>
            <Text>
                Any estimates or quotes given at the time of booking may change. There may be a higher charge due to coat condition, different breed then described or abnormal differences for breed (for example, a Yorkshire Terrier double the size of an average Yorkshire Terrier). Extra payment must be made in full on collection of your pet.

            </Text>
            <Title mt='sm'>Preparation</Title>
            <Text>
                Take your pet for a long walk before entering Bespoke barks to give a chance to your dog to relax during the grooming session and avoid accidents in the Salon. Avoid food 2 hours prior to your grooming appointment.
                {/* We ask that before your visit to Bespoke Barks grooming SPA that your pet has been to the toilet. We wouldn't recommend any feeding at least 2 hours prior to your grooming appointment. */}

            </Text>
            <Title mt='sm'>Collection</Title>
            <Text>
                The groomer will give you an indication of when your dog will be ready for collection and we would appreciate the owners being on time to collect (please refer to Cancellations, lateness & arrival) If a family member or any other person will be collecting your pet, we must be informed. For security reasons if we haven't been informed another person other than you are collecting your pet, then your pet will not be released until we receive confirmation by you.

            </Text>
            <Title mt='sm'>Photography</Title>
            <Text>
                For purposes of marketing and promoting, Bespoke Barks will take photos and/or videos of dogs being groomed to demonstrate services and quality of work carried out. These photographs may be used on the website and social media sites. You agree to this unless your inform as otherwise.
                Please make sure we have all up to date contact details and mobile numbers in case of an emergency.
                <strong>The customer is not allowed to take photos or videos on the salon premises.</strong>
            </Text>


    </PoppiPageHeader >
}

export default TermsAndConditionsPage