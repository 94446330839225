import { AutoComplete, Avatar, List } from "antd"
import React, { useState } from "react"
import { useSelector } from 'react-redux'
import { useNavigate } from "react-router"
import PoppiButton from '../../../widget/buttons/poppiButton'
import PoppiCard from '../../../widget/card/poppiCard'
import PoppiEmpty from '../../../widget/empty/poppiEmpty'
import PoppiIcon from '../../../widget/icons/poppiIcons'
import { LoaderDispatch } from "../../../widget/loaders/loaderDipatch"
import { getAllUsers } from "../dashboardStore"
import PoppiList from "../../../widget/list/PoppiList"
import { CloseButton, Input, Stack } from "@mantine/core"
import PoppiListItem from "../../../widget/list/PoppiListItem"
export const DashboardUsers = (props) => {
    const dashboardData = useSelector(state => state.dashboard)

    return <LoaderDispatch
        dispatch={getAllUsers()}
    >

        <ListUsers
            listUsers={dashboardData.users}
        />
    </LoaderDispatch>
}

const ListUsers = (props) => {
    const navigate = useNavigate()
    var listUsers = [...props.listUsers].sort((a, b) => {
        if (a.surname.toUpperCase() > b.surname.toUpperCase()) {
            return 1
        } else {
            return -1
        }
    })
    const [userFilter, setUserFilter] = useState('')
    return <PoppiList
        header={<Input
            placeholder="Search..."
            style={{ width: '100%' }}
            value={userFilter}
            onChange={(event => {
                setUserFilter(event.currentTarget.value)
            })}
            rightSection={
                <CloseButton
                    aria-label="Clear input"
                    onClick={() => setUserFilter('')}
                />}
        />}
        dataSource={listUsers.filter((item) => {
            if (userFilter) {
                return (item.name.toUpperCase().includes(userFilter.toUpperCase()) || item.surname.toUpperCase().includes(userFilter.toUpperCase()) || item?.email?.toUpperCase().includes(userFilter.toUpperCase()))
            }
            return true
        }).sort((a, b) => {
            if (a.surname.toUpperCase() > b.surname.toUpperCase()) {
                return 1
            } else {
                return -1
            }
        })}
        renderItem={user => {
            return <PoppiListItem
                title={`${user.surname} ${user.name} `}
                description={<Stack gap={0}>
                    <span>{user.phone} {user.email}</span>
                    <span>Number of dogs: {user.Dogs.length}</span>
                </Stack>}
                extra={[
                    <PoppiButton
                        iconOnly={true}
                        icon={<PoppiIcon icon='details' />}
                        onClick={() => { navigate(`/dashboard/users/${user.id}`) }} >
                    </PoppiButton>]}

            />

        }}
    />
}


export default DashboardUsers 