import { Result } from "antd";
import React from "react";
import PoppiButton from "../buttons/poppiButton";
import PoppiIcon from "../icons/poppiIcons";

export const PoppiResult = (props) => {
    return <Result
        title={props.title}
        icon={props.icon}
        subTitle={props.subtitle}
        status={props.status}
        extra={props.extra}
    >
        {props.children}
    </Result>
}

PoppiResult.defaultProps = {
    title: 'Warning',
    subtitle: null,
    icon: null,
    status: 'warning',
    extra: [<PoppiButton to={'/'} icon={<PoppiIcon icon='home' />}>Homepage</PoppiButton>]
}

export default PoppiResult