import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import PoppiResult from "../empty/poppiResult"
import PoppiLoader from "./poppiLoader"
export const LoaderDispatch = (props) => {
    const [status, setStatus] = useState('loading')
    const dispatch = useDispatch()
    useEffect(() => {
        setStatus('loading')
        dispatch(props.dispatch).then(
            (response) => {
                setStatus('done')
            },
            (error) => {
                setStatus('error')
                console.log(error)
            }
        )
        // eslint-disable-next-line
    }, [props.refresh])

    switch (status) {
        case 'error':
            return <PoppiResult
                status='error'
                title={'An error occured in the server'} />
        case 'done':
            return props.children
        default:
            return <PoppiLoader />
    }
}

LoaderDispatch.defaultProps = {
    dispatch: () => { },
    refresh: null,
    loadingText: 'Loading...'
}

export default LoaderDispatch