import { sendPasswordResetEmail } from '@firebase/auth'
import React, { useState } from 'react'
import { BASE_URL } from '../config'
import PoppiButton from '../widget/buttons/poppiButton'
import PoppiPageHeader from '../widget/card/poppiPageHeader'
import poppiNotification from '../widget/dialog/poppiNotification'
import PoppiResult from '../widget/empty/poppiResult'
import PoppiFormField from '../widget/form/poppiFormField'
import PoppiIcon from '../widget/icons/poppiIcons'
import { auth } from './auth'
import { useForm, yupResolver } from '@mantine/form'
import * as yup from 'yup'
import { Container, Divider, Paper, Space, Text, Title } from '@mantine/core'
import PoppiMatineFormField from '../widget/form/poppiMantineFormField'

const ResetPasswordPage = (props) => {
    const [successEmail, setSuccessEmail] = useState(null)

    return <PoppiPageHeader
        title={'Retrieve your password'}>
        {successEmail ?
            <PasswordResetSuccess email={successEmail} />
            :
            <FormResetPassword setSuccessEmail={setSuccessEmail} />
        }
    </PoppiPageHeader>
}

const FormResetPassword = (props) => {
    const [isProcessing, setIsProcessing] = useState(false)

    const sendPasswordReset = data => {
        setIsProcessing(true)
        var actionCodeSettings = {
            url: BASE_URL,
        }
        sendPasswordResetEmail(auth,
            data.email,
            actionCodeSettings).then(function () {
                // Email Password Reset sent.
                setIsProcessing(false)
                // Go to confirmation page
                props.setSuccessEmail(data.email)
            }).catch(function (error) {
                console.log(error)
                // An error happened.
                poppiNotification(
                    {
                        message: 'Email not found',
                        description: 'The email is not registered',
                    }
                )
                setIsProcessing(false)
            });
    };
    const schema = yup.object().shape({
        email: yup
            .string()
            .required('Invalid email')
            .email('Invalid email'),
    });

    const formMantine = useForm({
        initialValues: { email: '', password: '' },
        validate: yupResolver(schema)
    })
    return <Container size={420}>
        <Title ta='center'>Password Reset</Title>
        <Text c='dimmed' ta={'center'}>Insert the email that you use in the registration.
            You will receive and email with a link to reset your password.
        </Text>
        <Paper withBorder shadow="md" p={30} mt={30} radius="md">
            <form
                onSubmit={formMantine.onSubmit(sendPasswordReset)}>
                <PoppiMatineFormField
                    label={'Email'}
                    placeholder={'Email'}
                    name={'email'}
                    form={formMantine}
                />
                <Space h='xs' />
                <PoppiButton
                    fullWidth
                    loading={isProcessing}
                    icon={<PoppiIcon icon='passwordReset' />}
                    type="submit"
                >
                    Reset Password
                </PoppiButton>
            </form>
        </Paper>

    </Container>
    // return <center>
    //     <Space direction='vertical'>
    //         <h4>Insert the email that you use in the registration.
    //             <br /> You will receive and email with a link to reset your password.</h4>
    //         <Form
    //             form={form}
    //             layout='vertical'

    //             onFinish={sendPasswordReset} >


    //             < PoppiFormField
    //                 name='email'
    //                 label='Email'
    //                 rules={
    //                     [
    //                         {
    //                             required: true,
    //                             message: 'Required Field'
    //                         },
    //                     ]}
    //             />

    //             <Form.Item>
    //                 <PoppiButton
    //                     loading={isProcessing}
    //                     icon={<PoppiIcon icon='passwordReset' />}
    //                     htmlType="submit"
    //                     variant='default'
    //                 >
    //                     Reset Password
    //                 </PoppiButton>
    //             </Form.Item>
    //         </Form>
    //     </Space>

    // </center>
}

FormResetPassword.defaultProps = {
    isSubmitting: false
}

const PasswordResetSuccess = (props) => {
    return <PoppiResult
        status='success'
        title="Email sent"
        subtitle={<p>An email to reset the password has been sent to {props.email}<br />If you didn't receive it, check your spam</p>}
        extra={[
            <PoppiButton
                icon={<PoppiIcon icon='home' />}
                to={'/'}
            >
                Homepage

            </PoppiButton>
        ]} />
}
export default ResetPasswordPage
