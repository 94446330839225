import { Box, Divider, Flex, Group, Input, Menu, Paper, Stack, UnstyledButton } from "@mantine/core";
import { randomId, useDebouncedState, useDisclosure, useId } from "@mantine/hooks";
import React, { useState } from "react";
import PoppiActionButton from "../buttons/PoppiActionButton";
import PoppiCard from "../card/poppiCard";
import PoppiEmpty from "../empty/poppiEmpty";
import PoppiIcon from "../icons/poppiIcons";
import dayjs from "dayjs";

// interface PoppiListProps extends CardProps {
//     header?: any,
//     title?: any,
//     filterBy?: string | Array<string>,
//     selectBarOptions?: Array<string> | undefined,
//     selectBy?: string,
//     dataSource: any[],
//     renderItem: (variables: any, index?: number) => any,
//     renderExtraItem?: (variables: any) => any,
//     onClickItem?: (variables: any) => any,
//     withBorder?: boolean,
//     extra?: any,
//     subtitle?: any,
//     divider?: boolean,
//     spacing?: string
//     maxHeight?: any
// }

const defaultProps = {
    header: null,
    title: '',
    dataSource: [],
    renderItem: () => { },
    renderExtraItem: () => { },
    withBorder: false,
    extra: null,
    divider: true,
    spacing: 'sm',
    maxHeight: null
}

export const PoppiList = (propsArg) => {
    const props = { ...defaultProps, ...propsArg }
    const [filter, setFilter] = useDebouncedState(undefined, 100)
    const [sortAsc, sortAscHandler] = useDisclosure(true)
    const [filterSelect, setFilterSelect] = useState([])

    return <PoppiCard
        withBorder={props.withBorder}
        extra={props.extra}
        title={props.title}
        subtitle={props.subtitle}
        header={props.filterBy
            ?
            <>
                <Flex align='center' w={'100%'}>
                    <Input
                        w={'100%'}
                        placeholder="Search..."
                        defaultValue={filter}
                        onChange={(event => {
                            setFilter(event.currentTarget.value)
                        })
                        }
                        leftSectionPointerEvents="all"
                        leftSection={
                            props.filterBy &&
                            < PoppiActionButton variant="transparent" icon={sortAsc ? <PoppiIcon icon='sortAsc' /> : <PoppiIcon icon='sortDesc' />} onClick={
                                () => { sortAscHandler.toggle() }
                            } />
                        }
                    />
                    {props.selectBarOptions &&
                        <Menu>
                            <Menu.Target>
                                <PoppiActionButton
                                    variant='transparent'
                                    icon={<PoppiIcon icon={filterSelect?.length > 0 ? 'filterOn' : 'filterOff'} />} ml='sm' />
                            </Menu.Target>

                            <Menu.Dropdown mah={'50vh'} style={{ overflowY: 'auto' }}>
                                <Menu.Label>Filter By</Menu.Label>
                                {props.selectBarOptions?.map((option) => {
                                    return <Menu.Item
                                        key={randomId()}
                                        rightSection={filterSelect.includes(option) && <PoppiIcon icon='check' />}
                                        onClick={() => {
                                            if (filterSelect.includes(option)) {
                                                setFilterSelect([...filterSelect].filter((item) => (item !== option)))
                                            } else {
                                                setFilterSelect([...filterSelect, option])
                                            }
                                        }}>{option}</Menu.Item>
                                })}
                            </Menu.Dropdown>
                        </Menu>

                    }
                </Flex>
                {props.header}
            </>
            :
            props.header
        }
    >
        <Paper mah={props.maxHeight} style={{ overflowY: 'auto' }} w='100%'>
            {
                (!props.dataSource || props.dataSource?.length === 0) ?
                    <PoppiEmpty />
                    : <Stack gap={0} ml={5} mr={5}>
                        {[...props.dataSource]?.filter((item) => {
                            if (filter && props.filterBy) {
                                if (typeof props.filterBy === 'string') {
                                    return item[props.filterBy]?.toUpperCase().includes(filter?.toUpperCase())
                                } else {
                                    var found = false
                                    props.filterBy.forEach((filterItem) => {
                                        if (item[filterItem]?.toUpperCase().includes(filter?.toUpperCase())) {
                                            found = true
                                        }
                                    })
                                    return found
                                }

                            }
                            return true
                        })?.filter((item) => {
                            if (props.selectBy && filterSelect.length > 0) {
                                if (Array.isArray(item[props.selectBy])) {
                                    if (item[props.selectBy]?.some((v) => filterSelect?.includes(v))) {
                                        return true
                                    } else {
                                        return false
                                    }
                                } else {
                                    if (filterSelect.includes(item[props.selectBy])) {
                                        return true
                                    } else {
                                        return false
                                    }
                                }

                            }
                            return true
                        })?.sort((a, b) => {
                            if (props.filterBy) {
                                if (typeof props.filterBy === 'string') {
                                    if (a[props.filterBy]?.toUpperCase() > b[props.filterBy]?.toUpperCase()) {
                                        return 1 * (sortAsc ? 1 : -1)
                                    } else {
                                        return -1 * (sortAsc ? 1 : -1)
                                    }
                                } else {
                                    // Filter Array
                                    if (a[props.filterBy[0]]?.toUpperCase() > b[props.filterBy[0]]?.toUpperCase()) {
                                        return 1 * (sortAsc ? 1 : -1)
                                    } else {
                                        return -1 * (sortAsc ? 1 : -1)
                                    }
                                }

                            }
                            return -1

                        })?.map((item, index) => {
                            return <Box key={`element${index}`}>
                                {index === 0 && (props.divider ? <Divider mt={props.spacing} mb={props.spacing} /> : <Space h={props.spacing} />)}
                                {props.onClickItem ?
                                    props.renderExtraItem ?
                                        <Group justify='flex-start'>
                                            <UnstyledButton
                                                style={{
                                                    flexGrow: 1
                                                }}
                                                onClick={() => {
                                                    props.onClickItem && props.onClickItem(item)
                                                }}
                                            >
                                                {props.renderItem(item, index)}
                                            </UnstyledButton>

                                            {props.renderExtraItem(item)}
                                        </Group>
                                        :
                                        <UnstyledButton
                                            style={{
                                                flexGrow: 1
                                            }}
                                            onClick={() => {
                                                props.onClickItem && props.onClickItem(item)
                                            }}
                                        >
                                            {props.renderItem(item, index)}
                                        </UnstyledButton>
                                    :
                                    <Group justify='space-between'>
                                        {props.renderItem(item, index)}
                                        {props.renderExtraItem && props.renderExtraItem(item)}
                                    </Group>



                                }
                                {props.divider ? <Divider mt={props.spacing} mb={props.spacing} /> : <Space h={props.spacing} />}
                            </Box>
                        })}
                    </Stack >
            }
        </Paper>
    </PoppiCard >

    return <PoppiCard
        extra={props.extra}
        title={props.title}
        subtitle={props.subtitle}
        header={props.header}>
        {(!props.dataSource || props.dataSource?.length === 0) ?
            <PoppiEmpty />
            : <Stack gap={0} ml={5} mr={5} >
                {[...props.dataSource]?.map((item, index) => {
                    return <Box key={`${props.id}_${index}`}>
                        {index === 0 && <Divider m='xs' />}
                        {props.renderItem(item)}
                        <Divider m='xs' />
                    </Box>
                })}
            </Stack >
        }
    </PoppiCard>
}

PoppiList.defaultProps = {
    header: null,
    title: '',
    dataSource: [],
    renderItem: () => { },
    bordered: true,
    extra: null,
    id: randomId()
}

export default PoppiList