import { ActionIcon, Menu } from '@mantine/core'
import React from 'react'
import PoppiConfirm from '../dialog/poppiConfirm'
import PoppiIcon from '../icons/poppiIcons'
import { v4 as uuidv4 } from 'uuid'

const PoppiOptionsMenu = (props) => {
    var itemsMenu = []
    if (props.onEdit) {
        itemsMenu.push(
            {
                key: 'edit',
                label: 'Edit',
                icon: <PoppiIcon icon='edit' />,
                onClick: props.onEdit
            })
    }


    itemsMenu = [...itemsMenu, ...props.items]

    if (props.onDelete) {
        itemsMenu.push(
            {
                key: 'delete',
                label: 'Delete',
                icon: <PoppiIcon icon='delete' />,
                onClick: () => {
                    PoppiConfirm(
                        {
                            icon: <PoppiIcon icon='delete' />,
                            title: 'Delete element',
                            content: <p>
                                Are you sure to delete this element?
                                <br /> All the data related will be lost.
                            </p>,
                            onConfirm: props.onDelete,
                            okType: 'danger'
                        }
                    )
                }
            })

    }

    return <>
        <Menu shadow="md" width={200}>
            <Menu.Target>
                <ActionIcon variant="white" >{props.icon}</ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
                {itemsMenu.map((item) => {
                    return <Menu.Item
                        key={uuidv4()}
                        onClick={item.onClick}
                        leftSection={item.icon}>
                        {item.label}</Menu.Item>
                    return {
                        key: item.key,
                        text: <>{item.icon} {item.label}</>,
                        icon: item.icon,
                        onClick: item.onClick
                    }
                })}
            </Menu.Dropdown>
        </Menu>
    </>
}


PoppiOptionsMenu.defaultProps = {
    items: [],
    icon: <PoppiIcon icon='options' />,
    buttonShape: 'round',
    buttonType: 'text',
    buttonText: null,
    onDelete: null,
    onEdit: null,
}

export default PoppiOptionsMenu
