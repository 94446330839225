import React from "react";
import { BASE_URL } from "./config";
import BespokeBarksLogo from './static/images/bespokeBarksPng.png';
import PoppiResult from "./widget/empty/poppiResult";
import { Button, Center, Image, Loader, Stack, Text, Title } from "@mantine/core";
import PoppiIcon from "./widget/icons/poppiIcons";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <ErrorApp />
        }

        return this.props.children;
    }
}


const ErrorApp = (props) => {

    return <React.Fragment>
        <PoppiResult
            image={<PoppiIcon icon='sadFace' />}
            title="500"
            subtitle="Sorry, something went wrong."
            extra={<Button href={BASE_URL}>Homepage</Button>}
        />
    </React.Fragment>
}

export const LoaderApp = (props) => {
    return <React.Fragment>
        <Center h={'100vh'}>
            <Stack align="center">
                <Image src={BespokeBarksLogo} />
                <Title>Bespoke Barks</Title>
                <Loader />
                <Text>Loading...</Text>
            </Stack>
        </Center>
    </React.Fragment>
}


export default ErrorBoundary