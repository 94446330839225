
import { Form, List } from "antd"
import React, { useState } from "react"
import { useDispatch } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import PoppiButton from "../../../widget/buttons/poppiButton"
import PoppiDialog from "../../../widget/dialog/poppiDialog"
import poppiNotification from "../../../widget/dialog/poppiNotification"
import PoppiFormField from "../../../widget/form/poppiFormField"
import { setPrice } from "../dashboardStore"

export const PriceFormDialog = (props) => {
    const { priceData } = props
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()


    function handlePrice(data) {
        setIsLoading(true)
        dispatch(setPrice(data)).then(
            (response) => {
                setIsLoading(false)
                props.handleClose()
            },
            (error) => {
                setIsLoading(false)
                poppiNotification(
                    {
                        message: 'An error occured',
                        description: 'There was a problem in the server',
                        type: 'error'
                    }
                )
            }
        )
    }

    var initialValues = {
        id: priceData?.id ? priceData.id : uuidv4(),
        breed: priceData?.breed ? priceData.breed : null,
        services: priceData?.services ? priceData.services : [
            {
                enabled: false,
                value: 'WashAndBrush',
                label: 'Wash And Brush',
                price: 50.00,
                time: 120,
            },
            {
                enabled: false,
                value: 'FullGroom',
                label: 'Full Groom',
                price: 60.00,
                time: 120,
            },

            {
                enabled: false,
                value: 'DeShedding',
                label: 'De-Shedding',
                price: 50.00,
                time: 180,
            },
            {
                enabled: false,
                value: 'HandStripping',
                label: 'Hand Stripping',
                price: 50.00,
                time: 180,


            },
            {
                enabled: true,
                value: 'NailClipping',
                label: 'Nail Clipping',
                price: 15.00,
                time: 15,


            },
            {
                enabled: false,
                value: 'Pawdicure',
                label: 'Pawdicure',
                price: 45.00,
                time: 60
            }
        ],
        spa: priceData?.spa ? priceData.spa : [
            {
                enabled: true,
                value: 'SensitiveSPA',
                label: 'Sensitive SPA',
                price: 50.00,
                time: 60,
            },
            {
                enabled: true,
                value: 'Detox',
                label: 'Detox',
                price: 50.00,
                time: 60,
            },
            {
                enabled: true,
                value: 'UltrasoundTeeth',
                label: 'Ultrasound Teeth Cleaning',
                price: 20.00,
                time: 30,
            }
        ]

    }
    const [form] = Form.useForm()

    const servicesValues = Form.useWatch('services', form)
    const spaValues = Form.useWatch('spa', form)

    return <React.Fragment>

        <PoppiDialog
            maxWidth={false}
            fullScreen={true}
            title={props.priceData ? 'Edit Price' : 'New Price'}
            open={props.open}
            handleClose={() => { props.handleClose() }}
            footer={
                [<PoppiButton
                    loading={isLoading}
                    variant='default'
                    onClick={() => { form.submit() }}>
                    Save</PoppiButton>
                ]}
        >

            <Form
                onFinish={handlePrice}
                layout='vertical'
                form={form}
                initialValues={initialValues}
            >
                <Form.Item name='id' noStyle />
                <PoppiFormField
                    name='breed'
                    label='Breed'
                />

                <h3>Services</h3>

                <Form.List
                    name="services"
                >

                    {(fieldsServices) => (
                        <List itemLayout='horizontal'>
                            {fieldsServices.map((fieldService, indexService) => {
                                return <List.Item>
                                    <List.Item.Meta
                                        title={
                                            <PoppiFormField
                                                type='switch'
                                                label={initialValues.services[indexService].label}
                                                name={[indexService, 'enabled']}
                                                style={{ margin: '0px' }}
                                            />

                                        }
                                        description={
                                            <React.Fragment>

                                                {
                                                    servicesValues && servicesValues[indexService].enabled &&
                                                    <React.Fragment>
                                                        <PoppiFormField
                                                            type='number'
                                                            label={'Price'}
                                                            name={[indexService, 'price']}
                                                            prepend='£'
                                                            style={{ display: 'inline-block', width: 'calc(50%)' }}
                                                            size='small'
                                                        />
                                                        <PoppiFormField
                                                            type='number'
                                                            label={'Time'}
                                                            name={[indexService, 'time']}
                                                            prepend='min'
                                                            style={{ display: 'inline-block', width: 'calc(50%)' }}
                                                            size='small'
                                                        />
                                                    </React.Fragment>
                                                }
                                            </React.Fragment>
                                        }
                                    ></List.Item.Meta>
                                </List.Item>
                            }
                            )
                            }
                        </List>

                    )}
                </Form.List>

                <h3>Spa</h3>
                <Form.List
                    name="spa"
                >
                    {(fieldsSpa) => (
                        <List itemLayout='horizontal'>

                            {fieldsSpa.map((fieldSpa, indexSpa) => {
                                return <List.Item>
                                    <List.Item.Meta
                                        title={<PoppiFormField
                                            type='switch'
                                            label={initialValues.spa[indexSpa].label}
                                            name={[indexSpa, 'enabled']}
                                            style={{ margin: '0px' }}
                                        />}
                                        description={spaValues && spaValues[indexSpa].enabled &&
                                            <React.Fragment>
                                                <Form.Item>
                                                    <PoppiFormField
                                                        type='number'
                                                        label={'Price'}
                                                        name={[indexSpa, 'price']}
                                                        prepend='£'
                                                        style={{ display: 'inline-block', width: 'calc(50%)' }}
                                                        size='small'
                                                    />
                                                    <PoppiFormField
                                                        type='number'
                                                        label={'Time'}
                                                        name={[indexSpa, 'time']}
                                                        prepend='min'
                                                        size='small'
                                                        style={{ display: 'inline-block', width: 'calc(50%)' }}
                                                    />
                                                </Form.Item>

                                            </React.Fragment>}
                                    />

                                </List.Item>
                            }
                            )
                            }
                        </List>
                    )}
                </Form.List>

            </Form>

        </PoppiDialog >
    </React.Fragment >
}

export default PriceFormDialog