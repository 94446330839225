export const TRACKING_ID = 'G-26HH2597SS'

// ##########################
// Development Configuration
// ##########################

// export const ENVIRONMENT = 'development'
// export const BASE_URL = 'http://localhost:3000'
// export const BASE_URL_API = 'http://localhost:8080'
// export const SQUARE_ACCESS_TOKEN = 'EAAAEA6bjWKVb7jWMslKshwfqozH3Y6o1du8vUkKi-TU0yIAzyqbP7udlOLSEHgr'
// export const SQUARE_APPLICATION_ID = 'sandbox-sq0idb-xEkYz2vqEi7Z8j2CvBFF7A'
// export const SQUARE_LOCATION_ID = 'L3321EQKN72F0'

// ##########################
// Production Configuration
// ##########################

export const ENVIRONMENT = 'production'
export const BASE_URL = 'https://bespokebarks.co.uk'
export const BASE_URL_API = 'https://api.bespokebarks.co.uk'
export const SQUARE_ACCESS_TOKEN = 'EAAAETsUsUmIEb_JRbsARYA42TRDRu2KiUUn4siqSbknxCBxUQZ54oGB6z58T7UE'
export const SQUARE_APPLICATION_ID = 'sq0idp-eh_v9WHimLilSfssmTBfSA'
export const SQUARE_LOCATION_ID = 'LSZ2XMYPZKATC'

// ######################

// Firebase Config
export const firebaseConfig = {
    apiKey: "AIzaSyCwBK75d7d5HJ8bOMgdrFA_lCylSmE7o-M",
    authDomain: "bespokebarks.firebaseapp.com",
    projectId: "bespokebarks",
    storageBucket: "bespokebarks.appspot.com",
    messagingSenderId: "936171457353",
    appId: "1:936171457353:web:c557d93406fa38eeff6898",
    measurementId: "G-26HH2597SS"
};

// Name item Local Storage for token
export const TOKEN_LOCAL_STORAGE = 'tokenBespokeBarks'

