import { Space, Text, Title } from "@mantine/core"
import { Form } from 'antd'
import { useState } from "react"
import { useSelector } from "react-redux"
import ApiRequest from "../api/api"
import { refreshAuthData } from "../auth/auth"
import PoppiButton from "../widget/buttons/poppiButton"
import poppiNotification from "../widget/dialog/poppiNotification"
import PoppiFormField from "../widget/form/poppiFormField"

export const UpdateData = (props) => {
    const authData = useSelector(state => state.auth)
    const [isProcessing, setIsProcessing] = useState(false)
    const [form] = Form.useForm()
    if (!(authData.authenticated && authData.verified === false)) {
        return <></>
    }
    return <>
        <Title>Update you profile info.</Title>
        Due to an outage on the company that host our server there are some info that needs to be inserted again on you profile in order to make a booking.
        We are sorry for the inconvenince and we change the host in order to not occur in the same issue again.

        <h4>Fill the form below with you updated info and then go to your profile and register again your dog in order to make a booking.</h4>
        <Form form={form}
            initialValues={authData}
            layout='vertical'

            onFinish={async (updatedDataForm) => {
                setIsProcessing(true)
                const updateDataProfile = {
                    name: authData.name,
                    surname: authData.surname,
                    email: authData.email,
                    phone: updatedDataForm.phone,
                    address: updatedDataForm.address,
                    verified: true
                }
                ApiRequest(`/auth/user/${authData.id}/update`, 'POST', updateDataProfile).then(
                    async (response) => {
                        await refreshAuthData()
                        location.reload()
                        setIsProcessing(false)
                    },
                    (error) => {
                        console.log(error)
                        poppiNotification(
                            {
                                message: 'Something Went Wrong',
                                description: 'Try again or contact support',
                            }
                        )
                        setIsProcessing(false)
                    }
                )
            }} >
            <Title size='1.5em'>Your Info</Title>

            <Space h='md' />
            <Text fw='bold'>Email</Text>
            <Text>{authData.email}</Text>

            <Space h='md' />
            <Text fw='bold'>Name</Text>
            <Text>{authData.name}</Text>

            <Space h='md' />
            <Text fw='bold'>Surname</Text>
            <Text>{authData.surname}</Text>

            <Space h='md' />

            <PoppiFormField
                name='phone'
                label='Phone Number'
                rules={
                    [
                        {
                            required: true,
                            message: 'Required Field'
                        },
                    ]}
            />

            <h3>Address</h3>

            <PoppiFormField
                name={['address', 'line1']}
                label='Line 1'
                rules={
                    [
                        {
                            required: true,
                            message: 'Required Field'
                        },
                    ]}
            />

            <PoppiFormField
                name={['address', 'line2']}
                label='Line 2'
            />

            <PoppiFormField
                name={['address', 'city']}
                label='Town or City'
                rules={
                    [
                        {
                            required: true,
                            message: 'Required Field'
                        },
                    ]}
            />

            <PoppiFormField
                name={['address', 'country']}
                label='Country'
                type='country'
                rules={
                    [
                        {
                            required: true,
                            message: 'Required Field'
                        },
                    ]}
            />
            <PoppiFormField
                name={['address', 'postcode']}
                label='Postcode'
                rules={
                    [
                        {
                            required: true,
                            message: 'Required Field'
                        },
                    ]}
            />

            <PoppiFormField
                type='checkbox'
                name={'consent'}
                label='I accept the privacy policy'
                rules={[
                    {
                        required: true,
                        message: 'Should accept the privacy policy'
                    }

                ]}
            />
            <Form.Item>
                <PoppiButton
                    loading={isProcessing}
                    type='submit'
                >
                    Update Account
                </PoppiButton>
            </Form.Item>
        </Form>
    </>


}