import { Group, Stack, Text } from "@mantine/core";
import React from "react";

const defaultProps = {
    avatar: null
}

export const PoppiListItem = (propsArg) => {
    const props = { ...defaultProps, ...propsArg }
    return <Stack w='100%'>
        <Group justify='space-between' bg={props.background} style={{ flexGrow: 1 }}>
            <Group justify='flex-start'>
                {props.avatar && props.avatar}
                <Stack gap='0'>
                    <Text>{props.title}</Text>
                    {props.description && <Text c='dimmed' >{props.description}</Text>}
                </Stack>
            </Group>
            {props.extra && props.extra}
        </Group>
        {props.children}
    </Stack>

    return <>
        <Group justify="space-between">
            <Group>
                {props.avatar &&
                    props.avatar}
                <div>
                    <Text fw={500}  >
                        {props.title}
                    </Text>
                    <Text c="dimmed" >
                        {props.description}
                    </Text>
                </div>
            </Group>
            {props.extra}
        </Group >
        {props.children}
    </>
}

PoppiListItem.defaultProps = {
    title: '',
    description: null,
    avatar: null,
    extra: null
}

export default PoppiListItem