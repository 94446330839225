import { Center, Divider, Stack, Stepper, Text } from '@mantine/core'
import { Steps } from 'antd'
import React from 'react'
const { Step } = Steps
const PoppiSteps = (props) => {

    return <React.Fragment>
        <Stack align='center'>
        <Text fw={500}>{props.steps[props.activeStep].title}</Text> 
            <Stepper wrap={true}
                size="xs"
                active={props.activeStep}
                orientation={props.direction}>

                {props.steps.map((step, index) => {
                    return <Stepper.Step
                        icon={index + 1}
                        key={step.title}
                    >
                    </Stepper.Step>
                })
                }
            </Stepper>
        </Stack>
        <Divider my='xs' />

        {/* <Steps
            size={props.size}
            direction={props.direction}
            current={props.activeStep} >
            {props.steps.map((step, index) => {
                return <Step
                    key={step.title}
                    title={step.title}
                >
                </Step>
            })
            }
        </Steps>
        <br /> */}
        {props.steps[props.activeStep].content}
    </React.Fragment>


}

PoppiSteps.defaultProps = {
    steps: [],
    activeStep: 1,
    size: 'small',
    direction: 'horizontal'
}

export default PoppiSteps