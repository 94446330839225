// import firebase from 'firebase/app';
import { deleteUser, getAuth, GoogleAuthProvider, onAuthStateChanged, signOut } from '@firebase/auth';
import { initializeApp } from 'firebase/app';
import { ApiRequest } from '../api/api';
import store from '../appStore';
import { firebaseConfig } from '../config';
import { login, logout } from './authStore';


const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp)

onAuthStateChanged(auth, function (user) {
    if (user) {
        user.getIdToken(true)
            .then((token) => {
                // Dispatch save token
                store.dispatch(login(token))
            })
            .catch(err => console.error(err));
    }
});

export async function refreshAuthData() {
    if (auth.currentUser) {
        await auth.currentUser.getIdToken(true)
            .then((token) => {
                // Dispatch save token
                store.dispatch(login(token))
            })
            .catch(err => console.error(err));
    }
    return true
}

export const createUser = async (accountData) => {
    const updateUserInfo = await ApiRequest(`/auth/user/create`, 'POST', accountData).then(
        (user) => {
            return { status: 'ok', data: user }
        },
        (error) => {
            return { status: 'error', data: error }

        }
    )

    return updateUserInfo
}

// export const updateUser = async (accountData) => {
//     // Update User Info
//     const updateUserInfo = await ApiRequest(`/auth/user/${accountData.accountId}/updateUser`, 'PATCH', accountData).then(
//         (response) => {
//             return true
//         },
//         (error) => {
//             return false
//         }
//     )
//     return updateUserInfo
// }

// export const updateUserCustomClaims = async (accountData) => {
//     // Change the custom Claims
//     const updateCustomClaimsResponse = await ApiRequest(`/auth/user/${accountData.accountId}/updateCustomClaims`, 'PATCH', accountData).then(
//         (response) => {
//             return true
//         },
//         (error) => {
//             return false
//         }
//     )
//     return updateCustomClaimsResponse
// }

export const signOutUser = async () => {
    store.dispatch(logout())
    await signOut(auth)
}

export const removeUser = async (user) => {
    const response = await deleteUser(user).then(
        () => {
            return true
        }
    ).catch(() => {
        return false
    })
    return response
}

export const provider = new GoogleAuthProvider();

