import { Loader, LoadingOverlay, Stack, Text } from '@mantine/core';
import { Form } from 'antd';
import { countries } from 'countries-list';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ApiRequest from '../../api/api';
import { SQUARE_APPLICATION_ID, SQUARE_LOCATION_ID } from '../../config';
import PoppiButton from '../buttons/poppiButton';
import poppiNotification from '../dialog/poppiNotification';
import PoppiFormField from '../form/poppiFormField';
import PoppiIcon from '../icons/poppiIcons';
import PoppiLoader from '../loaders/poppiLoader';
import { currency } from '../text/formatText';

export const PoppiPaymentForm = (props) => {
    const { bookingData } = props
    const [card, setCard] = useState(null);
    const [payments, setPayments] = useState(null);
    const [processingPayment, setProcessingPayment] = useState(false);
    const authData = useSelector(state => state.auth)
    const [form] = Form.useForm()

    useEffect(() => {
        const initSquare = async () => {
            if (!window.Square) {
                throw new Error('Square.js failed to load properly');
            }
            var paymentsSquare = window.Square.payments(SQUARE_APPLICATION_ID, SQUARE_LOCATION_ID);
            setPayments(paymentsSquare)
            try {
                const card = await paymentsSquare.card();
                if (document.getElementById('card-container')?.childNodes.length === 0) {
                    await card.attach('#card-container');
                }
                setCard(card);
            } catch (e) {
                console.error('Initializing Card failed', e);
                return;
            }
        }
        initSquare();
        setProcessingPayment(false);
    }, [])

    const createPayment = async (token, verificationToken) => {
        return new Promise(async (resolve, reject) => {
            ApiRequest('/payments/process-payment',
                'POST',
                {
                    SQUARE_LOCATION_ID,
                    sourceId: token,
                    verificationToken: verificationToken,
                    bookingData: bookingData
                },
            ).then(
                (paymentResponse) => {
                    if (paymentResponse?.success) {
                        resolve(paymentResponse)
                    } else {
                        poppiNotification(
                            {
                                type: 'error',
                                message: 'Error Creating the payment',
                                description: 'There was an error creating the payment'
                            }
                        )
                        reject(paymentResponse)
                    }
                },
                (error) => {
                    poppiNotification(
                        {
                            type: 'error',
                            message: 'Error Creating the payment',
                            description: 'There was an error creating the payment'
                        }
                    )
                    reject(error)
                }
            )
        }
        )
    }

    async function verifyBuyerHelper(paymentToken = { token: null }) {
        return new Promise(async (resolve, reject) => {
            const verificationDetails = {
                amount: bookingData.total.price.toFixed(2).toString(),
                /* collected from the buyer */
                billingContact: form.getFieldsValue(),
                currencyCode: 'GBP',
                intent: 'CHARGE',
            };

            payments.verifyBuyer(
                paymentToken.token,
                verificationDetails
            ).then(
                (success) => {
                    resolve(success.token)
                },
                (error) => {

                    reject(error)
                }
            )
        })
    }

    async function tokenizeCard() {
        return new Promise(async (resolve, reject) => {
            card.tokenize().then(
                (tokenSuccess) => {
                    if (tokenSuccess.status === 'OK') {
                        resolve(tokenSuccess)
                    } else {
                        poppiNotification(
                            {
                                type: 'error',
                                message: 'Error Card',
                                description: 'There was an error in the card initialization, try to refresh the page'
                            }
                        )
                        reject(false)
                    }
                },
                (error) => {
                    poppiNotification(
                        {
                            type: 'error',
                            message: 'Error Card',
                            description: 'There was an error in the card initialization, try to refresh the page'
                        }
                    )
                    reject(error)
                }
            )
        })
    }
    const handlePaymentMethodSubmission = async (shouldVerify = false) => {
        // try {
        setProcessingPayment(true);

        // Create the token
        tokenizeCard().then(
            (tokenSuccess) => {
                // Verify The Buyer
                verifyBuyerHelper(tokenSuccess).then(
                    (verificationToken) => {
                        // Create the payment
                        createPayment(tokenSuccess, verificationToken).then(
                            (successPayment) => {
                                setProcessingPayment(false);
                                props.onSuccess(successPayment)

                            },
                            (error) => {
                                setProcessingPayment(false);
                            }
                        );

                    },
                    (error) => {
                        setProcessingPayment(false);

                    }
                )
            },
            (error) => {
                setProcessingPayment(false);
            }
        );
    }

    return <>
        <LoadingOverlay
            visible={processingPayment}
            zIndex={10}
            loaderProps={{
                children: <Stack align='center'>
                    <Loader type='bars' />
                    <Text>Processing Payment...</Text>
                </Stack>
            }} />
        <h3>Billing Contact</h3>
        <Form form={form} initialValues={
            {
                addressLines: [authData.address.line1
                    , authData.address.line2 ? authData.address.line2 : ""
                ],
                familyName: authData.name,
                givenName: authData.surname,
                email: authData.email,
                country: countries[authData.address.country],
                phone: authData.phone,
                region: authData.region,
                city: authData.address.city
            }
        }>
            <PoppiFormField
                name={['addressLines', 0]}
                label={'Address line 1'}
                rules={[
                    { required: true, message: 'Field Required' }
                ]}
            />
            <PoppiFormField
                name={['addressLines', 1]}
                label={'Address line 2'}

            />
            <PoppiFormField
                name={'givenName'}
                label={'Name'}
                rules={[
                    { required: true, message: 'Field Required' }
                ]}
            />
            <PoppiFormField
                name={'familyName'}
                label={'Surname'}
                rules={[
                    { required: true, message: 'Field Required' }
                ]}
            />
            <PoppiFormField
                name={'email'}
                label={'Email'}
                rules={[
                    { required: true, message: 'Field Required' }
                ]}
            />
            <PoppiFormField
                type='select'
                options={Object.keys(countries).sort((a, b) => (countries[a].name > countries[b].name ? 1 : -1)).map((code) => {
                    return { value: code, label: countries[code].name }
                })}
                name={'country'}
                label={'Country'}
                rules={[
                    { required: true, message: 'Field Required' }
                ]}
            />
            <PoppiFormField
                name={'phone'}
                label={'Phone'}
                rules={[
                    { required: true, message: 'Field Required' }
                ]}
            />
            <PoppiFormField
                name={'region'}
                label={'Region'}
                rules={[
                    { required: true, message: 'Field Required' }
                ]}
            />
            <PoppiFormField
                name={'city'}
                label={'City'}
                rules={[
                    { required: true, message: 'Field Required' }
                ]}
            />
        </Form>
        <h3>Payment</h3>

        <div id="card-container" ></div>

        {
            !card ? <PoppiLoader />
                :
                <PoppiButton
                    icon={<PoppiIcon icon='payment' />}
                    loading={processingPayment}
                    fullWidth={true}
                    size='large'
                    onClick={async () => {
                        if (card) {
                            form.validateFields().then(
                                (success) => {
                                    // Billing Contact validated, proceed with payment
                                    handlePaymentMethodSubmission(true)
                                },
                                (error) => {
                                    // Notify missing data in the billing contact
                                    poppiNotification(
                                        {
                                            type: 'warning',
                                            message: 'Warning',
                                            description: 'There are some information missing in the billing contact'
                                        }
                                    )
                                }
                            )
                            return
                        } else {
                            poppiNotification(
                                {
                                    type: 'error',
                                    message: 'Error',
                                    description: 'There is an error loading the payment system, try to refresh the page'
                                }
                            )
                        }
                    }}
                    disabled={processingPayment && !!card}
                >
                    Pay {currency(bookingData.total.price)}
                </PoppiButton>
        }
    </>
}

export default PoppiPaymentForm