import { onAuthStateChanged } from '@firebase/auth';
import axios from 'axios';
import { auth } from '../auth/auth';
import { login } from '../auth/authStore';
import { BASE_URL_API, TOKEN_LOCAL_STORAGE } from '../config';
import store from '../appStore';

export function ApiRequest(path = '', method = 'GET', values = {}) {
    var authData = store.getState().auth
    var headers = {
        'Accept': 'application/json'
    }

    if (authData.authenticated || localStorage.getItem(TOKEN_LOCAL_STORAGE)) {
        if (((authData.exp * 1000) - Date.now()) < 0) {
            return new Promise((resolve, reject) => {
                onAuthStateChanged(auth, function (user) {
                    if (user) {
                        // Refresh Token
                        user.getIdToken(true).then((token) => {
                            // Dispatch save token
                            store.dispatch(login(token))
                            resolve(ApiRequest(path, method, values))
                        }).catch(err => console.error(err));
                    } else {
                        reject('Token Not valid')
                    }

                })
            })
        }

        headers = {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + authData.token
        }
    }
    return new Promise((resolve, reject) => {
        axios({
            method: method,
            headers: headers,
            url: `${BASE_URL_API}${path}`,
            data: values
        }).then((response) => {
            resolve(response.data)
        }).catch((error) => {
            if (error.response) {
                reject(error.response.data)
            }
            reject(error)
        })

    })
}

export default ApiRequest


