import React from 'react';
import PoppiButton from '../widget/buttons/poppiButton';
import PoppiPageHeader from '../widget/card/poppiPageHeader';
import PoppiEmpty from '../widget/empty/poppiEmpty';
import PoppiIcon from '../widget/icons/poppiIcons';

const NotAuthorizedPage = (props) => {

    return <PoppiPageHeader
        to='/'
        title='Not Authorized'>
        <PoppiEmpty
            image={props.icon}
            title={'Private page'}
            description={'To view this page you need to login to you account'}

        >
            <PoppiButton fullWidth={true} to='/login' icon={<PoppiIcon icon='login' />}>Login</PoppiButton>
        </PoppiEmpty>


    </PoppiPageHeader>

}

export default NotAuthorizedPage