import { Space } from "@mantine/core"
import { DateInput, TimeInput } from "@mantine/dates"
import dayjs from "dayjs"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import PoppiButton from "../../../../widget/buttons/poppiButton"
import PoppiCard from "../../../../widget/card/poppiCard"
import poppiNotification from "../../../../widget/dialog/poppiNotification"
import PoppiList from "../../../../widget/list/PoppiList"
import PoppiListItem from "../../../../widget/list/PoppiListItem"
import LoaderDispatch from "../../../../widget/loaders/loaderDipatch"
import { getBookingsDate } from "../../calendar/calendarStore"

export const CustomBookingDateSelection = (props) => {
    const { bookingData, setBookingData, activeStep, setActiveStep } = props
    const [dateSelected, setDateSelected] = useState(
        bookingData.CalendarId ? dayjs(bookingData.CalendarId, 'DDMMYYYY') : dayjs(new Date()));
    const calendar = useSelector(state => state.calendar)

    return <PoppiCard
        title='Date Selection'
        actions={[
            <PoppiButton
                fullWidth={true}
                onClick={() => {
                    setBookingData({
                        ...bookingData,
                        CalendarId: dayjs().format('DDMMYYYY'),
                        time: null,
                    })
                    setActiveStep(activeStep - 1)
                }}
            >Back</PoppiButton>,
            <PoppiButton
                variant='default'
                fullWidth={true}
                onClick={() => {
                    if (!(bookingData.CalendarId && bookingData.time)) {
                        console.log(bookingData)
                        poppiNotification({
                            message: 'Missing Data',
                            description:
                                'You need to specify a date and time',
                        })
                    } else {
                        setActiveStep(activeStep + 1)
                    }
                }}
            >Next</PoppiButton>
        ]}
    >
        <DateInput
            defaultDate={dayjs(dateSelected).toDate()}
            mt='sm'
            label='Date'
            minDate={new Date()}
            onChange={(value) => {
                console.log(value)
                console.log(dayjs(value))

                setBookingData({
                    ...bookingData,
                    CalendarId: dayjs(value).format('DDMMYYYY'),
                    time: null
                })
                setDateSelected(dayjs(value))
            }}
            value={dayjs(dateSelected).toDate()}
        />
        <TimeInput
            mt='sm'
            label='Time'
            value={bookingData.time}
            onChange={(value) => {
                setBookingData({ ...bookingData, time: value.target.value })
            }}
        />
        <Space h='sm' />
        <LoaderDispatch
            dispatch={getBookingsDate(dayjs(dateSelected).format('DDMMYYYY'))}
            refresh={dateSelected}
            loadingText='Loading Bookings'
        >
            <PoppiList

                title={`Bookings on ${dayjs(dateSelected).format('DD/MM/YYYY')}`}
                subtitle={`${calendar.bookings.open ? `Open (${calendar.bookings.timings[0]} - ${calendar.bookings.timings[1]}) ${calendar.bookings.reverse ? '(Reverse)' : ''}` : 'Closed'}`}
                dataSource={[...(calendar?.bookings?.Bookings ? calendar?.bookings?.Bookings : [])].sort((a, b) => (
                    dayjs(`${a.CalendarId} ${a.time}`, 'DDMMYYY HH:mm').isAfter(dayjs(`${b.CalendarId} ${b.time}`, 'DDMMYYY HH:mm'))
                        ? 1 * (props.sortDesc ? -1 : 1)
                        : -1 * (props.sortDesc ? -1 : 1)
                ))}
                renderItem={booking => {
                    return <>
                        <PoppiListItem
                            bordered={false}
                            title={`${booking.Dog?.name} (${booking.Dog?.breed})`}
                            description={`${dayjs(booking.time, 'HH:mm').format('HH:mm')} - ${dayjs(booking.time, 'HH:mm').add(booking.total.time, 'minutes').format('HH:mm')} (${dayjs(booking.CalendarId, 'DDMMYYYY').format('ddd DD/MM/YYYY')})`}

                        >
                        </PoppiListItem>
                    </>
                }}
            />

        </LoaderDispatch>

    </PoppiCard >
}

export default CustomBookingDateSelection