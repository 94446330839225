import { Card, Group, Space, Stack, Tabs, Text } from "@mantine/core";
import React, { useState } from "react";
import { v4 as uuidv4 } from 'uuid'

export const PoppiCard = (props) => {
    const [activeTab, setActiveTab] = useState(props?.tabList ? props?.tabList[0]?.key : null)

    return <Card withBorder={props.bordered} p={props.p}>
        {(props.icon || props.title || props.subtitle || props.extra) &&
            <Group justify="space-between" mb={5}>
                <Group>
                    {props.icon}
                    <div>
                        <Text fw={500} >
                            {props.title}
                        </Text>
                        <Text c="dimmed" >
                            {props.subtitle}
                        </Text>
                    </div>
                </Group>
                {props.extra}
            </Group>
        }
        {props.cover && <Card.Section>
            {props.cover}
        </Card.Section>}
        {/* Tablist structure */}
        {props.tabList?.length > 0 ?
            <>
                <Tabs value={activeTab} onChange={setActiveTab}>


                    <Card.Section>
                        {props.header}
                        {props.tabList &&
                            <Tabs.List grow>
                                {props.tabList.map((tab) => {
                                    return <Tabs.Tab
                                        leftSection={tab.icon}
                                        key={uuidv4()} value={tab.key}>{tab.label}</Tabs.Tab>
                                })}
                            </Tabs.List>
                        }
                    </Card.Section>
                    <Card.Section>
                        {props.tabList.map((tab) => {
                            return <Tabs.Panel
                                key={uuidv4()}
                                value={tab.key}>
                                {tab.content}
                            </Tabs.Panel>
                        })}
                        {props.children}
                    </Card.Section>
                </Tabs>

            </>
            :
            <>
                <Card.Section>
                    {props.header}
                    {props.tabList &&
                        <Tabs value={props.activeTab} onChange={props.setActiveTab}>
                            <Tabs.List grow>
                                {props.tabList.map((tab) => {
                                    return <Tabs.Tab
                                        leftSection={tab.icon}
                                        key={uuidv4()} value={tab.key}>{tab.label}</Tabs.Tab>
                                })}
                            </Tabs.List>
                        </Tabs>
                    }
                </Card.Section>
                <Card.Section>

                    {props.children}
                </Card.Section>
            </>}


        <Card.Section mt={10}>
            {props.actions && <Group justify="space-evenly" grow>
                {props.actions.map((item) => {
                    return <span key={uuidv4()}>{item}</span>
                })
                }
            </Group>}
        </Card.Section>
    </Card >
    if (props.bottomTitle) {
        return <Card
            type={props.type}
            bordered={props.bordered}
            actions={props.actions}
            extra={props.extra}
            cover={props.cover}
            tabList={props.tabList}
            activeTabKey={props.activeTabKey}
            onTabChange={props.onTabChange}>
            {/* <Meta
                avatar={props.icon}
                title={props.title}
                description={props.subtitle}
            ></Meta> */}
            {props.children}
        </Card>
    }
    return <Card
        type={props.type}
        bordered={props.bordered}
        title={
            <Meta
                avatar={props.icon}
                title={props.title}
                description={props.subtitle}
            ></Meta>
        }
        extra={props.extra}
        actions={props.actions}
        cover={props.cover}
        tabList={props.tabList}
        activeTabKey={props.activeTabKey}
        onTabChange={props.onTabChange}
    >
        {props.children}
    </Card>
}

PoppiCard.defaultProps = {
    header: null,
    bottomTitle: false,
    title: '',
    subtitle: '',
    icon: null,
    actions: [],
    cover: null,
    extra: null,
    tabList: [],
    activeTabKey: null,
    bordered: true,
    type: null,
    onTabChange: () => { }
}

export default PoppiCard