import * as React from 'react';
import PoppiDatePicker from './poppiDatePicker';


const PoppiTimePicker = React.forwardRef((props, ref) => (
    <PoppiDatePicker {...props} picker="time" mode={undefined} ref={ref} />
));

PoppiTimePicker.displayName = 'TimePicker';

export default PoppiTimePicker;