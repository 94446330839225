import { Avatar, Card, Group, Image, Overlay, SimpleGrid, Stack, Text } from '@mantine/core'
import React from 'react'
import { useNavigate } from 'react-router'
import EventFront from '../static/images/EventsFront.jpg'
import FullGroom from '../static/images/FullGroom2024.jpg'
import Tailoring02 from '../static/images/Tailoring01.jpeg'
import TeethCleaning01 from '../static/images/TeethCleaning01.jpg'
import BespokeBarksLogo from '../static/images/bespokeBarksPng.png'
import PoppiButton from '../widget/buttons/poppiButton'
import PoppiPageHeader from '../widget/card/poppiPageHeader'
import PoppiIcon from '../widget/icons/poppiIcons'

const Homepage = (props) => {
    return <React.Fragment>
        <PoppiPageHeader title='Homepage' />

        <Stack align='center' gap={0} mb={10}>
            <Avatar src={BespokeBarksLogo} size='20em' />
            <Text fw={500} size='xl'>Bespoke Barks</Text>
        </Stack>
        <SimpleGrid cols={{ base: 1, sm: 1, md: 2, lg: 4 }} spacing={0}>
            <CardHomepage
                image={FullGroom}
                title='Grooming'
                icon={<PoppiIcon icon='grooming' style={{ color: 'white' }} />}
                to='/grooming'
                buttonName='Book Now'
            />
            <CardHomepage
                image={TeethCleaning01}
                title='Spa Packages'
                icon={<PoppiIcon icon='spa' style={{ color: 'white' }} />}
                to='/spa'
                buttonName='Book Now'
            />
            <CardHomepage
                image={Tailoring02}
                title='Tailoring'
                icon={<PoppiIcon icon='tailoring' style={{ color: 'white' }} />}
                to='/tailoring'
                buttonName='Discover'
            />
            <CardHomepage
                image={EventFront}
                title='Events'
                icon={<PoppiIcon icon='event' style={{ color: 'white' }} />}
                to='/events'
                buttonName='Discover'
            />
        </SimpleGrid>


    </React.Fragment >

}

const CardHomepage = (props) => {
    const navigate = useNavigate()
    return < Card >
        <Card.Section>
            <Image
                src={props.image}
            />
            <Overlay
                onClick={() => { navigate(props.to) }}

                gradient="linear-gradient(360deg, rgba(0, 0, 0, 0.95) 0%, rgba(0, 0, 0, 0) 30%)" backgroundOpacity={0.5} zIndex={1}>
                <Group
                    justify='space-around'
                    style={{
                        width: '100%',
                        position: `absolute`,
                        alignContent: 'center',
                        bottom: 'var(--mantine-spacing-xl)'
                    }}>
                    <Stack
                        width='100%'
                        align="center" gap={0} pt={'md'}>
                        {props.icon}
                        <Text c='white' size="xl" fw={700}  >
                            {props.title}
                        </Text>
                    </Stack>
                    <PoppiButton
                        // style={{
                        //     position: `absolute`,
                        //     right: 'var(--mantine-spacing-xs)',
                        //     bottom: 'var(--mantine-spacing-xs)'
                        // }}
                        to={props.to}
                    >
                        {props.buttonName}
                    </PoppiButton>
                </Group>

            </Overlay>
        </Card.Section>
    </Card >
}

export default Homepage