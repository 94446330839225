import { ActionIcon, ActionIconProps } from "@mantine/core"
import { ReactNode, forwardRef } from "react"
import { useNavigate } from "react-router"

// interface PoppiActionButtonProps extends ActionIconProps {
//     onClick?: (variables: any) => any,
//     to?: string,
//     icon?: ReactNode
// }

export const PoppiActionButton = forwardRef((props, ref) => {
    const { onClick, to, icon, ...otherProps } = props
    const navigate = useNavigate()

    return <ActionIcon
        ref={ref}
        variant={props.variant}
        onClick={
            props.to ?
                () => { navigate(props.to ? props.to : '') }
                :
                props.onClick
        }
        {...otherProps}
    >
        {props.icon}
    </ActionIcon>
}
)

export default PoppiActionButton