import { Checkbox, Group, Stack, Text, UnstyledButton, useMantineTheme } from "@mantine/core"
import React from "react"
import { useSelector } from 'react-redux'
import PoppiButton from "../../../widget/buttons/poppiButton"
import PoppiCard from "../../../widget/card/poppiCard"
import poppiNotification from "../../../widget/dialog/poppiNotification"
import PoppiSelectableList from "../../../widget/form/poppiSelectableList"
import { LoaderDispatch } from "../../../widget/loaders/loaderDipatch"
import { currency } from "../../../widget/text/formatText"
import { getUserPrices } from "../../profile/profileStore"
import PoppiIcon from "../../../widget/icons/poppiIcons"

export const ServiceSelection = (props) => {
    const { bookingData, setBookingData, activeStep, setActiveStep, calculateTotalPrice } = props
    const defaultDeMattingValue = { value: 'DeMatting', label: 'De Matting', price: 25, time: 30 }
    const theme = useMantineTheme()
    return <PoppiCard
        actions={[
            <PoppiButton
                icon={<PoppiIcon icon='arrowBack' />}
                fullWidth={true}
                onClick={() => {
                    setBookingData(
                        {
                            ...bookingData,
                            services: [],
                            total: calculateTotalPrice({
                                ...bookingData,
                                services: []
                            })
                        }
                    )
                    setActiveStep(activeStep - 1)
                }}
            >Back</PoppiButton>,
            <PoppiButton
                icon={<PoppiIcon icon='arrowForward' />}
                variant='default'
                fullWidth={true}
                onClick={() => {
                    if (bookingData.services.length > 0) {
                        setActiveStep(activeStep + 1)
                    } else {
                        poppiNotification({
                            message: 'Missing Data',
                            description:
                                'You need to select a service',
                        })
                    }
                }}
            >Next</PoppiButton>
        ]} >
        <LoaderDispatch
            dispatch={getUserPrices()}
        >
            <Stack>
                <ServiceSelector {...props} />
                {bookingData.typeService !== 'osteopathy' &&
                    <PoppiCard
                        title='De-matting'>
                        <Stack>
                            <UnstyledButton
                                style={(bookingData.extras.find((extra) => (extra.value === defaultDeMattingValue.value))) ?
                                    {
                                        background: theme.colors.blue[2],
                                        borderColor: theme.colors.blue[0],
                                        borderRadius: 5,
                                        border: '1px solid'
                                    }
                                    :
                                    {
                                        borderColor: theme.colors.blue[0],
                                        borderRadius: 5,
                                        border: '1px solid'
                                    }}
                                p={10}
                                onClick={() => {
                                    if (bookingData.extras.find((extra) => (extra.value === defaultDeMattingValue.value))) {
                                        setBookingData(
                                            {
                                                ...bookingData,
                                                extras: bookingData.extras.filter((extra) => (extra.value !== defaultDeMattingValue.value)),
                                                total: calculateTotalPrice({
                                                    ...bookingData,
                                                    extras: bookingData.extras.filter((extra) => (extra.value !== defaultDeMattingValue.value)),
                                                })
                                            }
                                        )
                                    } else {
                                        setBookingData(
                                            {
                                                ...bookingData,
                                                extras: [...bookingData.extras, defaultDeMattingValue],
                                                total: calculateTotalPrice({
                                                    ...bookingData,
                                                    extras: [...bookingData.extras, defaultDeMattingValue]
                                                })
                                            }
                                        )
                                    }
                                }}>
                                <Group justify="space-between">
                                    <Group>
                                        <div>
                                            <Text fw={500}  >
                                                The dog is matted and needs extra time
                                            </Text>
                                            <Text c="dimmed" >
                                                £ 25.00
                                            </Text>
                                        </div>
                                    </Group>
                                    <Checkbox
                                        checked={bookingData.extras.find((extra) => (extra.value === 'DeMatting'))}
                                        tabIndex={-1}
                                        styles={{ input: { cursor: 'pointer' } }}
                                    />
                                </Group >

                            </UnstyledButton>
                        </Stack>
                    </PoppiCard>
                }

            </Stack>
        </LoaderDispatch>

    </PoppiCard >
}

const ServiceSelector = (props) => {
    const profileData = useSelector(state => state.profile)
    const { bookingData, setBookingData, calculateTotalPrice } = props


    var priceData = null
    if (bookingData.dogData.PriceId) {
        // Search by PriceId
        priceData = profileData.prices.find((item) => (item.id === bookingData.dogData.PriceId))
    }
    if (!priceData) {
        // Search by breed name
        priceData = profileData.prices.find((item) => (item.breed === bookingData.dogData.breed))

    }

    return <PoppiSelectableList
        title='Select the service'
        onChange={(valueSelected) => {
            console.log(valueSelected)
            // Max one service
            if (bookingData?.services.length === 1) {
                if (bookingData.services.find((service) => (service.value === valueSelected.value))) {
                    setBookingData({
                        ...bookingData,
                        services: [],
                        total: calculateTotalPrice({
                            ...bookingData,
                            services: []
                        })
                    })
                } else {
                    setBookingData({
                        ...bookingData,
                        services: [valueSelected],
                        total: calculateTotalPrice({
                            ...bookingData,
                            services: [valueSelected]
                        })
                    })
                }
            } else {
                setBookingData({
                    ...bookingData,
                    services: [valueSelected],
                    total: calculateTotalPrice({
                        ...bookingData,
                        services: [valueSelected]
                    })
                })
            }

        }}
        items={
            (bookingData.typeService === 'osteopathy') ?
                // Osteopathy type selected
                [{
                    selected: !!(bookingData?.services?.find((item) => (item.value === 'Osteopathy'))),
                    value: { enabled: true, value: 'Osteopathy', label: 'Osteopathy', price: 95, time: 60 },
                    label: 'Osteopathy',
                    description: `${currency(95)} (${60} min)`,
                }]
                :
                // Grooming type selected
                [...priceData.services
                    .filter((service) => (service.enabled))
                    .map((service) => {
                        return {
                            selected: !!(bookingData?.services?.find((item) => (item.value === service.value))),
                            value: service,
                            label: service.label,
                            description: `${currency(service.price + (service.value === 'NailClipping' ? 0 : bookingData.dogData.extraCharge))} (${service.time} min)`,
                        }

                    }),
                ...priceData.spa
                    .filter((service) => (service.enabled && service.value === 'UltrasoundTeeth'))
                    .map((service) => {
                        return {
                            selected: !!(bookingData?.services?.find((item) => (item.value === service.value))),
                            value: service,
                            label: service.label,
                            description: `${currency(service.price)} (${service.time} min)`,
                        }

                    })]
        }
    />
}
export default ServiceSelection