import { Checkbox } from "@mantine/core"
import dayjs from "dayjs"
import React, { useState } from "react"
import ApiRequest from "../../../../api/api"
import PoppiButton from "../../../../widget/buttons/poppiButton"
import PoppiCard from "../../../../widget/card/poppiCard"
import PoppiDescription from "../../../../widget/description/poppiDescription"
import { currency } from "../../../../widget/text/formatText"

export const CustomBookingSummarySection = (props) => {

    const { bookingData, setBookingData, activeStep, setActiveStep } = props
    const [isProcessing, setIsProcessing] = useState(false)

    return <PoppiCard
        title='Summary'
        subtitle={`Total price: ${bookingData.complementary ? '£ 0.00' : currency(bookingData.total.price)} (${bookingData.total.time} min)`}
        actions={[
            <PoppiButton
                fullWidth={true}
                onClick={() => {
                    setActiveStep(activeStep - 1)
                }}
            >Back</PoppiButton>,
            <PoppiButton
                loading={isProcessing}
                variant='default'
                fullWidth={true}
                onClick={() => {
                    setIsProcessing(true)
                    ApiRequest(
                        `/calendar/${bookingData.CalendarId}/booking/new`,
                        'POST',
                        { ...bookingData }
                    ).then(
                        (response) => {
                            setIsProcessing(false)
                            setActiveStep(activeStep + 1)
                        },
                        (error) => {
                            setIsProcessing(false)
                            alert('error')
                        }
                    )
                }}
            >Confirm</PoppiButton>
        ]}
    >
        Review the detail of your booking, if everything is ok proceed to payment or go back to make some changes
        <SummaryDetails {...props} />
        <PoppiDescription
            title={'Payment Options'}
            items={[
                {
                    description: <Checkbox
                        label='Mark as complementary (Total Price £ 0.00)'
                        checked={bookingData.complementary}
                        onChange={(e) => {
                            if (bookingData.complementary) {
                                setBookingData({
                                    ...bookingData,
                                    complementary: false,
                                    payments: []
                                })
                            } else {
                                setBookingData({
                                    ...bookingData,
                                    complementary: true,
                                    payments: [{ type: 'Complementary', amount: bookingData.total.price }]
                                })
                            }

                        }}
                    />
                }
            ]} />
    </PoppiCard>
}

export const SummaryDetails = (props) => {
    const { bookingData } = props

    return <>
        <PoppiDescription
            bordered={true}
            title='Dog'
            items={[
                {
                    label: 'Dog',
                    description: bookingData.dogData.name
                },
                {
                    label: 'Breed',
                    description: bookingData.dogData.breed
                },
            ]} />
        <PoppiDescription
            bordered={true}
            title='Services'
            items={bookingData.services.map((service) => {
                return {
                    label: service.label,
                    description: `${bookingData.complementary ? '£ 0.00' : currency(service.price + (service.value === 'NailClipping' ? 0 : bookingData.dogData.extraCharge))} (${service.time} min)`
                }
            })} />
        {bookingData.spa.length > 0 &&
            <React.Fragment>
                <PoppiDescription
                    bordered={true}
                    title='Spa'
                    items={bookingData.spa.map((spa) => {
                        return {
                            label: spa.label,
                            description: `${bookingData.complementary ? '£ 0.00' : currency(spa.price)} (${spa.time} min)`
                        }
                    })} />
            </React.Fragment>
        }
        {bookingData.extras.length > 0 &&
            <React.Fragment>
                <PoppiDescription
                    bordered={true}
                    title='Extra'
                    items={bookingData.extras.map((extra) => {
                        return {
                            label: extra.label,
                            description: `${bookingData.complementary ? '£ 0.00' : currency(extra.price)} (${extra.time} min)`
                        }
                    })} />
            </React.Fragment>
        }
        <PoppiDescription
            bordered={true}
            title={'Appointment Date'}
            items={[
                {
                    label: 'Date',
                    description: dayjs(bookingData.CalendarId, 'DDMMYYYY').format('ddd DD/MM/YYYY')
                },
                {
                    label: 'Time',
                    description: bookingData.time
                }
            ]} />

    </>
}

export default CustomBookingSummarySection