import dayjs from "dayjs"
import React, { useState } from "react"
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from "react-router"
import ApiRequest from "../../../api/api"
import { calculatePayments } from "../../../utils/payments"
import PoppiButton from "../../../widget/buttons/poppiButton"
import PoppiOptionsMenu from "../../../widget/buttons/poppiOptionsMenu"
import PoppiCard from "../../../widget/card/poppiCard"
import PoppiDescription from "../../../widget/description/poppiDescription"
import poppiNotification from "../../../widget/dialog/poppiNotification"
import PoppiEmpty from "../../../widget/empty/poppiEmpty"
import PoppiIcon from "../../../widget/icons/poppiIcons"
import ImageLoader from "../../../widget/images/imageLoader"
import { LoaderDispatch } from "../../../widget/loaders/loaderDipatch"
import { currency } from "../../../widget/text/formatText"
import { getBookingData } from "../dashboardStore"
import DialogEditBooking from "./dialogEditBooking"
import { DialogEditServices } from "./dialogEditServices"
import DialogNotesBooking from "./dialogNotesBooking"
import { Avatar, Stack, useMantineTheme } from "@mantine/core"
import { notifications } from "@mantine/notifications"

export const DashboardBookingDetails = (props) => {
    const dashboardData = useSelector(state => state.dashboard)
    const params = useParams()

    return <LoaderDispatch
        dispatch={getBookingData(params.bookingId)}
    >
        {!dashboardData?.dog ?
            <PoppiEmpty
                description='Booking not Found'>
            </PoppiEmpty>
            :
            <BookingDetails booking={dashboardData.booking} />
        }
    </LoaderDispatch>
}

const BookingDetails = (props) => {
    const { booking } = props
    const [dialogNotes, setDialogNotes] = useState({ open: false })
    const [dialogEditBooking, setDialogEditBooking] = useState({ open: false })
    const [dialogEditServices, setDialogEditServices] = useState({ open: false })
    const theme = useMantineTheme()

    const navigate = useNavigate()
    return <>
        {dialogNotes.open &&
            <DialogNotesBooking
                open={dialogNotes.open}
                bookingData={booking}
                handleClose={() => { setDialogNotes({ open: false }) }}
            />
        }
        {dialogEditBooking.open &&
            <DialogEditBooking
                open={dialogEditBooking.open}
                bookingData={booking}
                handleClose={() => { setDialogEditBooking({ open: false }) }}
            />
        }
        {dialogEditServices.open &&
            <DialogEditServices
                open={dialogEditServices.open}
                bookingData={booking}
                handleClose={() => { setDialogEditServices({ open: false }) }}
            />
        }
        <PoppiCard
            title={`${booking.Dog?.name} (${booking.Dog?.breed})`}
            subtitle={`${dayjs(booking.time, 'HH:mm').format('HH:mm')} - ${dayjs(booking.time, 'HH:mm').add(booking.total.time, 'minutes').format('HH:mm')} (${dayjs(booking.CalendarId, 'DDMMYYYY').format('ddd DD/MM/YYYY')})`}
            extra={[<PoppiOptionsMenu
                onDelete={() => {
                    ApiRequest(
                        `/admin/bookings/${booking.id}/delete`, 'DELETE'
                    ).then(
                        (success) => {
                            navigate('/dashboard/calendar')

                        },
                        (error) => {
                            poppiNotification()
                        }
                    )
                }}
                items={[
                    {
                        key: 'editBookingDate',
                        label: 'Edit Booking Date',
                        icon: <PoppiIcon icon='edit' />,
                        onClick: () => { setDialogEditBooking({ open: true }) }
                    },
                    {
                        key: 'editServices',
                        label: 'Edit Services',
                        icon: <PoppiIcon icon='grooming' />,
                        onClick: () => { setDialogEditServices({ open: true }) }
                    },
                    {
                        key: 'editNotes',
                        label: 'Edit Notes',
                        icon: <PoppiIcon icon='notes' />,
                        onClick: () => { setDialogNotes({ open: true }) }
                    },
                    {
                        key: 'payment Info',
                        label: 'Payment Info',
                        icon: <PoppiIcon icon='payment' />,
                        onClick: () => {
                            notifications.show(
                                { message: JSON.stringify(booking.payment, '-') }
                            )
                        }
                    }
                ]}
            />]}
            icon={
                <Avatar>
                    <ImageLoader imageId={booking.Dog.photoId} size='xs' />
                </Avatar>
            }
            actions={
                [

                    <PoppiButton
                        fullWidth={true}
                        to={`/dashboard/dogs/${booking.Dog?.id}`}
                        icon={<PoppiIcon icon='pets' />}>
                        Dog
                    </PoppiButton>,
                    <PoppiButton
                        fullWidth={true}
                        to={`/dashboard/users/${booking.User?.id}`}
                        icon={<PoppiIcon icon='profile' />}>
                        User
                    </PoppiButton>
                ]
            }
        >

            <PoppiDescription
                title='Services'
                bordered={true}
                items={[
                    ...booking.services.length > 0 ? booking.services.map((service) => {
                        return {
                            background: theme.colors.green[1],
                            label: service.label,
                            description: `${service.time} min`
                        }
                    }) : [],
                    ...booking.spa.length > 0 ? booking.spa.map((spa) => {
                        return {
                            background: theme.colors.blue[1],
                            label: spa.label,
                            description: `${spa.time} min`
                        }
                    }) : [],
                    ...booking?.extras?.length > 0 ? booking.extras.map((extra) => {
                        return {
                            background: theme.colors.red[1],
                            label: extra.label,
                            description: `${extra.time} min`
                        }
                    }) : [],
                    {
                        label: 'Total Time',
                        description: `${booking.total.time} min`
                    },
                ]}
            />
            <PoppiDescription
                title='Notes'
                items={[
                    {
                        description: booking.notes ?
                            <span style={{ whiteSpace: 'pre-line' }}>
                                {booking.notes}
                            </span>
                            :
                            'None'
                    }

                ]}
            />

            <PoppiDescription
                bordered={true}
                title='Payment'
                items={
                    [
                        {
                            label: 'Total Price',
                            description: currency(booking.total.price)
                        },
                        {
                            label: 'Tip',
                            description: currency(booking.tip)
                        },
                        {
                            label: 'Payments',
                            description: <Stack>
                                {booking?.payments?.map((payment) => { return <div>{currency(payment.amount)} {payment.type}</div> })}
                            </Stack>
                        },
                        {
                            label: 'Remaining Amount',
                            description: <Stack>
                                {currency(booking.total.price - calculatePayments(booking.payments))}
                            </Stack>
                        },
                    ]
                }
            />

            <PoppiDescription
                bordered={true}
                title='Booking Info'
                items={
                    [
                        {
                            label: 'id',
                            description: booking.id
                        },
                        {
                            label: 'By',
                            description: `${booking.User.surname} ${booking.User.name}`
                        },
                        {
                            label: 'On:',
                            description: `${dayjs(booking.createdAt).format('ddd DD/MM/YYYY HH:mm')}`
                        },

                    ]
                }
            />

        </PoppiCard>

    </>
}

export default DashboardBookingDetails 