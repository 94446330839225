import React from 'react'
import PoppiButton from '../buttons/poppiButton'
import { Group, Modal } from '@mantine/core'
import { v4 as uuidv4 } from 'uuid'

const PoppiDialog = (props) => {
    if (!props.open) {
        return <React.Fragment></React.Fragment>
    }

    return <Modal.Root
        opened={props.open}
        onClose={props.handleClose}
    >
        <Modal.Overlay />
        <Modal.Content>
            <Modal.Header>
                <Modal.Title fw={500}>{props.title}</Modal.Title>
                <Modal.CloseButton />
            </Modal.Header>
            <Modal.Body>
                {props.children}
                <Group justify="flex-end" mt={5}>
                    <PoppiButton onClick={props.handleClose}>Cancel</PoppiButton>
                    {props.footer && props.footer.map((item) => {
                        return <span key={uuidv4()}>
                            {item}
                        </span>
                    })}
                </Group></Modal.Body>

        </Modal.Content>


        {props.children}


    </Modal.Root>
}

PoppiDialog.defaultProps = {
    maxWidth: 'xl',
    fullScreen: false,
    open: false,
    handleClose: () => { },
    title: 'Dialog',
    confirmText: 'Ok',
    onConfirm: null,
    confirmLoading: false,
    footer: []

}

export default PoppiDialog